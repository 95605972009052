import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiramDetailsComponent } from './hiram-details.component';
import { MaterialHmisModule } from './material-hmis/material-hmis.module';
import { HiramDetailsNavbarModule } from './hiram-details-navbar/hiram-details-navbar.module';
import { HiramResearchersModule } from './hiram-researchers/hiram-researchers.module';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BasicDetailsComponent } from './basic-details/basic-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProcessPhysicalHazardModule } from './process-physical-hazard/process-physical-hazard.module';
import { SbraModule } from './sbra/sbra.module';
import { IconModule } from 'src/app/icon.module';
import { ApproversModule } from './approvers/approvers.module';
import { SubmitComponent } from './submit/submit.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { OtherHazardsModule } from '../admin/other-hazards/other-hazards.module';
import { HiramOtherHazardsModule } from './hiram-other-hazards/hiram-other-hazards.module';
import { RouterModule } from '@angular/router';
import { HiramCommonsModule } from '../hiram-commons/hiram-commons.module';
import { DiscussionModule } from './discussion/discussion.module';
import { ChangeOwnerComponent } from './basic-details/change-owner/change-owner.component';
import { AttachmentsModule } from './attachments/attachments.module';
import { HiramLogModule } from './hiram-log/hiram-log.module';
import { PrintComponent } from './print/print.component';
import { HiramPrintModule as HiramPrintModule } from './print/print-module.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { OtherInfoComponent } from './other-info/other-info.component';
import { ContributorsComponent } from './basic-details/contributors/contributors.component';
import { QuestionsComponent } from './other-info/questions/questions.component';
import { QuestionItemComponent } from './other-info/questions/question-item/question-item.component';
import { ChangeTitleComponent } from './basic-details/change-title/change-title.component';

@NgModule({
    declarations: [
        HiramDetailsComponent,
        BasicDetailsComponent,
        SubmitComponent,
        StatusBarComponent,
        ChangeOwnerComponent,
        ChangeTitleComponent,
        OtherInfoComponent,
        ContributorsComponent,
        QuestionsComponent,
        QuestionItemComponent,
    ],
    imports: [
        CommonModule,
        HiramCommonsModule,
        ProcessPhysicalHazardModule,
        MaterialHmisModule,
        HiramDetailsNavbarModule,
        HiramResearchersModule,
        OtherHazardsModule,
        RouterModule,
        HiramOtherHazardsModule,
        SbraModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        TooltipModule.forRoot(),
        FormsModule,
        IconModule,
        ReactiveFormsModule,
        ApproversModule,
        DiscussionModule,
        AttachmentsModule,
        HiramLogModule,
        HiramPrintModule,
        BrowserAnimationsModule,
        TypeaheadModule.forRoot(),
    ],
    exports: [PrintComponent]
})
export class HiramDetailsModule {}
