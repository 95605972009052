// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-muted {
  color: lightgray !important;
}

.calc-summary-value {
  color: yellow;
  text-shadow: 1px 1px 2px gray;
}

.btn-sm {
  padding: 0 0.5rem;
}

.text-right {
  padding-right: 0.8rem !important;
}

input[type=number] {
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

tr.sticky > th {
  top: 56px;
}

select {
  width: 100%;
  height: 30px;
}

.invalid {
  outline: 2px solid #a94442;
}

.not-included-items {
  color: #888888 !important;
  font-weight: lighter;
  font-style: italic;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
