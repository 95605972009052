// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  color: black;
}

label {
  font-size: 0.7rem;
}

h4 {
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-top: 3rem;
}

table {
  font-size: 0.8rem;
}

thead {
  background-color: white;
  color: black;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: black;
  padding-left: 0;
  padding-right: 0;
}
thead tr {
  background-color: white;
  color: black;
}
thead tr th {
  background-color: white;
  color: black;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: black !important;
  border-top: none !important;
}

tbody {
  border-top-color: none;
}
tbody tr {
  border-top-color: none;
}
tbody tr td {
  border: none;
  color: black;
}

h5 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: solid 0.5px;
  border-bottom: solid 0.5px;
}

h6 {
  margin-top: 3rem;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
