import { Component, OnInit } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { MaterialByOrgNameItem } from 'src/app/core/model/material-by-org-name-item';
import { map } from 'rxjs/operators';
import { LocationService } from 'src/app/core/services/location/location.service';
import { Location } from 'src/app/core/model/location';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';
import { MaterialByLocationItem } from 'src/app/core/model/material-by-location-item';

class SortingConfig {
    field: string;
    thenBy: string;
}

@Component({
    selector: 'hiram-chemicals-by-location',
    templateUrl: './chemicals-by-location.component.html',
    styleUrls: ['./chemicals-by-location.component.scss'],
    animations: [trigger('fadeIn', [transition('*->*', useAnimation(fadeIn))])],
})
export class ChemicalsByLocationComponent implements OnInit {
    // tslint:disable: no-misleading-array-reverse

    constructor(private hiramService: HiramService, private locationsService: LocationService) {}

    location: string;
    locationsFromEmoc: Location[];
    items: MaterialByLocationItem[];
    businessUnitName: string;
    showResults = false;
    loadingLocationList = false;

    allSortingConfigs: { title: SortingConfig; hiram: SortingConfig; owner: SortingConfig };
    activeSortingConfig: SortingConfig;

    ngOnInit() {
        this.allSortingConfigs = {
            title: { field: 'title', thenBy: 'hiramHiramNumber' },
            hiram: { field: 'hiramHiramNumber', thenBy: 'title' },
            owner: { field: 'hiramOwnerDisplayName', thenBy: 'title' },
        };
        this.activeSortingConfig = this.allSortingConfigs.title;

        this.loadLocations();
    }

    search() {
        this.showResults = true;

        console.log('service result', this.location);

        this.hiramService
            .materialsByLocation(this.location)
            .pipe(map(data => data.sort(bySortByField(this.activeSortingConfig))))
            .subscribe(data => (this.items = data));
    }

    locationIsSelected() {
        return this.location !== null && !this.loadingLocationList;
    }

    private loadLocations() {
        this.loadingLocationList = true;
        this.locationsService.getAll().subscribe(result => {
            this.loadingLocationList = false;
            this.locationsFromEmoc = result;
            this.locationsFromEmoc.sort(byName);
        });
    }

    sortByHiram() {
        this.changeSortingConfig(this.allSortingConfigs.hiram);
    }

    sortByTitle() {
        this.changeSortingConfig(this.allSortingConfigs.title);
    }

    sortByOwner() {
        this.changeSortingConfig(this.allSortingConfigs.owner);
    }

    changeSortingConfig(newSortingConfig: SortingConfig) {
        this.activeSortingConfig = newSortingConfig;
        this.items = this.items.sort(bySortByField(this.activeSortingConfig));
    }

    isSortingByHiram(): boolean {
        return this.activeSortingConfig === this.allSortingConfigs.hiram;
    }

    isSortingByTitle(): boolean {
        return this.activeSortingConfig === this.allSortingConfigs.title;
    }

    isSortingByOwner(): boolean {
        return this.activeSortingConfig === this.allSortingConfigs.owner;
    }
}

function byName(a: { name }, b: { name }) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

function bySortByField(config) {
    return (x: MaterialByOrgNameItem, y: MaterialByOrgNameItem) => {
        const primaryField = config.field;
        const secondaryField = config.thenBy;

        const titleCompare = (x[primaryField] + '').localeCompare(y[primaryField] + '');
        if (titleCompare !== 0) {
            return titleCompare;
        }

        return (x[secondaryField] + '').localeCompare(y[secondaryField] + '');
    };
}
