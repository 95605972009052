import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'hiram-tile-circular',
    templateUrl: './tile-circular.component.html',
    styleUrls: ['./tile-circular.component.scss'],
})
export class TileCircularComponent implements OnInit {
    constructor() {}

    @Output() click = new EventEmitter();
    @Input() icon: string;
    @Input() text: string;
    @Input() label: string;
    // tslint:disable-next-line: max-union-size
    @Input() colorStyle: 'warning' | 'secondary' | 'danger' | 'success' | 'info' | 'dark' = 'secondary';

    ngOnInit() {}
}
