import { Component, OnInit } from '@angular/core';
import { HfrCutPointsService } from 'src/app/core/services/hfr-cut-points.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'hiram-hfr-cut-points-admin',
    templateUrl: './hfr-cut-points-admin.component.html',
    styleUrls: ['./hfr-cut-points-admin.component.scss'],
})
export class HfrCutPointsAdminComponent implements OnInit {
    formGroup: UntypedFormGroup;

    constructor(private service: HfrCutPointsService, private formBuilder: UntypedFormBuilder) {
        this.formGroup = this.formBuilder.group({
            id: [null],
            healthCutPoint1to2: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
            healthCutPoint2to3: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
            healthCutPoint3to4: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
            flammabilityCutPoint1to2: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
            flammabilityCutPoint2to3: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
            flammabilityCutPoint3to4: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
            reactivityCutPoint1to2: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
            reactivityCutPoint2to3: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
            reactivityCutPoint3to4: [null, [Validators.required, Validators.min(1), Validators.pattern('^[0-9]*$')]],
        });
    }

    ngOnInit() {
        this.service.get().subscribe(data => {
            this.formGroup.patchValue(data);
        });
    }

    submit() {
        if (this.formGroup.valid) {
            this.service.update(this.formGroup.value).subscribe(data => {
                this.formGroup.patchValue(data);
            });
        }
    }
}
