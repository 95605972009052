import { Directive, HostBinding } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[ok-button]',
})
export class OkButtonDirective {
    constructor() {}

    @HostBinding('class')
    elementClass = 'btn btn-primary';
}
