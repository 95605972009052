import { Component, OnInit } from '@angular/core';
import { StandardDocumentsService } from 'src/app/core/services/standard-documents/standard-documents.service';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from 'src/app/core/services/upload/upload.service';

@Component({
    selector: 'hiram-standard-documents-list',
    templateUrl: './standard-documents-list.component.html',
    styleUrls: ['./standard-documents-list.component.scss'],
})
export class StandardDocumentsListComponent implements OnInit {
    constructor(
        private service: StandardDocumentsService,
        private confirmationService: ConfirmationService,
        private toastr: ToastrService,
        private uploadService: UploadService
    ) {}

    items$ = this.service.all();

    ngOnInit() {}
}
