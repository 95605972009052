import { Component, OnInit } from '@angular/core';
import { OtherHazard } from 'src/app/core/model/other-hazard';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { OtherHazardsService } from 'src/app/core/services/other-hazards.service/other-hazards.service';
import { OtherHazardsDetailsComponent } from '../other-hazards-details/other-hazards-details.component';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'other-hazards-list',
    templateUrl: './other-hazards-list.component.html',
    styleUrls: ['./other-hazards-list.component.scss'],
})
export class OtherHazardsListComponent implements OnInit {
    constructor(
        private service: OtherHazardsService,
        private modalService: BsModalService,
        private confirmationService: ConfirmationService
    ) {}

    items: OtherHazard[];

    ngOnInit() {
        this.service.all().subscribe(items => {
            this.items = items;
        });
    }

    create() {
        const initialState = { item: new OtherHazard() };

        const modalRef = this.modalService.show(OtherHazardsDetailsComponent, { initialState, backdrop: 'static' });

        modalRef.content.updated.subscribe((data: OtherHazard) => this.items.push(data));
    }

    edit(item: OtherHazard) {
        const initialState = { item };

        const modalRef = this.modalService.show(OtherHazardsDetailsComponent, { initialState, backdrop: 'static' });

        modalRef.content.updated.subscribe((data: OtherHazard) => this.replaceItemInList(data));
    }

    private replaceItemInList(data: OtherHazard): void {
        const itemIndex = this.items.findIndex(x => x.id === data.id);
        this.removeItemByIndex(itemIndex);
        this.addItemAt(itemIndex, data);
    }

    private removeItemByIndex(index: number) {
        this.items.splice(index, 1);
    }

    public addItemAt(index: number, item: OtherHazard) {
        this.items.splice(index, 0, item);
    }
}
