import { Component, OnInit, Input } from '@angular/core';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';

@Component({
    selector: 'hiram-readonly-hiram-list',
    templateUrl: './readonly-hiram-list.component.html',
    styleUrls: ['./readonly-hiram-list.component.scss'],
})
export class ReadonlyHiramListComponent implements OnInit {
    @Input() hirams: HiramListItem[];

    constructor() {}

    ngOnInit() {}
}
