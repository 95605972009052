import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { SBRA } from 'src/app/core/model/sbra';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { RiskAssessmentItemsComponent } from './risk-assessment-items/risk-assessment-items.component';
import { RiskCategoryDetailsComponent } from '../risk-category-details/risk-category-details.component';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'hiram-sbra-form',
    templateUrl: './sbra-form.component.html',
    styleUrls: ['./sbra-form.component.scss'],
})
export class SbraFormComponent implements OnInit {
    constructor(
        private hiramService: HiramService,
        private formBuilder: UntypedFormBuilder,
        private toastr: ToastrService,
        private confirmationService: ConfirmationService
    ) {}

    @Input() hiramId: number;
    @Input() readonly = false;
    @Output() removed = new EventEmitter<SBRA>();

    @ViewChild('riskCategoryDetails', { static: false }) riskCategoryDetails: RiskCategoryDetailsComponent;
    @ViewChild('riskAssessmentItems', { static: false }) riskAssessmentItems: RiskAssessmentItemsComponent;

    sbra: SBRA;
    operationInProgress = false;
    detailsForm: UntypedFormGroup;
    submitOperationQueued = false;

    ngOnInit() {
        this.detailsForm = this.buildForm();
    }

    load(sbra: SBRA) {
        this.sbra = sbra;
        this.detailsForm.patchValue(sbra);
        this.riskAssessmentItems.load(sbra);
        this.riskCategoryDetails.load(sbra);
    }

    isSubmitEnabled() {
        return this.detailsForm.valid && !this.operationInProgress;
    }

    itemsUpdate(category: number) {
        this.sbra.riskCategory = category;
        this.riskCategoryDetails.load(this.sbra);
        this.submit();
    }

    procedureListChange() {
        this.submit();
    }

    submit(config: { showToastr: boolean } = null) {
        if (this.readonly) {
            console.log('submit canceled because it is readonly');
            return;
        }

        if (this.operationInProgress) {
            console.log('SBRA submit ignored as there is already one in place');

            if (!this.submitOperationQueued) {
                this.submitOperationQueued = true;
                setTimeout(() => {
                    this.submit();
                    this.submitOperationQueued = false;
                }, 1000);
            }

            return;
        }

        console.log('submitting...');

        const item = this.sbra;
        item.name = this.detailsForm.value.name;
        item.cascadingEvent = this.detailsForm.value.cascadingEvent;
        item.initiatingEvent = this.detailsForm.value.initiatingEvent;
        item.consequence = this.detailsForm.value.consequence;

        this.operationInProgress = true;
        this.hiramService
            .updateSBRAs(this.hiramId, item)
            .pipe(tap(() => (this.operationInProgress = false)))
            .subscribe(_ => {
                console.log('HIRAM save complete');
                if (config && config.showToastr) {
                    this.toastr.success('Scenario successfully saved!');
                }
            });
    }

    delete() {
        this.confirmationService.ask({
            message:
                'This operation will remove the current scenario and cannot be undone. Are you sure you want to continue?',
            andRunIfConfirmed: () => {
                this.operationInProgress = true;
                this.hiramService.deleteSBRA(this.hiramId, this.sbra).subscribe(() => {
                    this.operationInProgress = false;
                    this.removed.emit(this.sbra);
                });
            },
        });
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            id: [null],
            name: [null, Validators.required],
            initiatingEvent: [null],
            cascadingEvent: [null],
            consequence: [null],
        });
    }
}
