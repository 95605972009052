import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DashboardStyleService } from '../dashboard-style.service';

@Component({
    selector: 'hiram-tile',
    templateUrl: './tile.component.html',
    styleUrls: ['./tile.component.scss'],
})
export class TileComponent implements OnInit {
    constructor(private styleService: DashboardStyleService) {}

    @Output() click = new EventEmitter();
    @Input() icon: string;
    @Input() text: string;
    @Input() label: string;
    @Input() highlightWhenGreaterThanOne = false;
    // tslint:disable-next-line: max-union-size
    @Input() colorStyle: 'warning' | 'secondary' | 'danger' | 'success' | 'unset' = 'unset';
    @Input() mode: 'Square' | 'Circle' = this.styleService.tileStyle;

    ngOnInit() {}

    onAnimationStart(event) {
        console.log('onAnimationStart');
    }

    onAnimationDone(event) {
        console.log('onAnimationDone');
    }

    valueIsGreaterThanZero() {
        if (!this.text) {
            return false;
        }

        if (typeof this.text !== 'number') {
            return false;
        }

        // tslint:disable-next-line: radix
        return parseInt(this.text) > 0;
    }

    styleToBeApplied() {
        if (this.colorStyle !== 'unset') {
            return this.colorStyle;
        }

        if (this.highlightWhenGreaterThanOne) {
            if (!this.text || this.text === '') {
                return 'secondary';
            }

            // tslint:disable-next-line: radix
            if (Number.parseInt(this.text) > 0) {
                return 'danger';
            } else {
                return 'secondary';
            }
        }

        return 'secondary';
    }
}
