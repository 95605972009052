import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'hiram-hiram-manage-button',
    templateUrl: './hiram-manage-button.component.html',
    styleUrls: ['./hiram-manage-button.component.scss'],
})
export class HiramManageButtonComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {}

    navigate() {
        this.router.navigate(['hirams', 'all']);
    }
}
