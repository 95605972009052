import { Component, OnInit, Input } from '@angular/core';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';

@Component({
    selector: 'hiram-user-role-badge',
    templateUrl: './user-role-badge.component.html',
    styleUrls: ['./user-role-badge.component.scss'],
})
export class UserRoleBadgeComponent implements OnInit {
    constructor() {}

    @Input() item: HiramListItem;

    ngOnInit() {}

    isApprovalRole(role: string) {
        if (role !== 'Owner' && role !== 'Creator' && role !== 'No role') {
            return true;
        }
        return false;
    }
}
