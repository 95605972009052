import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'hiram-loading-sign',
    templateUrl: './loading-sign.component.html',
    styleUrls: ['./loading-sign.component.scss'],
})
export class LoadingSignComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
