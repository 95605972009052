import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Organization } from 'src/app/core/model/organization';
import { OrganizationService } from 'src/app/core/services/organization/organization.service';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { MaterialByOrgNameItem } from 'src/app/core/model/material-by-org-name-item';
import { map, tap } from 'rxjs/operators';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';

class SortingConfig {
    field: string;
    thenBy: string;
}

@Component({
    selector: 'hiram-chemicals-by-organization',
    templateUrl: './chemicals-by-organization.component.html',
    styleUrls: ['./chemicals-by-organization.component.scss'],
    animations: [trigger('fadeIn', [transition('*->*', useAnimation(fadeIn))])],
})
export class ChemicalsByOrganizationComponent implements OnInit {
    // tslint:disable: no-misleading-array-reverse

    constructor(private hiramService: HiramService, private organizationService: OrganizationService) {}

    organizations$: Observable<Organization[]>;
    items: MaterialByOrgNameItem[];
    businessUnitName: string;
    showResults = false;
    loadingOrganizations = false;

    allSortingConfigs: { title: SortingConfig; hiram: SortingConfig };
    activeSortingConfig: SortingConfig;

    ngOnInit() {
        this.allSortingConfigs = {
            title: { field: 'title', thenBy: 'hiramHiramNumber' },
            hiram: { field: 'hiramHiramNumber', thenBy: 'title' },
        };
        this.activeSortingConfig = this.allSortingConfigs.title;

        this.loadOrganizations();
    }

    search() {
        this.showResults = true;

        console.log('service result', this.hiramService.materialsByOrg(this.businessUnitName));

        this.hiramService
            .materialsByOrg(this.businessUnitName)
            .pipe(map(data => data.sort(bySortByField(this.activeSortingConfig))))
            .subscribe(data => (this.items = data));
    }

    businessUnitIsSelected() {
        return this.businessUnitName && this.businessUnitName.trim() !== '';
    }

    loadOrganizations() {
        this.loadingOrganizations = true;
        this.organizations$ = this.organizationService.getAll().pipe(tap(() => (this.loadingOrganizations = false)));
    }

    sortByHiram() {
        this.changeSortingConfig(this.allSortingConfigs.hiram);
    }

    sortByTitle() {
        this.changeSortingConfig(this.allSortingConfigs.title);
    }

    changeSortingConfig(newSortingConfig: SortingConfig) {
        this.activeSortingConfig = newSortingConfig;
        this.items = this.items.sort(bySortByField(this.activeSortingConfig));
    }

    isSortingByHiram(): boolean {
        return this.activeSortingConfig === this.allSortingConfigs.hiram;
    }

    isSortingByTitle(): boolean {
        return this.activeSortingConfig === this.allSortingConfigs.title;
    }
}

function bySortByField(config) {
    return (x: MaterialByOrgNameItem, y: MaterialByOrgNameItem) => {
        const primaryField = config.field;
        const secondaryField = config.thenBy;

        const titleCompare = (x[primaryField] + '').localeCompare(y[primaryField] + '');
        if (titleCompare !== 0) {
            return titleCompare;
        }

        return (x[secondaryField] + '').localeCompare(y[secondaryField] + '');
    };
}
