import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ApprovalHistoryComponent } from './approval-history/approval-history.component';

@Component({
    selector: 'hiram-approvers',
    templateUrl: './approvers.component.html',
    styleUrls: ['./approvers.component.scss'],
})
export class ApproversComponent implements OnInit {
    @Input() hiramId: number;
    @Input() readonly = false;

    @ViewChild('approvalHistory', { static: false }) approvalHistory: ApprovalHistoryComponent;

    hiramStatus: string;

    constructor(private service: HiramService) {}

    ngOnInit() {
        if (this.hiramId > 0) {
            this.service.byId(this.hiramId).subscribe(data => (this.hiramStatus = data.statusDescription));
        } else {
            this.hiramStatus = null;
        }
    }

    itemChange() {
        this.approvalHistory.refresh();
    }
}
