// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-btn {
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
}
.info-btn:hover {
  color: #5b5b61;
}

.active {
  font-weight: 600;
}

.retired {
  color: gray;
}

.version-row {
  cursor: pointer;
}

.version-row:hover {
  background-color: #f2f7f4;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
