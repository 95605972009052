import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiramDetailsNavbarComponent } from './hiram-details-navbar.component';
import { HiramDetailsNavbarItemComponent } from './hiram-details-navbar-item/hiram-details-navbar-item.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IconModule } from 'src/app/icon.module';

@NgModule({
    declarations: [HiramDetailsNavbarComponent, HiramDetailsNavbarItemComponent],
    imports: [CommonModule, IconModule, TooltipModule.forRoot()],
    exports: [HiramDetailsNavbarComponent],
})
export class HiramDetailsNavbarModule {}
