import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UnsupportedBrowserCheckerService {
    constructor() {}

    public usingCompatibleBrowser(): boolean {
        const userAgent: string = window.navigator.userAgent;

        if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
            return false;
        }

        return true;
    }
}
