import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Observable } from 'rxjs';
import { Approval } from 'src/app/core/model/approval';
import { map } from 'rxjs/operators';

@Component({
    selector: 'hiram-approvers',
    templateUrl: './approvers.component.html',
    styleUrls: ['../print.component.scss'],
})
export class ApproversComponent implements OnInit {
    approvals$: Observable<Approval[]>;

    constructor(private service: HiramService) {}

    ngOnInit() {}

    @Input() set hiramId(value: number) {
        if (value) {
            this.approvals$ = this.service
                .getApprovals(value)
                .pipe(map(items => items.filter(each => each.notRequired !== true)));
        }
    }
}
