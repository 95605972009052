import { Component, OnInit, EventEmitter, ViewChild } from '@angular/core';
import { HiramOtherHazard } from 'src/app/core/model/hiram-other-hazard';
import { OtherHazard } from 'src/app/core/model/other-hazard';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { OtherHazardsService } from 'src/app/core/services/other-hazards.service/other-hazards.service';
// tslint:disable-next-line: max-line-length
import { OtherHazardsTypeListComponent } from 'src/app/components/admin/other-hazards/other-hazards-type-list/other-hazards-type-list.component';

// tslint:disable: no-string-literal

@Component({
    selector: 'hiram-details-form',
    templateUrl: './details-form.component.html',
    styleUrls: ['./details-form.component.scss'],
})
export class DetailsFormComponent implements OnInit {
    @ViewChild('typelist', { static: false }) typelist: OtherHazardsTypeListComponent;
    hiramId: number;
    created = new EventEmitter<HiramOtherHazard>();
    items: OtherHazard[];
    idsAlreadyInHiram: number[] = [];
    selectedTypeId = -1;
    detailsForm: UntypedFormGroup;
    submittingForm = false;
    itemsForSelectedType: OtherHazard[] = [];

    constructor(
        private service: OtherHazardsService,
        private hiramService: HiramService,
        private formBuilder: UntypedFormBuilder,
        public bsModalRef: BsModalRef
    ) {}

    ngOnInit() {
        this.service.all().subscribe(items => {
            this.items = items;
        });
        this.detailsForm = this.buildForm();
    }

    submit() {
        const newItem = new HiramOtherHazard();
        newItem.hiramId = this.hiramId;
        newItem.otherHazardId = this.detailsForm.value.factorId;

        this.submittingForm = true;
        this.hiramService.postOtherHazard(newItem).subscribe(item => {
            this.created.emit(item);
            this.submittingForm = false;
            this.close();
        });
    }

    noTypeIsSelected() {
        return !this.selectedTypeId || this.selectedTypeId <= 0;
    }

    getItemsForSelectedType(): OtherHazard[] {
        if (this.noTypeIsSelected()) {
            return [];
        }

        return this.items.filter(
            each => each.otherHazardTypeId === this.selectedTypeId && !this.idsAlreadyInHiram.includes(each.id)
        );
    }

    close() {
        this.bsModalRef.hide();
    }

    onTypeSelectionChange(typeId: number) {
        if (!typeId) {
            return;
        }

        // tslint:disable-next-line: radix
        this.selectedTypeId = parseInt(typeId.toString());
        if (this.noTypeIsSelected()) {
            this.detailsForm.controls['factorId'].disable();
        } else {
            this.detailsForm.controls['factorId'].enable();
        }
    }

    isSubmitEnabled() {
        return this.detailsForm.valid && !this.detailsForm.pristine && !this.submittingForm;
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            factorId: new UntypedFormControl({ value: null, disabled: true }),
        });
    }
}
