import { Component, OnInit, Input } from '@angular/core';
import { MaterialSummaryQuintileDefinition } from 'src/app/core/model/material-summary';

@Component({
    selector: 'hiram-quintile-def-table',
    templateUrl: './quintile-def-table.component.html',
    styleUrls: ['./quintile-def-table.component.scss'],
})
export class QuintileDefTableComponent implements OnInit {
    constructor() {}

    @Input() items: MaterialSummaryQuintileDefinition[];
    @Input() quintileToHighlight: number;

    ngOnInit() {}
}
