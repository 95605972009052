import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'hiram-sort-controls',
    templateUrl: './sort-controls.component.html',
    styleUrls: ['./sort-controls.component.scss'],
})
export class SortControlsComponent implements OnInit {
    constructor() {}

    sortByFieldValue: 'title' | 'expirationDate' | 'ownerDisplayName' = 'title';
    sortDirectionValue: 'asc' | 'desc' = 'asc';

    @Output()
    sortByFieldChange = new EventEmitter();

    @Output()
    sortDirectionChange = new EventEmitter();

    get sortByField() {
        return this.sortByFieldValue;
    }

    @Input()
    set sortByField(value) {
        this.sortByFieldValue = value;
        this.sortByFieldChange.emit(value);
    }

    get sortDirection() {
        return this.sortDirectionValue;
    }

    @Input()
    set sortDirection(value) {
        this.sortDirectionValue = value;
        this.sortDirectionChange.emit(value);
    }

    ngOnInit() {}
}
