import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'hiram-unsupported-browser-page',
    templateUrl: './unsupported-browser-page.component.html',
    styleUrls: ['./unsupported-browser-page.component.scss'],
})
export class UnsupportedBrowserPageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
