// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  display: block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  font-size: 2rem;
}

.text-label {
  font-size: 0.8rem;
  color: darkslategray;
  text-align: center;
  text-shadow: 1px 1px 0px gray !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
