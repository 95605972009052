import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MidasResponse } from '../model/midas-contract';

@Injectable({
    providedIn: 'root',
})
export class MidasService {
    constructor(private httpClient: HttpClient) {}

    get(term: string): Observable<MidasResponse> {        
        if (!term) return;
        
        return this.httpClient.get<MidasResponse>(`${environment.baseUrl}/api/Midas?term=${term}`);
    }
}