import { Component, OnInit, Input } from '@angular/core';
import { SortController } from 'src/app/core/services/sort-controller';

@Component({
    selector: 'hiram-sort-button',
    templateUrl: './sort-button.component.html',
    styleUrls: ['./sort-button.component.sass'],
})
export class SortButtonComponent implements OnInit {
    constructor() {}

    @Input()
    field: string;

    @Input()
    sortController: SortController;

    ngOnInit() {}

    showIconDown(): boolean {
        return (
            this.sortController && (this.sortController.field !== this.field || this.sortController.direction === 'asc')
        );
    }

    showIconUp(): boolean {
        return !this.showIconDown();
    }

    changeSorting() {
        if (this.sortController.field === this.field) {
            this.sortController.direction = this.sortController.direction === 'asc' ? 'desc' : 'asc';
        } else {
            this.sortController.direction = 'asc';
        }

        this.sortController.field = this.field;
    }

    isSortingBy(): boolean {
        return this.sortController && this.sortController.field === this.field;
    }
}
