import { Component, OnInit, Input } from '@angular/core';
import { SBRA } from 'src/app/core/model/sbra';

@Component({
    selector: 'hiram-scenario-summary',
    templateUrl: './scenario-summary.component.html',
    styleUrls: ['./scenario-summary.component.scss'],
})
export class ScenarioSummaryComponent implements OnInit {
    constructor() {}

    @Input() hiramId: number;
    @Input() scenarios: SBRA[];

    ngOnInit() {}
}
