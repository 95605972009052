import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Approval } from 'src/app/core/model/approval';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';
import { tap } from 'rxjs/operators';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'hiram-approval-item',
    templateUrl: './approval-item.component.html',
    styleUrls: ['./approval-item.component.scss'],
    animations: [
        trigger('slide', [transition(':leave', [animate('600ms ease-in', style({ transform: 'translateX(-100%)' }))])]),
    ],
})
export class ApprovalItemComponent implements OnInit {
    constructor(private service: ApprovalService) {}

    @Input() item: Approval;
    @Input() showHiramAndRoleNames = true;
    @Output() itemChange = new EventEmitter();

    comments = '';
    workInProgress = false;
    workCompleted = false;

    ngOnInit() {
        console.log(this.item);
    }

    approve() {
        this.workInProgress = true;

        this.service
            .approve(this.item.id, this.comments)
            .pipe(tap(() => (this.workInProgress = false)))
            .subscribe(() => {
                this.workCompleted = true;
                this.itemChange.emit();
            });
    }

    reject() {
        if (this.noComments()) {
            return;
        }

        this.workInProgress = true;
        this.service
            .reject(this.item.id, this.comments)
            .pipe(tap(() => (this.workInProgress = false)))
            .subscribe(() => {
                this.workCompleted = true;
                this.itemChange.emit();
            });
    }

    lastComment(item: Approval) {
        if (!item.approvalLogs || item.approvalLogs.length === 0) {
            return '';
        } else {
            return item.approvalLogs[item.approvalLogs.length - 1].comments;
        }
    }

    noComments() {
        return this.comments == null || this.comments.trim() === '';
    }

    pendingApproval() {
        return this.item.accepted === null;
    }
}
