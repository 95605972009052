import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HfrCutPointsAdminComponent } from './hfr-cut-points-admin/hfr-cut-points-admin.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [HfrCutPointsAdminComponent],
    imports: [CommonModule, FontAwesomeModule, ReactiveFormsModule],
    exports: [HfrCutPointsAdminComponent],
})
export class HfrCutPointsModule {}
