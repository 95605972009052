// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-wrapper {
  font-size: 1.5rem;
}

*.icon-blue {
  color: #0088cc;
}

*.icon-grey {
  color: gray;
}

i {
  width: 100px;
  text-align: center;
  vertical-align: middle;
}

.badge {
  border: black;
  background: transparent;
  font-size: 8px;
  width: auto;
  height: auto;
  margin: 0;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -3px;
  padding: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
