import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OtherInfoQuestion } from 'src/app/core/model/other-info-question';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { OtherInfoQuestionsService } from 'src/app/core/services/other-info-questions-service/other-info-questions-service.service';
import { tap } from 'rxjs/operators';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';

@Component({
    selector: 'hiram-other-info-question-item',
    templateUrl: './other-info-question-item.component.html',
    styleUrls: ['./other-info-question-item.component.scss'],
})
export class OtherInfoQuestionItemComponent implements OnInit {
    formGroup: UntypedFormGroup;
    workInProgress = false;
    editInProgress = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private service: OtherInfoQuestionsService,
        private confirmationService: ConfirmationService
    ) {}

    @Input() item: OtherInfoQuestion;
    @Output() deleted = new EventEmitter();

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            text: [this.item.text, Validators.required],
        });
        this.editInProgress = this.item.id === -1;
    }

    edit() {
        this.editInProgress = true;
        this.formGroup.controls.text.setValue(this.item.text);
    }

    save() {
        if (this.formGroup.invalid) {
            return;
        }

        const itemToSave = { id: this.item.id, text: this.formGroup.value.text };
        this.workInProgress = true;

        this.doSaving(itemToSave)
            .pipe(tap(() => (this.workInProgress = false)))
            .subscribe(result => {
                this.item.id = result.id;
                this.item.text = result.text;
            });

        this.editInProgress = false;
    }

    delete() {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.editInProgress = false;
                this.service
                    .delete(this.item.id)
                    .pipe(tap(() => (this.editInProgress = false)))
                    .subscribe(() => this.deleted.emit());
            },
        });
    }

    doSaving(itemToSave) {
        if (this.item.id === -1) {
            return this.service.add(itemToSave);
        } else {
            return this.service.update(itemToSave);
        }
    }

    undo() {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.editInProgress = false;
                if (this.item.id === -1) {
                    this.deleted.emit();
                }
            },
        });
    }

    submit() {
        this.save();
    }
}
