import { Component, OnInit } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'risk-table-header-content',
    templateUrl: './header-item.component.html',
    styleUrls: ['./header-item.component.scss'],
})
export class ProcessPhysicalHazardTableHeaderItemComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
