import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'hiram-risk-category-tile',
    templateUrl: './risk-category-tile.component.html',
    styleUrls: ['./risk-category-tile.component.scss'],
})
export class RiskCategoryTileComponent implements OnInit {
    @Input() value: number;
    @Input() size: 'small' | 'medium' = 'medium';

    constructor() {}

    ngOnInit() {}

    mainClass() {
        if (this.size === 'medium') {
            return 'risk-cell pt-3';
        } else {
            return 'risk-cell-sm pt-1';
        }
    }
}
