import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { APP_CONFIG } from './app/core/model/app-config';


fetch('/config.json')
    .then(response => response.json())
    .then(config => {
        if (config.production) {
            enableProdMode();
        }

        platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
            .bootstrapModule(AppModule)
            .catch(err => console.error(err));
    });