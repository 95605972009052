import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OtherHazard } from 'src/app/core/model/other-hazard';
import { OtherHazardsService } from 'src/app/core/services/other-hazards.service/other-hazards.service';
import { ApprovalRole } from 'src/app/core/model/approval-role';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';
import { map } from 'rxjs/operators';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'other-hazards-details',
    templateUrl: './other-hazards-details.component.html',
    styleUrls: ['./other-hazards-details.component.scss'],
})
export class OtherHazardsDetailsComponent implements OnInit {
    @Output() updated = new EventEmitter<OtherHazard>();

    item: OtherHazard;
    endorsementRoles: ApprovalRole[];
    detailsForm: UntypedFormGroup;
    submittingForm = false;

    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: UntypedFormBuilder,
        private service: OtherHazardsService,
        private approvalRoleService: ApprovalService
    ) {}

    ngOnInit() {
        this.detailsForm = this.buildForm();

        if (this.item.approvalRoleId === null) {
            this.item.approvalRoleId = -1;
        }

        this.detailsForm.patchValue(this.item);

        this.approvalRoleService
            .getApprovalRoles()
            .pipe(map(roles => roles.filter(each => each.isEndorser === true)))
            .subscribe(roles => {
                this.endorsementRoles = [{ id: -1, name: 'None' }, ...roles];
            });
    }

    submit() {
        if (this.detailsForm.value.id <= 0) {
            this.service.create(this.detailsForm.value).subscribe(item => this.afterSaving(item));
        } else {
            this.service.update(this.detailsForm.value).subscribe(item => this.afterSaving(item));
        }
    }

    afterSaving(item) {
        this.updated.emit(item);
        this.bsModalRef.hide();
    }

    get f() {
        return this.detailsForm.controls;
    }

    close() {
        this.bsModalRef.hide();
    }

    isSubmitEnabled(): boolean {
        return this.detailsForm.valid && !this.detailsForm.pristine && !this.submittingForm;
    }

    readyToShowErrorMessages(control: any) {
        return control.invalid && (control.dirty || control.touched);
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            id: [0],
            description: [null, Validators.required],
            tooltip: [''],
            riskFactor: [null, Validators.required],
            otherHazardTypeId: [null, Validators.required],
            approvalRoleId: [-1],
        });
    }
}
