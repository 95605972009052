import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'hiram-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
    @Input() user: { displayName: string; title: string; domainName: string; domainUserName: string };
    @Input() title: string;
    @Input() size: 'full' | 'small' = 'full';
    @Input() badge = true;
    @Input() displayName = true;
    @Output() whenEditButtonIsClicked: EventEmitter<string> = new EventEmitter();

    constructor() {}

    ngOnInit() {}

    imageUrl() {
        if (this.user && this.user.domainName && this.user.domainUserName) {
            if (this.size === 'full') {
                return `http://skypepictures/images/${this.user.domainName}/M/${this.user.domainUserName}.jpg`;
            } else {
                return `http://skypepictures/images/${this.user.domainName}/S/${this.user.domainUserName}.jpg`;
            }
        } else {
            return 'assets/images/default_user.png';
        }
    }

    getSmallName(name: string) {
        const safeName = name || '';
        const names = safeName.split(',').map(s => s.trim().split(' ')[0]);
        return `${names[0]}, ${names[1]}`;
    }
}
