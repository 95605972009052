import { Component, OnInit, HostListener, Renderer2, ChangeDetectorRef } from '@angular/core';
import { MainPageLayoutService } from './core/services/main-page-layout/main-page-layout.service';
import { Router, NavigationEnd } from '@angular/router';
import { CurrentUserService } from './core/services/authentication/current-user.service';
declare let gtag;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'hiram-web';

    constructor(
        private renderer: Renderer2,
        private mainPageLayout: MainPageLayoutService,
        private cd: ChangeDetectorRef,
        private router: Router,
        private currentUserService: CurrentUserService
    ) {}

    lastAuthenticationStart = null;
    lastAuthenticationEnd = null;
    showAuthenticationMessage = false;
    showScaffold = true;

    ngOnInit() {
        this.currentUserService.getCurrentUser().subscribe((v) => console.log(v));

        this.handleChangesOnHeaderVisibility();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'UA-177605528-16', {
                    page_path: event.urlAfterRedirects,
                });
            }
        });
    }

    private handleChangesOnHeaderVisibility() {
        this.mainPageLayout.changeHeaderVisibility.subscribe(value => {
            this.showScaffold = value;
            this.cd.detectChanges();
        });
    }

    @HostListener('window:keydown', ['$event'])
    handleKeydown($event: { key: string; ctrlKey: boolean }) {
        if ($event.key === ';' && $event.ctrlKey) {
            const control = this.renderer.selectRootElement('#globalSearchControl');
            if (control) {
                control.focus();
            }
        }
    }
}
