import { Component, OnInit, Input } from '@angular/core';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';

@Component({
    selector: 'hiram-expiration-badges',
    templateUrl: './expiration-badges.component.html',
    styleUrls: ['./expiration-badges.component.scss'],
})
export class ExpirationBadgesComponent implements OnInit {
    constructor() {}

    @Input() item: HiramListItem;

    ngOnInit() {}

    expired() {
        if (!this.item.expirationDate) {
            return false;
        }

        const expirationDate = new Date(Date.parse(this.item.expirationDate.toString()));
        return expirationDate.valueOf() < Date.now();
    }

    requiresAttention() {
        if (!this.item.expirationDate) {
            return false;
        }

        const expirationDate = new Date(Date.parse(this.item.expirationDate.toString()));
        return this.diffInDaysToToday(expirationDate) <= 90;
    }

    badgeClass() {
        const expirationDate = new Date(Date.parse(this.item.expirationDate.toString()));
        if (this.diffInDaysToToday(expirationDate) <= 30) {
            return 'expiration-badge-level2';
        }

        if (this.diffInDaysToToday(expirationDate) <= 90) {
            return 'expiration-badge-level3';
        }
    }

    numberOfDaysToExpire() {
        if (!this.item.expirationDate) {
            return Number.MAX_VALUE;
        }

        const expirationDate = new Date(Date.parse(this.item.expirationDate.toString()));
        return this.diffInDaysToToday(expirationDate);
    }

    private diffInDaysToToday(date: Date): number {
        return this.diffInDays(date, new Date(Date.now()));
    }

    private diffInDays(date1: Date, date2: Date): number {
        return Math.floor((date1.valueOf() - date2.valueOf()) / (1000 * 60 * 60 * 24));
    }
}
