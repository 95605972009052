import { Component, OnInit, Input } from '@angular/core';
import { HiramDetails } from 'src/app/core/model/hiram-details';
import { Observable } from 'rxjs';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';

@Component({
    selector: 'hiram-basic-details',
    templateUrl: './basic-details.component.html',
    styleUrls: ['../print.component.scss'],
})
export class BasicDetailsComponent implements OnInit {
    constructor(private service: HiramService) {}

    details$: Observable<HiramDetails>;
    level$: Observable<number>;

    ngOnInit() {}

    @Input() set hiramId(value: number) {
        if (value) {
            this.details$ = this.service.byId(value);
            this.level$ = this.service.hiramLevel(value);
        }
    }

    classByLevel(level: number) {
        if (level === 1) {
            return 'text-danger';
        } else if (level === 2) {
            return 'text-warning';
        } else if (level === 3) {
            return 'text-primary';
        }
        return 'text-success';
    }
}
