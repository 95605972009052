import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'hiram-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
    constructor() {}

    @Input() size = '3rem';

    ngOnInit() {}
}
