import { Injectable } from '@angular/core';
import { HazardStatement } from '../../model/hazard-statement';

@Injectable({
    providedIn: 'root',
})
export class HazardStatementService {
    constructor() {}

    public hazardStatementSplitRegex = /([H][0-9]{3}:)/g;
    hazardStatementReplacer = '###$&';
    hazardStatementDelimiter = '###';

    splitHazardStatementText(hazardStatement: string): string[] {
        let hazardStatementList = [];
        const regexMatch = hazardStatement.match(this.hazardStatementSplitRegex);
        if (regexMatch && regexMatch.length > 0) {
            const hazardStatementWithDelimiters = hazardStatement.replace(
                this.hazardStatementSplitRegex,
                this.hazardStatementReplacer
            );
            hazardStatementList = hazardStatementWithDelimiters.split(this.hazardStatementDelimiter);
            if (hazardStatementList[0] === '') {
                hazardStatementList.shift();
            }
        } else {
            hazardStatementList.push(hazardStatement);
        }
        return hazardStatementList;
    }

    extractHazardStatementsWithCode(hazardStatement: HazardStatement): { code: string; statement: string }[] {
        const hazardStatementsWithCode = [];
        const hazardStatementList = this.splitHazardStatementText(hazardStatement.statements);
        hazardStatementList.forEach(hazardText => {
            const regexMatch = hazardText.match(this.hazardStatementSplitRegex);
            if (regexMatch && regexMatch.length > 0) {
                hazardStatementsWithCode.push({ code: regexMatch.toString().substring(0, 4), statement: hazardText });
            } else {
                hazardStatementsWithCode.push({ code: hazardText, statement: hazardText });
            }
        });
        return hazardStatementsWithCode;
    }
}
