import { Injectable } from '@angular/core';
import { HttpBackend, HttpRequest, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    constructor(private http: HttpBackend) {}

    load(): Observable<any> {
        console.log('getting config.json');
        return this.http.handle(new HttpRequest<any>('GET', '/config.json')).pipe(
            filter(data => data instanceof HttpResponse),
            tap((data: HttpResponse<any>) => {
                environment.homeUrl = data.body.homeUrl;
                environment.baseDomain = data.body.baseDomain;
                environment.authUrl = data.body.authApiUrl;
                environment.baseUrl = data.body.baseApiUrl;
                environment.APIUrl = `${data.body.baseApiUrl}/api`;
                environment.appInsightsInstrumentationKey = data.body.appInsightsInstrumentationKey;
                environment.midasApiUrl = data.body.midasApiUrl;
                environment.adalConfig.tenant = data.body.tenantId;
                environment.adalConfig.clientId = data.body.clientId;
                environment.adalConfig.redirectUri = data.body.redirectUri;
                environment.adalConfig.endpoints[data.body.baseApiUrl] = data.body.clientId;

                if (console.table) {
                    console.table(getObjectWithNoAdalConfig(environment));
                }
            })
        );
    }
}

function getObjectWithNoAdalConfig(obj) {
    const result = { ...obj };
    result.adalConfig = null;
    return result;
}
