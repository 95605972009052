import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachmentsListComponent } from './attachments-list/attachments-list.component';
import { IconModule } from 'src/app/icon.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AttachmentItemComponent } from './attachment-item/attachment-item.component';

@NgModule({
    declarations: [AttachmentsListComponent, AttachmentItemComponent],
    exports: [AttachmentsListComponent],
    imports: [
        CommonModule,
        NgxFileDropModule,
        IconModule,
        FormsModule,
        ReactiveFormsModule,
        HiramCommonsModule,
        TooltipModule.forRoot(),
        AlertModule.forRoot(),
    ],
})
export class AttachmentsModule {}
