import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbraTicklerListComponent } from './sbra-tickler-list/sbra-tickler-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { SbraTicklerListItemComponent } from './sbra-tickler-list-item/sbra-tickler-list-item.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
    declarations: [SbraTicklerListComponent, SbraTicklerListItemComponent],
    imports: [
        CommonModule,
        FontAwesomeModule,
        HiramCommonsModule,
        ReactiveFormsModule,
        FormsModule,
        TooltipModule.forRoot(),
        ToastrModule.forRoot(),
    ],
    exports: [SbraTicklerListComponent],
})
export class SbraTicklerModule {}
