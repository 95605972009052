import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DocumentCategory } from 'src/app/core/model/document-category';
import { Document } from 'src/app/core/model/document';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from '../../hiram-commons/confirmation-service/confirmation-service.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { DocumentsService } from 'src/app/core/services/documents/documents.service';
import { tap } from 'rxjs/operators';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceOut, bounceIn } from 'ng-animate';

// tslint:disable: no-console

@Component({
    selector: 'hiram-document-category-item',
    templateUrl: './document-category-item.component.html',
    styleUrls: ['./document-category-item.component.scss'],
    animations: [
        trigger('bounce', [
            transition(':enter', useAnimation(bounceIn)),
            transition(':leave', useAnimation(bounceOut)),
        ]),
    ],
})
export class DocumentCategoryItemComponent implements OnInit {
    @ViewChild('attachmentsInput', { static: false }) fileInput;
    @ViewChild('inputForm', { static: false }) inputForm;

    constructor(
        private service: DocumentsService,
        private toastr: ToastrService,
        private confirmationService: ConfirmationService
    ) {}

    @Input() category: DocumentCategory;
    @Input() items: Document[] = [];
    @Input() filesInProgress: Document[] = [];

    @Output() newItem = new EventEmitter<Document>();
    @Output() deletedItem = new EventEmitter<Document>();

    loading = false;

    ngOnInit() {}

    itemsToList() {
        return this.items.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
    }

    public dropped(files: NgxFileDropEntry[]) {
        console.log('dropped file');

        if (files.length > 1) {
            this.toastr.error('Only one file should be dropped at a time');
            return;
        }

        const droppedFile = files[0];
        if (!droppedFile.fileEntry.isFile) {
            this.toastr.error('Directories cannot be uploaded!');
            return;
        }

        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => this.triggerFileUpload(file));
    }

    filesSelectedFromFileInput(fileInput) {
        console.log('filesSelectedFromFileInput');
        const files: FileList = fileInput.files;
        console.log(files);
        for (const key in files) {
            if (files.hasOwnProperty(key)) {
                const file: File = files[key];
                this.triggerFileUpload(file);
            }
        }
        this.inputForm.nativeElement.reset();
    }

    openFileExplorer() {
        this.fileInput.nativeElement.click();
    }

    delete(item: Document) {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.filesInProgress.push(item);
                this.service.delete(item).subscribe(() => {
                    this.deletedItem.emit(item);
                    this.filesInProgress.splice(this.filesInProgress.indexOf(item), 1);
                });
            },
        });
    }

    inProgress(item) {
        return this.filesInProgress.indexOf(item) !== -1;
    }

    private triggerFileUpload(file: File) {
        const newDocument = this.createNewDocument(file);

        this.newItem.emit(newDocument);
        this.filesInProgress.push(newDocument);

        console.time('file upload');
        this.service
            .add(this.category, file.name, file)
            .pipe(
                tap(() => this.filesInProgress.splice(this.filesInProgress.indexOf(newDocument), 1)),
                tap(() => console.timeEnd('file upload'))
            )
            .subscribe(
                newItem => (newDocument.id = newItem.id),
                error => this.errorOnUpload(error, newDocument)
            );
    }

    private createNewDocument(file: File): Document {
        return {
            id: -1,
            timestamp: new Date(Date.now()),
            documentCategoryId: this.category.id,
            fileName: file.name,
        };
    }

    private errorOnUpload(error, attachment) {
        this.filesInProgress.length = 0;
        this.items.splice(this.items.indexOf(attachment), 1);
        this.toastr.error(error.message);
    }
}
