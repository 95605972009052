import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminListComponent } from './admin-list/admin-list.component';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [AdminListComponent, AddAdminComponent],
    imports: [CommonModule, HiramCommonsModule, FontAwesomeModule, FormsModule, ReactiveFormsModule],
    exports: [AdminListComponent]
})
export class AdministratorsModule {}
