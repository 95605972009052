import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChemicalHazardOverviewComponent } from './chemical-hazard-overview.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { IconModule } from 'src/app/icon.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [ChemicalHazardOverviewComponent],
    imports: [CommonModule, IconModule, FormsModule, ReactiveFormsModule, HiramCommonsModule, RouterModule],
    exports: [ChemicalHazardOverviewComponent],
})
export class ChemicalHazardOverviewModule {}
