import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Params } from '@angular/router';
import { filter } from 'rxjs/operators';
import { HiramLoadedObserverService } from '../core/services/hiram-loaded-observer/hiram-loaded-observer.service';
import { HiramService } from '../core/services/hiram-service/hiram.service';
import { HiramDetails } from '../core/model/hiram-details';
import { Subscription } from 'rxjs';
import { NavigationService } from '../core/services/navigation/navigation.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce } from 'ng-animate';

interface Breadcrumb {
    name: string;
    route: string;
    params: Params;
    isHiramDescription?: boolean;
}

@Component({
    selector: 'hiram-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    animations: [
        trigger('bounce', [
            transition(':increment', useAnimation(bounce)),
            transition(':decrement', useAnimation(bounce)),
        ]),
    ],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
    breadcrumbs: Breadcrumb[] = [];
    hiramId: number;
    hiramLevel: number;
    hiramLevelSubscription: Subscription;

    constructor(
        private router: Router,
        private hiramLoadedObserver: HiramLoadedObserverService,
        private hiramService: HiramService,
        private navigation: NavigationService
    ) {}

    ngOnInit() {
        this.hiramLevel = null;
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => (this.breadcrumbs = this.getBreadcrumbs()));

        this.hiramLoadedObserver.newHiramLoaded.subscribe(this.whenHiramIsLoaded());
    }

    ngOnDestroy(): void {
        if (this.hiramLevelSubscription) {
            this.hiramLevelSubscription.unsubscribe();
        }
    }

    whenHiramIsLoaded() {
        return (hiramData: HiramDetails) => {
            this.hiramId = hiramData.id;
            if (this.router.url.startsWith('/hiramdetails')) {
                this.breadcrumbs = this.getBreadcrumbs();
                this.breadcrumbs.push({
                    name: `${hiramData.hiramNumber} - ${hiramData.title}`,
                    isHiramDescription: true,
                    route: null,
                    params: null,
                });

                if (this.hiramLevelSubscription) {
                    this.hiramLevelSubscription.unsubscribe();
                }

                this.hiramLevel = null;
                this.hiramLevelSubscription = this.hiramService.hiramLevelChange.subscribe(
                    (changeData: { hiramId: number; level: number }) => {
                        this.hiramLevel = changeData.level;
                    }
                );

                this.hiramService.refreshHiramLevel(this.hiramId);
            }
        };
    }

    private getBreadcrumbs(): Breadcrumb[] {
        const result: Breadcrumb[] = [];

        if (this.router.url.startsWith('/hiramdetails')) {
            result.push({ name: 'Home', route: '/', params: null });
            result.push({ name: 'List', route: '/hirams/all', params: null });
        }

        return result;
    }

    print() {
        this.navigation.gotoHiramPrint(this.hiramId);
    }
}
