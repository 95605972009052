import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from 'src/app/icon.module';
import { HiramResearchersAddComponent } from './hiram-researchers-add/hiram-researchers-add.component';
import { HiramResearchersListComponent } from './hiram-researchers-list/hiram-researchers-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { ResearcherCardComponent } from './researcher-card/researcher-card.component';

@NgModule({
    declarations: [HiramResearchersAddComponent, HiramResearchersListComponent, ResearcherCardComponent],
    imports: [
        CommonModule,
        IconModule,
        FormsModule,
        ReactiveFormsModule,
        HiramCommonsModule,
        AlertModule.forRoot(),
        TypeaheadModule.forRoot(),
    ],
    exports: [HiramResearchersListComponent],
})
export class HiramResearchersModule {}
