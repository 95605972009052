import { Component, OnInit } from '@angular/core';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';
import { Approval } from 'src/app/core/model/approval';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'hiram-my-approvals',
    templateUrl: './my-approvals.component.html',
    styleUrls: ['./my-approvals.component.scss'],
})
export class MyApprovalsComponent implements OnInit {
    constructor(private service: ApprovalService) {}

    items: Approval[];
    loading = false;

    ngOnInit() {
        this.refreshItems();
    }

    itemChanged() {
        this.refreshItems();
    }

    private refreshItems() {
        this.loading = true;
        this.items = [];

        this.service
            .getApprovalsForCurrentUser()
            .pipe(tap(() => (this.loading = false)))
            .subscribe(data => (this.items = data));
    }
}
