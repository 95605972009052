import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { UntypedFormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'hiram-other-info',
    templateUrl: './other-info.component.html',
    styleUrls: ['./other-info.component.scss'],
})
export class OtherInfoComponent implements OnInit {
    constructor(private service: HiramService, private formBuilder: UntypedFormBuilder, private toastr: ToastrService) {}

    @Input() hiram: { id?: number; otherInfo?: string };
    @Input() readonly: boolean;

    operationInProgress = false;
    formGroup = this.formBuilder.group({
        text: [null],
    });

    ngOnInit() {
        this.formGroup.controls.text.setValue(this.hiram.otherInfo);
    }

    submit() {
        if (this.readonly) {
            return;
        }

        this.operationInProgress = true;
        this.service
            .updateOtherInfo(this.hiram.id, this.formGroup.value.text)
            .pipe(
                tap(() => (this.operationInProgress = false)),
                tap(() => (this.hiram.otherInfo = this.formGroup.value.text))
            )
            .subscribe(() => this.toastr.success('Other Info text successfully updated!'));
    }
}
