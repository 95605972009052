import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Person } from 'src/app/core/model/person';
import { Approval } from 'src/app/core/model/approval';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'hiram-replace-approver',
  templateUrl: './replace-approver.component.html',
})
export class ReplaceApproverModalComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: UntypedFormBuilder,
    private service: HiramService,
    private toastr: ToastrService
  ) { }

  
  closing: EventEmitter<Approval> = new EventEmitter();
  form: UntypedFormGroup;
  submittingForm = false;
  selectedUserValue: Person;
  hiramId: number;
  approval: Approval;
  working = false;
  originalSignerId: number;

  ngOnInit() {
    this.form = this.buildForm();
    this.originalSignerId = this.approval.signerId;
  }
  
  private buildForm(): UntypedFormGroup {
    return this.formBuilder.group({
      hiramId: [this.hiramId, Validators.required],
      signerId: [null, Validators.required],
    });
  }

  get signer(): Person {
    return this.selectedUserValue;
  }

  set signer(value: Person) {
    if (!value) {
      this.form.controls.signerId.setValue(null);
      return;
    }
    this.selectedUserValue = value;
    this.form.controls.signerId.setValue(value.id);
    this.form.markAsDirty();
  }

  submit() {
    const data = this.form.value;
    data.id = this.approval.id;
    data.hiramId = this.hiramId;
    data.signerId = this.selectedUserValue.id;

    this.working = true;
    this.service
      .replaceApproval(this.hiramId, data)
      .pipe(
        tap(() => (this.working = false)),
        tap(this.showMessageForSuccess())
      )
      .subscribe(this.closeModalAfterSuccessfullyAddingUser());
  }

  cancel() {
    this.closing.emit(null);
    this.bsModalRef.hide();
  }

  isSubmitEnabled(): boolean {
    return this.form.valid
      && !this.form.pristine
      && !this.submittingForm
      && this.signer.id !== this.originalSignerId;
  }

  private showMessageForSuccess() {
    return (_: any) => this.toastr.success('User successfully replaced');
  }

  private closeModalAfterSuccessfullyAddingUser() {
    return (result: Approval) => {
      this.closing.emit(result);
      this.bsModalRef.hide();
    };
  }
}
