import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NumberTileComponent } from './number-tile/number-tile.component';
import { RouterModule } from '@angular/router';
import { NewHiramButtonComponent } from './new-hiram-button/new-hiram-button.component';
import { HiramsSessionComponent } from './hirams-session/hirams-session.component';
import { AdminSessionComponent } from './admin-session/admin-session.component';
import { HiramCommonsModule } from '../hiram-commons/hiram-commons.module';
import { HiramManageButtonComponent } from './hiram-manage-button/hiram-manage-button.component';
import { ApprovalsModule } from '../approvals/approvals.module';
import { HiramListModule } from '../hiram-list/hiram-list.module';
import { MyHiramsListComponent } from './my-hirams-list/my-hirams-list.component';
import { RelatedHiramsListComponent } from './related-hirams-list/related-hirams-list.component';
import { HiramListButtonComponent } from './hiram-list-button/hiram-list-button.component';
import { GetHelpButtonComponent } from './get-help-button/get-help-button.component';
import { TileComponent } from './tile/tile.component';
import { TileCircularComponent } from './tile-circular/tile-circular.component';
import { TileSquareComponent } from './tile-square/tile-square.component';

@NgModule({
    declarations: [
        DashboardComponent,
        NumberTileComponent,
        NewHiramButtonComponent,
        HiramsSessionComponent,
        AdminSessionComponent,
        HiramManageButtonComponent,
        MyHiramsListComponent,
        RelatedHiramsListComponent,
        HiramListButtonComponent,
        GetHelpButtonComponent,
        TileComponent,
        TileCircularComponent,
        TileSquareComponent,
    ],
    imports: [CommonModule, FontAwesomeModule, RouterModule, HiramListModule, HiramCommonsModule, ApprovalsModule],
    exports: [DashboardComponent],
})
export class DashboardModule {}
