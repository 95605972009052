import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, throwError } from 'rxjs';
import { MaterialHmisService } from 'src/app/core/services/material-hmis/material-hmis.service';
import { tap, catchError, map } from 'rxjs/operators';
import { MaterialHMIS } from 'src/app/core/model/materialHMIS';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramMaterialHMISValueItem } from 'src/app/core/model/hiram-materialHMIS-value-item';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HazardStatement } from 'src/app/core/model/hazard-statement';
import { MidasService } from 'src/app/core/services/midas.service';
import { MidasContract } from 'src/app/core/model/midas-contract';

// tslint:disable:max-line-length

@Component({
    selector: 'hiram-material-hmis-add',
    templateUrl: './material-hmis-add.component.html',
    styleUrls: ['./material-hmis-add.component.scss'],
})
export class MaterialHmisAddComponent implements OnInit {
    @Output() OnProductAdded: Subject<HiramMaterialHMISValueItem>;

    @ViewChild('searchTextbox', { static: false })
    searchTextbox;

    public hiramId: number;
    public products: MaterialHMIS[];
    public searchProductForm: UntypedFormGroup;
    public searching = false;
    private selecting = false;
    public errorMessage = false;
    private chemIdRegex = new RegExp('^([0-9]+-[0-9]+-[0-9]+)');
    public message: string;

    constructor(
        public bsModalRef: BsModalRef,
        private service: MaterialHmisService,
        private hiramService: HiramService,
        private fb: UntypedFormBuilder,
        private midasService: MidasService
    ) { }

    ngOnInit() {
        this.searchProductForm = this.buildSearchProductForm();
        this.OnProductAdded = new Subject();

        setTimeout(() => this.searchTextbox.nativeElement.focus());
    }

    searchProduct() {
        const values = this.searchProductForm.value;
        this.searching = true;
        this.errorMessage = false;

        this.midasService.get(values.searchString)
        .pipe(
            tap(() => (this.searching = false)),
            map(items => items.result),
            map(items => this.mapOldMidasToNewMidas(items)),
            catchError(error => this.handleMidasSearchError(error))
        )
        .subscribe(items => (this.products = items));
    }

    filterMidasChemicalsByBeginWith(items: MaterialHMIS[], searchString: string) {
        const validItems = this.validMidasChemicalsBegginingWithSearchTerm(items, searchString);
        const invalidItemsSortedBySearchTerm = this.sortInvalidMidasChemicalsBySearchTerm(items, searchString);

        return validItems.concat(invalidItemsSortedBySearchTerm);
    }

    validMidasChemicalsBegginingWithSearchTerm(items: MaterialHMIS[], searchString: string) {
        const validItems = items.filter(item => this.chemIdRegex.test(item.casNumber));
        return validItems.sort(sortBySearchTermAndTitle(searchString));
    }

    sortInvalidMidasChemicalsBySearchTerm(items: MaterialHMIS[], searchString: string) {
        const itemsWithInvalidChemIdRegex = items.filter(item => !this.chemIdRegex.test(item.casNumber));
        const invalidChemicalsBegginingWithSearchTerm = itemsWithInvalidChemIdRegex.filter(item =>
            item.title.toUpperCase().startsWith(searchString.toUpperCase())
        );
        const otherInvalidChemicals = itemsWithInvalidChemIdRegex.filter(
            item => !item.title.toUpperCase().startsWith(searchString.toUpperCase())
        );
        return invalidChemicalsBegginingWithSearchTerm.concat(otherInvalidChemicals);
    }

    handleMidasSearchError(error) {
        let partial = 'Midas3 app';
        this.message = `Hiram was not able to reach the ${partial} for chemicals data`;
        this.searching = false;
        this.errorMessage = true;
        return throwError(this.message);
    }

    selectProduct(item: MaterialHMIS) {
        if (this.selecting) {
            return;
        }

        this.selecting = true;
        
        this.hiramService
            .addMaterialHMIS(this.hiramId, item)
            .pipe(
                map(newMaterialHMIS => {
                    newMaterialHMIS.hazardStatement = item.hazardStatement;
                    return newMaterialHMIS;
                }),
                map(newMaterialHMIS => this.OnProductAdded.next(newMaterialHMIS))
            )
            .subscribe(_ => this.close());        
    }

    close() {
        this.bsModalRef.hide();
    }

    private buildSearchProductForm() {
        return this.fb.group({
            searchField: ['Chemical Name', Validators.required],
            searchString: [null, Validators.required],
        });
    }

    phsShortName(phsValue: number) {
        return this.service.phsShortName(phsValue);
    }

    phsLongName(phsValue: number) {
        return this.service.phsLongName(phsValue);
    }

    private mapOldMidasToNewMidas(items: MidasContract[]): MaterialHMIS[] {        
        return items.map(item => {        
            return {
                title: item.substanceName,
                casNumber: item.chemID,
                categoryOnePhs: item.isPHSCat1 ? 1 : 0,
                categoryTwoPhs: item.isPHSCat2 ? 1 : 0,
                categoryThreePhs: item.isPHSCat3 ? 1 : 0,
                chronicToxicity: item.isChronic ? 1 : 0,
                health: item.nfpah,
                flammability: item.nfpaf,
                reactivity: item.nfpaf,
                hazardStatement: null,
                fromMidas: true
             } as MaterialHMIS
        });
    }
}

function sortBySearchTermAndTitle(searchString: string) {
    return (a: MaterialHMIS, b: MaterialHMIS) => {
        const title1 = (a.title + '').toUpperCase();
        const title2 = (b.title + '').toUpperCase();
        const term = (searchString + '').toUpperCase();

        if (title1.startsWith(term) && title2.startsWith(term)) {
            return title1.localeCompare(title2);
        }

        if (title1.startsWith(term)) {
            return -1;
        }

        if (title2.startsWith(term)) {
            return 1;
        }

        const titleCompare = title1.localeCompare(title2);
        return titleCompare !== 0 ? titleCompare : (a.casNumber + '').localeCompare(b.casNumber + '');
    };
}
