import { Injectable } from '@angular/core';
import { OtherHazard } from '../../model/other-hazard';
import { Observable } from 'rxjs';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { OtherHazardType } from '../../model/other-hazard-type';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class OtherHazardsService {
    constructor(private api: HiramAPIService) {}

    private all$ = this.api.httpGetMulti<OtherHazard>('otherhazards');
    private allRoles$ = this.api.httpGetMulti<OtherHazardType>('OtherHazardsTypes').pipe(shareReplay());

    public all(): Observable<OtherHazard[]> {
        return this.all$;
    }

    public allRoles(): Observable<OtherHazardType[]> {
        return this.allRoles$;
    }

    public create(item: OtherHazard): Observable<OtherHazard> {
        return this.api.post<OtherHazard>('otherhazards', item);
    }

    public update(item: OtherHazard): Observable<OtherHazard> {
        return this.api.put<OtherHazard>('otherhazards', item);
    }

    public delete(item: OtherHazard): Observable<OtherHazard> {
        return this.api.delete(`otherhazards(${item.id})`, {});
    }
}
