import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Approval } from 'src/app/core/model/approval';

@Component({
    selector: 'hiram-approval-buttons',
    templateUrl: './approval-buttons.component.html',
    styleUrls: ['./approval-buttons.component.scss'],
})
export class ApprovalButtonsComponent implements OnInit {
    constructor() {}

    @Input() item: Approval;
    @Input() workInProgress = false;
    @Input() noComments = false;

    @Output() approve = new EventEmitter();
    @Output() reject = new EventEmitter();

    ngOnInit() {}
}
