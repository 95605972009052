import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApproversComponent } from './approvers.component';
import { IconModule } from 'src/app/icon.module';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AddApproverModalComponent } from './add-approver-modal/add-approver-modal.component';
import { ApprovalCardComponent } from './approval-card/approval-card.component';
import { ApprovalsReportComponent } from './approvals-report/approvals-report.component';
import { ApprovalsSetupComponent } from './approvals-setup/approvals-setup.component';
import { ReportItemComponent } from './approvals-report/report-item/report-item.component';
import { ApprovalHistoryComponent } from './approval-history/approval-history.component';
import { ApprovalsModule } from '../../approvals/approvals.module';
import { EndorsersPreviewComponent } from './endorsers-preview/endorsers-preview.component';
import { ApproverPictureComponent } from './approval-history/approver-picture/approver-picture.component';
import { PreviewItemComponent } from './endorsers-preview/preview-item/preview-item.component';
import { ReplaceApproverModalComponent } from './replace-approver/replace-approver.component';

@NgModule({
    declarations: [
        ApproversComponent,
        AddApproverModalComponent,
        ApprovalCardComponent,
        ApprovalsReportComponent,
        ApprovalsSetupComponent,
        ReportItemComponent,
        ApprovalHistoryComponent,
        EndorsersPreviewComponent,
        ApproverPictureComponent,
        PreviewItemComponent,
        ReplaceApproverModalComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        TooltipModule.forRoot(),
        IconModule,
        HiramCommonsModule,
        AlertModule.forRoot(),
        TypeaheadModule.forRoot(),
        ApprovalsModule,
    ],
    exports: [ApproversComponent, ApprovalsSetupComponent]
})
export class ApproversModule {}
