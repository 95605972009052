// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondary {
  background-color: lightgray !important;
  color: #888 !important;
}

.warning {
  text-shadow: 1px 1px 0px #000000 !important;
  background-color: #ffc107 !important;
  color: white !important;
}

.danger {
  text-shadow: 1px 1px 0px #000000 !important;
  background-color: #dc3545 !important;
  color: white !important;
}

.success {
  text-shadow: 1px 1px 0px #000000 !important;
  background-color: green !important;
  color: white !important;
}

.clickable {
  cursor: pointer;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
