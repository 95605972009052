// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expiration-bg-level1 {
  background-color: #e52020 !important;
  color: white;
  text-shadow: 1px 1px 2px black;
}

.expiration-bg-level2 {
  background-color: #f48642 !important;
  text-shadow: 1px 1px 2px white;
}

.expiration-bg-level3 {
  background-color: #e5c020 !important;
  text-shadow: 1px 1px 2px white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
