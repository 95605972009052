import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { HiramListFilter } from 'src/app/core/model/hiram-list-filter';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Person } from 'src/app/core/model/person';
import { HiramItemHeaderComponent } from './hiram-item-header/hiram-item-header.component';
import { SortController } from 'src/app/core/services/sort-controller';

const PageSize = 20;

@Component({
    selector: 'hiram-list',
    templateUrl: './hiram-list.component.html',
    styleUrls: ['./hiram-list.component.scss'],
})
export class HiramListComponent implements OnInit {
    // tslint:disable: no-misleading-array-reverse

    constructor(
        private service: HiramService,
        private route: ActivatedRoute,
        private navigationService: NavigationService,
        private authService: AuthenticationService
    ) {}

    @Input() showTopButtons = true;

    @ViewChild('header', { static: true }) header: HiramItemHeaderComponent;

    // tslint:disable-next-line: max-union-size
    mode: 'search' | 'allHirams' | 'myHirams' | 'onlyExpired' | 'expiresSoon';
    searchTerm: string;
    sortController = new SortController();
    dataSourceObservable$: Observable<HiramListItem[]>;
    pageTitle = 'HIRAMs';
    items: HiramListItem[] = [];
    apiUrl: string;
    loading = false;
    expirationFilterValue: 'all' | 'onlyExpired' | 'expiresSoon' = 'all';
    stopPendingRequestsSubject: Subject<void> = new Subject<void>();
    stopRoutingSubscription: Subject<void> = new Subject<void>();
    unreadMessages = {};
    currentUser: Person = null;
    showLoadMoreButton = false;
    filter = new HiramListFilter();
    currentPage = 0;

    ngOnInit() {
        this.filter.reset();
        this.apiUrl = environment.APIUrl;

        this.sortController.field = 'title';
        this.sortController.direction = 'asc';
        this.sortController.SortParametersChange.subscribe(_ => {
            this.currentPage = 0;
            this.items = [];
            this.loadItems();
        });

        this.authService.getPersonDataForCurrentUser().subscribe(user => (this.currentUser = user));

        this.route.paramMap
            .pipe(takeUntil(this.stopRoutingSubscription))
            .subscribe(params => this.handleRoutingParamChanges(params));
    }

    private handleRoutingParamChanges(params: ParamMap) {
        this.expirationFilterValue = 'all';

        if (
            this.route.snapshot.url &&
            this.route.snapshot.url.length > 0 &&
            this.route.snapshot.url[0].path === 'search'
        ) {
            this.handleSearch(params);
        } else {
            this.handleDifferentModes(params);
        }
    }

    private handleSearch(params: ParamMap) {
        this.mode = 'search';
        this.searchTerm = params.get('term');
        this.expirationFilterValue = 'all';
        this.pageTitle = 'HIRAM Search Result for "' + this.searchTerm + '"';
        this.currentPage = 0;
        this.items = [];
        this.loadItems();
    }

    handleDifferentModes(params) {
        this.filter.reset();
        switch (params.get('mode')) {
            case 'all':
                this.mode = 'allHirams';
                this.pageTitle = 'HIRAMs';
                break;
            case 'myHirams':
                this.mode = 'myHirams';
                this.pageTitle = 'HIRAMs you own';
                this.filter.role = 'Owner';
                this.authService
                    .getPersonDataForCurrentUser()
                    .subscribe(user => (this.filter.owner = user.displayName));
                break;
            case 'onlyExpired':
                this.mode = 'myHirams';
                this.pageTitle = 'Expired HIRAMs';
                this.filter.role = 'Owner';
                this.filter.onlyExpired = true;
                break;
            case 'expiresSoon':
                this.mode = 'myHirams';
                this.pageTitle = 'Expires Soon';
                this.filter.role = 'Owner';
                this.filter.expiresSoon = true;
                break;
            case 'pendingApproval':
                this.mode = 'myHirams';
                this.pageTitle = 'HIRAMs Pending Approval';
                this.filter.role = 'Owner';
                this.filter.pendingApproval = true;
                break;
        }

        this.loadItems();
    }

    ngDestroy() {
        this.stopRoutingSubscription.next();
        this.sortController.SortParametersChange.unsubscribe();
    }

    hiramList(): HiramListItem[] {
        return this.items;
    }

    downloadExcelFile() {
        this.service.excelReport();
    }

    excelUrl() {
        return environment.baseUrl + '/XLReports';
    }

    numberOfUnreadMessages(item: HiramListItem) {
        return this.unreadMessages[item.id] !== null ? this.unreadMessages[item.id] : 0;
    }

    loadItems() {
        this.loadUnreadMessageCount();
        this.fetchItems();
    }

    loadMoreItems() {
        this.currentPage++;
        this.loadItems();
    }

    loadUnreadMessageCount() {
        this.service.unreadMessageCount().subscribe((data: { hiramId: number; unreadMessages: number }[]) => {
            if (data) {
                data.forEach(each => (this.unreadMessages[each.hiramId] = each.unreadMessages));
            }
        });
    }

    private fetchItems() {
        const list$ = this.getDataSourceObservable();
        console.log('loading...');
        this.stopPendingRequests();
        if (list$) {
            this.loading = true;
            list$.pipe(takeUntil(this.stopPendingRequestsSubject)).subscribe(data => {
                this.showLoadMoreButton = data.length === PageSize;
                this.items = this.items.concat(data);
                this.loading = false;
            });
        }
    }

    searchFilterChange(filterValues: HiramListFilter) {
        this.filter = filterValues;
        this.currentPage = 0;
        this.items = [];
        this.loadItems();
    }

    // tslint:disable-next-line: cognitive-complexity
    private getFilter(): string {
        const result = [];

        if (!this.filter) {
            return null;
        }

        if (this.filter.hiramNumber && this.filter.hiramNumber.length > 0) {
            result.push(`indexof(hiramNumber,\'${this.filter.hiramNumber}\') ne -1`);
        }

        if (this.filter.title && this.filter.title.length > 0) {
            result.push(`indexof(title,\'${this.filter.title}\') ne -1`);
            result.push('title ne null');
        }

        if (this.filter.owner && this.filter.owner.length > 0) {
            result.push(`indexof(ownerDisplayName,\'${this.filter.owner}\') ne -1`);
        }

        if (this.filter.status && this.filter.status.length > 0 && this.filter.status !== 'All') {
            result.push(`statusDescription eq \'${this.filter.status}\'`);
        }

        if (this.filter.level && this.filter.level.length > 0 && this.filter.level !== 'All') {
            result.push(`level eq ${this.filter.level}`);
        }

        if (this.filter.role && this.filter.role.length > 0 && this.filter.role !== 'All') {
            if (this.filter.role === 'Any') {
                result.push(`currentUserRole ne \'No role\'`);
            } else {
                result.push(`currentUserRole eq \'${this.filter.role}\'`);
            }
        }

        if (this.filter.onlyExpired) {
            result.push('numberOfDaysToExpiration lt 0');
        }

        if (this.filter.expiresSoon) {
            result.push('numberOfDaysToExpiration lt 30 AND numberOfDaysToExpiration gt 0');
        }

        if (this.filter.pendingApproval) {
            result.push(`indexof(statusDescription,'Awaiting') ne -1`);
            result.push(
                `(currentUserRole eq 'Owner' OR currentUserRole eq 'Creator' OR currentUserRole eq 'Contributor')`
            );
        }

        return result.join(' AND ');
    }

    showFilters() {
        this.header.filterEnabled = true;
    }

    hideFilters() {
        this.header.filterEnabled = false;
    }

    areFiltersVisible() {
        return this.header.filterEnabled;
    }

    private stopPendingRequests() {
        this.stopPendingRequestsSubject.next();
        this.stopPendingRequestsSubject.complete();
    }

    approvalStatus(item: HiramListItem) {
        return item.approvalStatusDescription === 'Approved';
    }

    gotoNewHiramPage() {
        this.navigationService.gotoNewHiram();
    }

    getDataSourceObservable(): Observable<HiramListItem[]> {
        if (this.mode === 'search') {
            return this.service.search(this.searchTerm, this.getQueryOptions());
        } else if (this.mode === 'allHirams') {
            return this.service.all(this.getQueryOptions());
        } else {
            return this.service.myHirams(this.getQueryOptions());
        }
    }

    getQueryOptions() {
        return {
            top: PageSize,
            skip: this.currentPage * PageSize,
            orderBy: `${this.sortController.field} ${this.sortController.direction}`,
            filter: this.getFilter(),
        };
    }
}
