import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { SplashScreenService } from 'src/app/core/services/splash-screen.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from '../../hiram-commons/confirmation-service/confirmation-service.service';

@Component({
    selector: 'hiram-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.sass'],
})
export class SplashScreenComponent implements OnInit {
    constructor(
        private fb: UntypedFormBuilder,
        private service: SplashScreenService,
        private toastr: ToastrService,
        private confirmationService: ConfirmationService
    ) {}

    mainForm = this.fb.group({ text: [null] });

    ngOnInit() {
        this.service.lastest().subscribe(data => {
            console.log(data);
            this.mainForm.controls.text.setValue(data);
        });
    }

    submit() {
        this.confirmationService.ask({
            message:
                'This operation will override the current splash ' +
                'screen message and will be pushed to all users. ' +
                'Are you sure you want to proceed?',
            andRunIfConfirmed: () => {
                this.service.add(this.mainForm.value.text).subscribe(() => {
                    this.toastr.success('Splash screen message updated!');
                });
            },
        });
    }
}
