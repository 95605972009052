// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active {
  color: black !important;
}

.nav {
  overflow: hidden;
  z-index: 2;
  background-color: #fff;
  height: 100%;
}
.nav .nav-item {
  color: #aaaaaa;
  white-space: nowrap;
  transition: color 0.1s linear;
}
.nav .nav-item .active, .nav .nav-item :hover {
  color: black;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
.highlighted {
  animation: blink 1s step-start 0s infinite;
  -webkit-animation: blink 1s step-start 0s infinite;
  color: red !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
