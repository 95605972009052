import { InjectionToken } from "@angular/core";

export class AppConfig {
    production: boolean;
    baseApiUrl: string;
    authUrl: string;
    homeUrl: string;
    baseDomain: string;
    version: string;
    APIUrl: string;
    midasAPIUrl: string;
    appInsightsInstrumentationKey: string;
    tenant: string;
    clientId: string;
    redirectUri: string;
    authority: string;
    endpoints: string;
    msGraphUri: string;
}

export let APP_CONFIG = new InjectionToken<AppConfig>('APP_CONFIG');