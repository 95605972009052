import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StandardDocument } from '../../model/standard-document';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { UploadService } from '../upload/upload.service';

@Injectable({
    providedIn: 'root',
})
export class StandardDocumentsService {
    constructor(private api: HiramAPIService, private uploadService: UploadService) {}

    all(): Observable<StandardDocument[]> {
        return this.api.httpGetMulti<StandardDocument>('StandardDocuments');
    }

    update(id: number, fileName: string, file: File): Observable<any> {
        return this.uploadService.post('StandardDocumentsUpload', fileName, file, [
            { name: 'id', value: id.toString() },
        ]);
    }
}
