import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class DashboardStyleService {
    constructor() {}

    tileStyle: 'Square' | 'Circle' = 'Square';
}
