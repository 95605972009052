import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscussionListComponent } from './discussion-list/discussion-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { IconModule } from 'src/app/icon.module';

@NgModule({
    declarations: [DiscussionListComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, HiramCommonsModule, IconModule],
    exports: [DiscussionListComponent],
})
export class DiscussionModule {}
