import { Directive, HostBinding } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[delete-button]',
})
export class DeleteButtonDirective {
    constructor() {}

    @HostBinding('class')
    elementClass = 'btn btn-danger';
}
