import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessPhysicalHazardItemsListComponent } from './process-physical-hazard-items-list/process-physical-hazard-items-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ItemEditableDescriptionComponent } from './item-editable-description/item-editable-description.component';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    declarations: [ProcessPhysicalHazardItemsListComponent, ItemEditableDescriptionComponent],
    imports: [
        CommonModule,
        FontAwesomeModule,
        HiramCommonsModule,
        FormsModule,
        ReactiveFormsModule,
        ToastrModule.forRoot(),
    ],
    exports: [ProcessPhysicalHazardItemsListComponent],
})
export class ProcessPhysicalHazardItemsModule {}
