import { Component, OnInit } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { OimsProcedureReportItem } from 'src/app/core/model/oims-procedure-report-item';
import { UploadService } from 'src/app/core/services/upload/upload.service';

@Component({
    selector: 'hiram-oims-procedure-report',
    templateUrl: './oims-procedure-report.component.html',
    styleUrls: ['./oims-procedure-report.component.scss'],
})
export class OimsProcedureReportComponent implements OnInit {
    constructor(private service: HiramService, private uploadService: UploadService) {}

    items$ = this.service.oimsProceduresReport();

    ngOnInit() {}

    download(item: OimsProcedureReportItem) {
        this.uploadService
            .downloadHiramAttachment(item.attachmentId, item.name)
            .subscribe(() => console.log('download complete'));
    }
}
