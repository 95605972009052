import { Component, OnInit } from '@angular/core';
import { SBRA } from 'src/app/core/model/sbra';
import { RiskMatrixService } from 'src/app/core/services/risk-matrix/risk-matrix.service';
import { SBRAItem } from 'src/app/core/model/sbra-item';

class RiskCategoryItem {
    category: number;
    description: string;
    consequenceLevel: string;
    frequencyRange: string;
}

@Component({
    selector: 'hiram-risk-category-details',
    templateUrl: './risk-category-details.component.html',
    styleUrls: ['./risk-category-details.component.scss'],
})
export class RiskCategoryDetailsComponent implements OnInit {
    // tslint:disable: no-misleading-array-reverse
    constructor(private service: RiskMatrixService) {}

    sbra: SBRA;
    items: RiskCategoryItem[] = [];
    riskCategory: string;

    ngOnInit() {}

    load(sbra: SBRA) {
        this.sbra = sbra;
        this.riskCategory = this.sbra.riskCategory ? this.sbra.riskCategory.toString() : null;
        this.loadItems();
    }

    private loadItems() {
        this.items = [];
        this.sbra.items.forEach(each => {
            if (each.riskAssessmentFrequencyId === 'N/A' || each.riskAssessmentConsequenceLevel === 'N/A') {
                return;
            }

            const riskLevel = this.findRiskLevel(each);

            this.items.push({
                category: riskLevel.value,
                description: each.description,
                consequenceLevel: each.riskAssessmentConsequenceLevel,
                frequencyRange: each.riskAssessmentFrequencyId,
            });

            this.items = this.items.sort(byCategory());
        });
    }

    private findRiskLevel(item: SBRAItem) {
        return this.service.riskLevels.find(
            x => x.level === item.riskAssessmentConsequenceLevel && x.range === item.riskAssessmentFrequencyId
        );
    }
}

function byCategory() {
    return (a, b) => (a.category > b.category ? 1 : -1);
}
