import { Component, OnInit, Input } from '@angular/core';
import { SBRA } from 'src/app/core/model/sbra';

@Component({
    selector: 'hiram-mitigation-table',
    templateUrl: './mitigation-table.component.html',
    styleUrls: ['./mitigation-table.component.scss'],
})
export class MitigationTableComponent implements OnInit {
    @Input() scenario: SBRA;

    constructor() {}

    ngOnInit() {}
}
