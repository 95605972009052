import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlListComponent } from './url-list/url-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UrlItemComponent } from './url-item/url-item.component';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    declarations: [UrlListComponent, UrlItemComponent],
    exports: [UrlListComponent],
    imports: [
        CommonModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        FormsModule,
        HiramCommonsModule,
        ToastrModule.forRoot(),
    ],
})
export class UrlsModule {}
