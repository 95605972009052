import { Component, OnInit, Input } from '@angular/core';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';
import { Person } from 'src/app/core/model/person';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
    selector: 'hiram-item',
    templateUrl: './hiram-item.component.html',
    styleUrls: ['./hiram-item.component.scss'],
    animations: [
        trigger('slideInOut', [
            state(
                'in',
                style({
                    // tslint:disable-next-line: no-duplicate-string
                    'max-height': '500px',
                    opacity: '1',
                    visibility: 'visible',
                })
            ),
            state(
                'out',
                style({
                    'max-height': '0px',
                    opacity: '0',
                    visibility: 'hidden',
                })
            ),
            transition('in => out', [
                group([
                    animate(
                        '400ms ease-in-out',
                        style({
                            opacity: '0',
                        })
                    ),
                    animate(
                        '600ms ease-in-out',
                        style({
                            'max-height': '0px',
                        })
                    ),
                    animate(
                        '1s ease-in-out',
                        style({
                            visibility: 'hidden',
                        })
                    ),
                ]),
            ]),
            transition('out => in', [
                group([
                    animate(
                        '300ms ease-in-out',
                        style({
                            visibility: 'visible',
                        })
                    ),
                    animate(
                        '600ms ease-in-out',
                        style({
                            'max-height': '500px',
                        })
                    ),
                    animate(
                        '1s ease-in-out',
                        style({
                            opacity: '1',
                        })
                    ),
                ]),
            ]),
        ]),
    ],
})
export class HiramItemComponent implements OnInit {
    @Input() item: HiramListItem;
    @Input() numberOfUnreadMessages: number;

    showDetails = false;

    constructor(private navitation: NavigationService) {}

    ngOnInit() {}

    createdBy(hiram: HiramListItem): Person {
        return {
            displayName: hiram.createdByDisplayName,
            domainUserName: hiram.createdByDomainUserName,
            domainName: hiram.createdByDomainName,
        };
    }

    gotoHiramDetails(item: HiramListItem) {
        this.navitation.gotoHiramDetails(item.id);
    }
}
