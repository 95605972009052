import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'hiram-user-picture',
    templateUrl: './user-picture.component.html',
    styleUrls: ['./user-picture.component.scss'],
})
export class UserPictureComponent implements OnInit {
    constructor() {}

    size = '80px';

    @Input() set imageWidth(value) {
        this.size = value;
    }

    get imageWidth(): string {
        return this.size;
    }

    @Input() set imageHeight(value) {
        this.size = value;
    }

    get imageHeight() {
        return this.size;
    }

    @Input() user: { displayName?: string; domainName?: string; domainUserName?: string };

    ngOnInit() {}

    imageUrl() {
        if (this.user && this.user.domainName && this.user.domainUserName) {
            return `http://skypepictures/images/${this.shortDomainName()}/M/${this.user.domainUserName}.jpg`;
        } else {
            return 'assets/images/default_user.png';
        }
    }

    shortDomainName() {
        if (this.user.domainName.indexOf('.') === -1) {
            return this.user.domainName;
        } else {
            return this.user.domainName.substring(0, this.user.domainName.indexOf('.'));
        }
    }
}
