import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
    selector: 'hiram-submit',
    templateUrl: './submit.component.html',
    styleUrls: ['./submit.component.scss'],
})
export class SubmitComponent implements OnInit {
    constructor(
        private service: HiramService,
        private navigationService: NavigationService,
        private toastr: ToastrService
    ) {}

    @Input() hiramId: number;

    resolvingHiramReadyness = false;
    isHiramReadyToSubmit = false;
    validationErrors: string[];
    submitting = false;

    ngOnInit() {
        this.resolvingHiramReadyness = true;
        this.service
            .runSubmitValidation(this.hiramId)
            .pipe(tap(() => (this.resolvingHiramReadyness = false)))
            .subscribe(data => {
                this.isHiramReadyToSubmit = data.errors.length === 0;
                this.validationErrors = data.errors;
            });
    }

    submitHiram() {
        this.submitting = true;
        this.toastr.info('Adding required endorsers and submitting HIRAM...');
        return this.service
            .submit(this.hiramId)
            .pipe(tap(() => this.showSuccessMessage()))
            .subscribe(() => this.refreshHiramDetails());
    }

    showSuccessMessage() {
        this.toastr.success('This HIRAM has been submitted and approvers will be notified!');
    }

    refreshHiramDetails() {
        this.navigationService.gotoHome();
    }
}
