import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { SplashScreenService } from 'src/app/core/services/splash-screen.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'hiram-splash-screen-message',
    templateUrl: './splash-screen-message.component.html',
    styleUrls: ['./splash-screen-message.component.scss'],
})
export class SplashScreenMessageComponent implements OnInit {
    @ViewChild('splashScreen', { static: false }) splashScreen: TemplateRef<any>;
    message: string;

    messageWindow: BsModalRef;

    constructor(private service: SplashScreenService, private modal: BsModalService) {}

    ngOnInit() {
        this.service.getForCurrentUser().subscribe(data => {
            this.message = data;
            if (this.message) {
                this.messageWindow = this.modal.show(this.splashScreen);
            }
        });
    }

    hideMessage() {
        this.messageWindow.hide();
    }

    dontShowMessageAgain() {
        this.messageWindow.hide();
        this.service.suppressForCurrentUser().subscribe(() => console.log('Splash screen has been suppressed!'));
    }
}
