import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { OperationsAndMaintenanceProcedure } from 'src/app/core/model/operations-and-maintenance-procedure';
import { UploadService } from 'src/app/core/services/upload/upload.service';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { OimsProceduresFlagsService } from 'src/app/core/services/oims-procedures-flags.service';

// tslint:disable: no-duplicate-string

@Component({
    selector: 'hiram-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {
    constructor(
        private uploadService: UploadService,
        private service: HiramService,
        private toastr: ToastrService,
        private procedureFlagsService: OimsProceduresFlagsService
    ) {}

    @ViewChild('attachmentsInput', { static: false }) fileInput;
    @Input() readonly = false;
    @Input() item: OperationsAndMaintenanceProcedure = { id: -1 };
    @Output() delete = new EventEmitter();

    procedureFlags = this.procedureFlagsService.flags;

    inProgress = false;

    ngOnInit() {}

    flagDescription() {
        return this.procedureFlags.find(each => each.id === this.procedureType).description;
    }

    get procedureType() {
        if (this.item.sheCritical) {
            return 'she-critical';
        }

        if (this.item.is61procedure) {
            return 'is-6.1';
        }

        return 'none';
    }

    procedureTypeChange(event) {
        this.item.sheCritical = event.target.value === 'she-critical';
        this.item.is61procedure = event.target.value === 'is-6.1';
    }

    downloadDocument() {
        this.uploadService
            .downloadHiramAttachment(this.item.attachmentId, this.item.attachmentFileName)
            .subscribe(_ => console.log('Download completed!'));
    }

    openFileExplorer() {
        this.fileInput.nativeElement.click();
    }

    filesSelectedFromFileInput(fileInput) {
        const files: FileList = fileInput.files;

        if (files.length > 1) {
            this.toastr.error('Only one file should be dropped at a time');
            return;
        }

        this.upload(files[0]);
    }

    upload(file) {
        this.inProgress = true;
        this.service
            .updateAttachment(this.item.attachmentId, file.name, file)
            .pipe(tap(() => (this.inProgress = false)))
            .subscribe(
                newItem => {
                    this.item.attachmentFileName = file.name;
                    this.toastr.success('The document has been successfully updated!');
                },
                error => this.errorOnUpload(error)
            );
    }

    private errorOnUpload(error) {
        this.inProgress = false;
        this.toastr.error(error.message);
    }
}
