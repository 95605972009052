import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OtherInfoQuestionItemComponent } from './other-info-question-item/other-info-question-item.component';
import { OtherInfoQuestionsComponent } from './other-info-questions.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [OtherInfoQuestionItemComponent, OtherInfoQuestionsComponent],
    exports: [OtherInfoQuestionsComponent],
    imports: [
        CommonModule,
        FontAwesomeModule,
        HiramCommonsModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
    ],
})
export class OtherInfoQuestionsModule {}
