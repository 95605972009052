import { Injectable } from '@angular/core';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { OtherInfoQuestion } from '../../model/other-info-question';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class OtherInfoQuestionsService {
    private readonly serviceUrl = 'otherInfoQuestions';
    private serviceUrlForId = item => `${this.serviceUrl}(${item.id})`;

    constructor(private api: HiramAPIService) {}

    all(): Observable<OtherInfoQuestion[]> {
        return this.api.httpGetMulti(this.serviceUrl);
    }

    add(item: OtherInfoQuestion): Observable<OtherInfoQuestion> {
        return this.api.post(this.serviceUrl, item);
    }

    update(item: OtherInfoQuestion): Observable<OtherInfoQuestion> {
        return this.api.put(this.serviceUrlForId(item), item);
    }

    delete(id: number): Observable<OtherInfoQuestion> {
        return this.api.delete(this.serviceUrlForId({ id }), id);
    }
}
