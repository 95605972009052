import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from 'src/app/icon.module';
import { DetailsFormComponent } from './details-form/details-form.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { OtherHazardsModule } from '../../admin/other-hazards/other-hazards.module';
import { HiramOtherHazardsListComponent } from './hiram-other-hazards-list/hiram-other-hazards-list.component';
import { OtherHazardsChecklistComponent } from './other-hazards-checklist/other-hazards-checklist.component';
import { NgxFileDropModule } from 'ngx-file-drop';

@NgModule({
    declarations: [HiramOtherHazardsListComponent, DetailsFormComponent, OtherHazardsChecklistComponent],
    imports: [
        CommonModule,
        NgxFileDropModule,
        IconModule,
        CommonModule,
        ModalModule.forRoot(),
        IconModule,
        FormsModule,
        ReactiveFormsModule,
        HiramCommonsModule,
        TooltipModule.forRoot(),
        OtherHazardsModule,
    ],
    exports: [HiramOtherHazardsListComponent]
})
export class HiramOtherHazardsModule {}
