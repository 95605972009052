import { Component, OnInit, Input } from '@angular/core';
import { Document } from 'src/app/core/model/document';
import { DocumentsService } from 'src/app/core/services/documents/documents.service';

@Component({
    selector: 'hiram-document-item-description',
    templateUrl: './document-item-description.component.html',
    styleUrls: ['./document-item-description.component.scss'],
})
export class DocumentItemDescriptionComponent implements OnInit {
    constructor(private documentsService: DocumentsService) {}

    @Input() item: Document;
    @Input() readonly = false;

    saving = false;
    edittingDescription = false;
    newDescription = '';

    ngOnInit() {}

    startDescriptionChange() {
        this.edittingDescription = true;
        this.newDescription = this.item.description;
    }

    updateDescription() {
        this.saving = true;
        this.documentsService.changeDescription(this.item.id, this.newDescription).subscribe(() => {
            this.saving = false;
            this.item.description = this.newDescription;
            this.edittingDescription = false;
        });
    }

    cancelDescriptionUpdate() {
        this.edittingDescription = false;
    }
}
