import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PeopleService } from 'src/app/core/services/people-service/people.service';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { Observable, Observer, debounceTime, distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { Person } from 'src/app/core/model/person';

@Component({
    selector: 'hiram-person-picker',
    templateUrl: './person-picker.component.html',
    styleUrls: ['./person-picker.component.scss'],
})
export class PersonPickerComponent implements OnInit, ControlValueAccessor {

    @Output() selectedUserChange = new EventEmitter<Person>();
    @Input() disabled = false;
    @Input()
    set selectedUser(value: Person) {
        this.selectedUserValue = value;
        if (value)
            this.searchTerm.setValue(value.displayName);
        this.propagateChange(value);
    }

    private selectedUserValue: Person;

    searchTerm: FormControl<string>;
    typeaheadLoading: boolean;
    people$?: Observable<Person[]>;

    constructor(private service: PeopleService) {
        this.searchTerm = new FormControl<string>('');
    }

    ngOnInit() {
        this.people$ = new Observable((observer: Observer<string | undefined>) => {
            observer.next(this.searchTerm.value);
        }).pipe(
            debounceTime(600),
            distinctUntilChanged(),
            switchMap((query: string) => {
                return query
                    ? this.service
                        .byTerm(query)
                        .pipe(
                            map((data: Person[]) => data || []),
                            tap(() => this.typeaheadLoading = false)
                        )
                    : of([]);
            })
        );
    }

    onPersonSelect(event: TypeaheadMatch) {
        this.selectedUser = event.item;
    }

    propagateChange = (_: any) => {
        this.selectedUserChange.emit(this.selectedUserValue);
    };

    writeValue(obj: any): void {
        this.selectedUser = obj;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void { }

    setDisabledState?(isDisabled: boolean): void { }

    changeTypeaheadLoading(e: boolean): void {
        this.typeaheadLoading = e;
    }

}
