import { Injectable } from '@angular/core';
import { Url } from '../../model/url';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { Observable } from 'rxjs';
import { UrlCategory } from '../../model/url-category';

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    constructor(private api: HiramAPIService) {}

    all(): Observable<Url[]> {
        return this.api.httpGetMulti<Url>('urls');
    }

    categories(): Observable<UrlCategory[]> {
        return this.api.httpGetMulti<Url>('urlCategories');
    }

    create(url: Url): Observable<Url> {
        return this.api.post('Urls', url);
    }

    update(url: Url): Observable<Url> {
        return this.api.put(`Urls(${url.id})`, url);
    }

    delete(url: Url): Observable<any> {
        return this.api.delete(`Urls(${url.id})`, {});
    }
}
