import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'hiram-control-list-item',
    templateUrl: './control-list-item.component.html',
    styleUrls: ['./control-list-item.component.scss'],
})
export class ControlListItemComponent implements OnInit {
    constructor() {}

    @Input() item: { id: number; text: string };
    @Input() description: string;
    @Output() deleteClick = new EventEmitter();
    @Input() readonly = false;
    @Output() itemChange = new EventEmitter();
    @Input() suggestions: string[];

    ngOnInit() {}

    delete() {
        this.deleteClick.emit();
    }
}
