import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplacePersonComponent } from './replace-person/replace-person.component';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PersonSelectionComponent } from './person-selection/person-selection.component';
import { RelatedHiramsListComponent } from './related-hirams-list/related-hirams-list.component';
import { OperationConfirmationComponent } from './operation-confirmation/operation-confirmation.component';
import { PeopleSideBySideComponent } from './people-side-by-side/people-side-by-side.component';
import { ReadonlyHiramListComponent } from './readonly-hiram-list/readonly-hiram-list.component';

@NgModule({
    declarations: [
        ReplacePersonComponent,
        PersonSelectionComponent,
        RelatedHiramsListComponent,
        OperationConfirmationComponent,
        PeopleSideBySideComponent,
        ReadonlyHiramListComponent,
    ],
    imports: [CommonModule, HiramCommonsModule, ReactiveFormsModule, FormsModule],
    exports: [ReplacePersonComponent],
})
export class ReplacePersonModule {}
