import { Component, OnInit, Input } from '@angular/core';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';
import { Observable } from 'rxjs';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
    selector: 'hiram-history-button',
    templateUrl: './hiram-history-button.component.html',
    styleUrls: ['./hiram-history-button.component.scss'],
})
export class HiramHistoryButtonComponent implements OnInit {
    constructor(private navitation: NavigationService, private service: HiramService) {}

    @Input() item: HiramListItem;

    allVersions$: Observable<HiramListItem[]>;
    showVersionsTable = false;

    ngOnInit() {}

    showVersions() {
        if (!this.allVersions$) {
            this.allVersions$ = this.service.getVersions(this.item.id);
        }
        this.showVersionsTable = true;
    }

    hideVersions() {
        this.showVersionsTable = false;
    }

    gotoHiramDetails(item: HiramListItem) {
        this.navitation.gotoHiramDetails(item.id);
    }

    versionRowClass(hiram: HiramListItem) {
        if (hiram.statusDescription === 'Active') {
            return 'active';
        }

        if (hiram.statusDescription === 'Retired') {
            return 'retired';
        }
    }

    versionRowBadgeClass(hiram: HiramListItem) {
        if (hiram.statusDescription === 'Active') {
            return 'bg-success';
        } else {
            return 'bg-secondary';
        }
    }
}
