import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError, OperatorFunction } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { IODataParams, IODataMultiple } from '.';

const HttpGetMultyOperation = 'httpGetMulti';
const HttpGetSingleOperation = 'httpGetSingle';
const HttpPostOperation = 'httpPost';
const HttpPutOperation = 'httpPut';
const HttpPutMultiOperation = 'httpPutMulti';
const HttpDeleteOperation = 'httpDelete';
const HttpDeleteWithBodyOperation = 'httpDeleteWithBody';

@Injectable({
    providedIn: 'root',
})
export class ODataService {
    headers: HttpHeaders = new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
    });

    constructor(private httpClient: HttpClient, private toastr: ToastrService) {}

    public httpGetMulti<T>(url: string, oDataParams: IODataParams = {}, params = null): Observable<any> {
        return this.httpClient
            .get<IODataMultiple>(this.reqUrl(url, oDataParams), {
                params,
                headers: this.headers,
                withCredentials: true,
            })
            .pipe(map(res => res.value as T[]))
            .pipe(handleError(this.toastr, HttpGetMultyOperation, 'Could not get list of items'));
    }

    public httpGetSingleAndIgnoreError<T>(url: string, oDataParams: IODataParams = {}, params = null): Observable<any> {
        return this.httpClient
            .get<T>(this.reqUrl(url, oDataParams), { params, headers: this.headers, withCredentials: true })
            .pipe(handleErrorSilently());
    }

    public httpGetSingle<T>(url: string, oDataParams: IODataParams = {}, params = null): Observable<any> {
        return this.httpClient
            .get<T>(this.reqUrl(url, oDataParams), { params, headers: this.headers, withCredentials: true })
            .pipe(handleError(this.toastr, HttpGetSingleOperation, 'Could not get item'));
    }

    public httpPost<T>(url: string, data: any, oDataParams: IODataParams = {}): Observable<any> {
        return this.httpClient
            .post<T>(this.reqUrl(url, oDataParams), data, { headers: this.headers, withCredentials: true })
            .pipe(handleError(this.toastr, HttpPostOperation, 'Could not create/update item'));
    }

    public httpPut<T>(url: string, data: any, oDataParams: IODataParams = {}): Observable<any> {
        return this.httpClient
            .put<T>(this.reqUrl(url, oDataParams), data, { headers: this.headers, withCredentials: true })
            .pipe(handleError(this.toastr, HttpPutOperation, 'Could not create/update item'));
    }

    public httpPutMulti<T>(url: string, data: any, oDataParams: IODataParams = {}): Observable<any> {
        return this.httpClient
            .put<IODataMultiple>(this.reqUrl(url, oDataParams), data, { headers: this.headers, withCredentials: true })
            .pipe(map(res => res.value as T[]))
            .pipe(handleError(this.toastr, HttpPutMultiOperation, 'Could not create/update items'));
    }

    public httpDelete(url: string, oDataParams: IODataParams = {}): Observable<any> {
        return this.httpClient
            .delete(this.reqUrl(url, oDataParams), { headers: this.headers, withCredentials: true })
            .pipe(handleError(this.toastr, HttpDeleteOperation, 'Could not delete item'));
    }

    public httpDeleteWithBody(url: string, data: any, oDataParams: IODataParams = {}): Observable<any> {
        return this.httpClient
            .request('DELETE', this.reqUrl(url, oDataParams), {
                headers: this.headers,
                body: data,
                withCredentials: true,
            })
            .pipe(handleError(this.toastr, HttpDeleteWithBodyOperation, 'Could not delete item'));
    }

    private reqUrl(url: string, oDataParams: IODataParams): string {
        return `${url + this.getODataUrl(oDataParams)}`;
    }

    private getODataUrl(oDataParams: IODataParams) {
        let url = '';
        for (const prop in oDataParams) {
            if (oDataParams[prop]) {
                if (url !== '') {
                    url += '&';
                }
                url += `$${prop}=${oDataParams[prop]}`;
            }
        }
        if (url !== '') {
            url = `?${url}`;
        }
        return url;
    }
}

function handleErrorSilently() {
    return catchError((err: any): Observable<{}> => {
        console.error(err);
        return of({});
    });
}

function handleError<T>(toast: ToastrService, operation: string, message: string): OperatorFunction<{}, {}> {
    return catchError((request: any) => {
        console.error(`[ODataService] ${operation} failed`, request);

        if (request.statusText === 'Conflict') {
            return handleConflictErrors(toast, operation, message, request);
        }

        if (
            request &&
            request.error &&
            request.error.error &&
            request.error.error.innererror &&
            request.error.error.innererror.message
        ) {
            toast.error(request.error.error.innererror.message);
        } else {
            toast.error(request.statusText, message);
        }

        return throwError(request);
    });
}

function handleConflictErrors(toast: ToastrService, operation: string, message: string, err: any): Observable<never> {
    if (operation === HttpDeleteOperation) {
        const userMessage = 'This item could not be removed as there are other items still referencing it';
        toast.error(userMessage);
        return throwError(userMessage);
    }

    return throwError(err);
}
