import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Observable } from 'rxjs';
import { HiramOtherInfoQuestionResponse } from 'src/app/core/model/hiram-other-info-question-response';

@Component({
    selector: 'hiram-questions',
    templateUrl: './questions.component.html',
    styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {
    constructor(private service: HiramService) {}

    @Input() hiramId: number;

    items$: Observable<HiramOtherInfoQuestionResponse[]>;

    ngOnInit() {
        this.items$ = this.service.getOtherInfoQuestionResponses(this.hiramId);
    }
}
