import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { HiramStatus } from 'src/app/core/model/hiram-status';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramListFilter } from 'src/app/core/model/hiram-list-filter';

@Component({
    selector: 'hiram-item-header',
    templateUrl: './hiram-item-header.component.html',
    styleUrls: ['./hiram-item-header.component.scss'],
})
export class HiramItemHeaderComponent implements OnInit {
    constructor(private service: HiramService) {}

    @Input()
    sortController: { field: string; direction: 'asc' | 'desc' };

    filterEnabledValue = false;
    hiramNumberFilterValue = '';
    titleFilterValue = '';
    ownerFilterValue = '';
    statusFilterValue = 'All';
    levelFilterValue = 'All';
    roleFilterValue = 'All';
    searchFiltersValues: HiramListFilter;
    hiramStatusList: HiramStatus[];

    @Output()
    filterEnabledChange = new EventEmitter();

    @Output()
    searchFilterChange = new EventEmitter();

    @Input()
    set filterEnabled(value: boolean) {
        this.filterEnabledValue = value;
    }

    get filterEnabled(): boolean {
        return this.filterEnabledValue;
    }

    loadHiramStatusList(hiramStatus: HiramStatus[]) {
        if (hiramStatus && hiramStatus.length > 0 && hiramStatus[0].description !== 'All') {
            const hiramStatusAll = {
                id: -1,
                description: 'All',
                step: -1,
            } as HiramStatus;
            hiramStatus.splice(0, 0, hiramStatusAll);
        }
        this.hiramStatusList = hiramStatus;
    }

    setFocus(control) {
        setTimeout(() => control.focus(), 500);
    }

    searchFilter() {
        this.searchFiltersValues = {
            title: this.titleFilterValue,
            hiramNumber: this.hiramNumberFilterValue,
            owner: this.ownerFilterValue,
            status: this.statusFilterValue,
            level: this.levelFilterValue,
            role: this.roleFilterValue,
        } as HiramListFilter;

        this.searchFilterChange.emit(this.searchFiltersValues);
    }

    ngOnInit() {
        this.service.allStatus().subscribe(items => this.loadHiramStatusList(items));
    }

    clearFilters() {
        this.titleFilterValue = '';
        this.hiramNumberFilterValue = '';
        this.ownerFilterValue = '';
        this.statusFilterValue = 'All';
        this.levelFilterValue = 'All';
        this.roleFilterValue = 'All';
        this.searchFilter();
    }
}
