import { Component, OnInit, EventEmitter } from '@angular/core';
import { PeopleService } from 'src/app/core/services/people-service/people.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Administrator } from 'src/app/core/model/administrator';
import { ToastrService } from 'ngx-toastr';
import { Person } from 'src/app/core/model/person';

@Component({
    selector: 'hiram-add-admin',
    templateUrl: './add-admin.component.html',
    styleUrls: ['./add-admin.component.sass'],
})
export class AddAdminComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: UntypedFormBuilder,
        private service: PeopleService,
        private toastr: ToastrService
    ) {}

    detailsForm: UntypedFormGroup;
    closing = new EventEmitter<Administrator>();

    private selectedUserValue: Person = null;

    ngOnInit() {
        this.detailsForm = this.buildForm();
    }

    get selectedUser(): Person {
        return this.selectedUserValue;
    }

    set selectedUser(value: Person) {
        if (!value) {
            if (this.selectedUser) {
                this.selectedUserValue = null;
                this.detailsForm.controls.personId.setValue(null);
            }
            return;
        }

        this.selectedUserValue = value;
        this.detailsForm.controls.personId.setValue(value.id);
        this.detailsForm.markAsDirty();
    }

    submit() {
        this.service.addAdministrator(this.detailsForm.value.personId).subscribe(result => {
            this.toastr.success(`${result.personDisplayName} has been set as administrator`);
            this.closing.emit(result);
            this.bsModalRef.hide();
        });
    }

    cancel() {
        this.bsModalRef.hide();
    }

    isSubmitEnabled() {
        return this.detailsForm.valid;
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            personId: [null, Validators.required],
        });
    }
}
