import { Component, OnInit, Input } from '@angular/core';
import { HazardStatement } from 'src/app/core/model/hazard-statement';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HazardStatementService } from 'src/app/core/services/hazard-statement/hazard-statement.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceInRight } from 'ng-animate';

@Component({
    selector: 'hiram-hazard-statements-list',
    templateUrl: './hazard-statements-list.component.html',
    styleUrls: ['./hazard-statements-list.component.scss'],
    animations: [
        trigger('bounceInRight', [transition(':enter', useAnimation(bounceInRight, { params: { timing: 2 } }))]),
    ],
})
export class HazardStatementsListComponent implements OnInit {
    constructor(private hiramService: HiramService, private hazardStatementService: HazardStatementService) {}

    @Input() public hiramId: number;

    highlightedMaterial = null;
    materialUnderMouse = null;

    public materials: {
        chemId: string;
        name: string;
        tradeName: string;
        tooltipText: string;
        materialNameFromMaterialHMIS: string;
    }[] = [];
    public statements: { code: string; text: string; chemIds: string[] }[] = [];
    loading = true;

    ngOnInit() {
        this.loading = true;
        this.hiramService.getHazardStatements(this.hiramId).subscribe(items => this.loadData(items));
    }

    selected(statement: { code: string; text: string; chemIds: string[] }, material: { chemId: string; name: string }) {
        return statement.chemIds.find(x => x === material.chemId);
    }

    chemicalNameById(id) {
        return this.materials.find(material => id === material.chemId).tradeName;
    }

    showDetailsForMaterial(id) {
        this.highlightedMaterial = this.materials.find(material => id === material.chemId);
    }

    statementsForHighlightedMaterial() {
        return this.statements.filter(each => each.chemIds.includes(this.highlightedMaterial.chemId));
    }

    validHazardCode(code: string) {
        return code.match(this.hazardStatementService.hazardStatementSplitRegex);
    }

    private loadData(items: HazardStatement[]) {
        items.forEach(item => {
            this.extractHazardStatementsFromText(this.statements, item);
            if (!this.containsMaterial(item)) {
                this.materials.push({
                    chemId: item.chemId,
                    name: item.materialName,
                    tradeName: item.tradeName,
                    tooltipText: item.materialNameFromMaterialHMIS,
                    materialNameFromMaterialHMIS:
                        item.materialNameFromMaterialHMIS && item.materialNameFromMaterialHMIS.length > 20
                            ? `${item.materialNameFromMaterialHMIS.substring(0, 20)}...`
                            : item.materialNameFromMaterialHMIS,
                });
            }
        });
        this.loading = false;
    }

    private extractHazardStatementsFromText(uniqueHazardList: object[], hazardStatement: HazardStatement) {
        if (hazardStatement.statements) {
            const allHazards = this.hazardStatementService.extractHazardStatementsWithCode(hazardStatement);
            allHazards.forEach(hazard => {
                let statement = this.getStatement(hazard);
                if (!statement) {
                    statement = { code: hazard.code, text: hazard.statement, chemIds: [] };
                    uniqueHazardList.push(statement);
                }
                statement.chemIds.push(hazardStatement.chemId);
            });
        }
    }

    private containsMaterial(item: HazardStatement): boolean {
        return this.materials.find(x => x.chemId === item.chemId) !== undefined;
    }

    private getStatement(item: { code: string; statement: string }): { code: string; text: string; chemIds: string[] } {
        return this.statements.find(x => x.code.toString() === item.code.toString());
    }
}
