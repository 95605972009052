import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ExpirationOutlookItem } from 'src/app/core/model/expiration-outlook-item';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { tap } from 'rxjs/operators';
import moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'hiram-expiration-outlook',
    templateUrl: './expiration-outlook.component.html',
    styleUrls: ['./expiration-outlook.component.scss'],
})
export class ExpirationOutlookComponent implements OnInit {
    constructor(private service: HiramService, private formBuilder: UntypedFormBuilder) {}

    runningReport = false;
    reportForm: UntypedFormGroup;

    items$: Observable<ExpirationOutlookItem[]>;

    ngOnInit() {
        this.reportForm = this.buildForm();
    }

    isSubmitEnabled(): boolean {
        return this.reportForm.valid && !this.runningReport;
    }

    run() {
        this.runningReport = true;
        this.items$ = this.service
            .expirationOutlookReport(this.reportForm.value.startDate, this.reportForm.value.endDate)
            .pipe(tap(() => (this.runningReport = false)));
    }

    badgeClassForStatus(status: string) {
        if (status === 'Expired') {
            return 'bg-danger';
        } else if (status.startsWith('Renewed')) {
            return 'bg-info';
        } else {
            return 'bg-secondary';
        }
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            startDate: [moment(Date.now()).subtract(1, 'months').toDate(), [Validators.required]],
            endDate: [moment(Date.now()).toDate(), [Validators.required]],
        });
    }
}
export function validDate(format = 'MM/dd/YYYY'): any {
    return (control: UntypedFormControl): { [key: string]: any } => {
        const val = moment(control.value, format, true);

        if (!val.isValid()) {
            return { invalidDate: true };
        }

        return null;
    };
}
