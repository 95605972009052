import { Component, OnInit, Input, TemplateRef, ViewChild } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramDetails } from 'src/app/core/model/hiram-details';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import { tap, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';
import { ConfirmationService } from '../../hiram-commons/confirmation-service/confirmation-service.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { Person } from 'src/app/core/model/person';
import { ChangeOwnerComponent } from './change-owner/change-owner.component';
import { Organization } from 'src/app/core/model/organization';
import { OrganizationService } from 'src/app/core/services/organization/organization.service';
import { LocationService } from 'src/app/core/services/location/location.service';
import { Location } from 'src/app/core/model/location';
import { LocationValidator } from '../../hiram-commons/custom-validators/location-validator';
import { Observable } from 'rxjs';
import { OrganizationGroup } from 'src/app/core/model/organization-group';
import { ChangeTitleComponent } from './change-title/change-title.component';

@Component({
    selector: 'hiram-basic-details',
    templateUrl: './basic-details.component.html',
    styleUrls: ['./basic-details.component.scss'],
})
export class BasicDetailsComponent implements OnInit {
    constructor(
        private service: HiramService,
        private navigation: NavigationService,
        private formBuilder: UntypedFormBuilder,
        private toastr: ToastrService,
        private auth: AuthenticationService,
        private confirmationService: ConfirmationService,
        private modalService: BsModalService,
        private locationService: LocationService,
        private organizationService: OrganizationService
    ) {
        this.detailsForm = this.buildForm();
    }

    public detailsForm: UntypedFormGroup;
    public hiram: HiramDetails;
    public parentHiram: HiramDetails;
    public childHirams: HiramDetails[];
    public operationInProgress = false;
    public onlyDescription = false;
    public currentUserData: { id?: number } = null;

    public loading = false;
    public isAdmin = false;
    public hiramIdValue: number;
    public hiramReadyToSubmit = false;

    public organizations$: Observable<Organization[]>;
    public locationsFromEmoc: Location[];
    public loadingLocationList = false;
    public firstAccessAsOwnerModalRef: BsModalRef;
    public acceptingOwnershipInProgress = false;
    public isContributor = false;
    organizationGroups: OrganizationGroup[];
    selectedOrganizationGroup: OrganizationGroup;

    @ViewChild('firstAccessAsOwner', { static: false })
    public firstAccessAsOwner: TemplateRef<any>;

    @Input() readonly: boolean;

    ngOnInit() {
        this.auth.isAdmin().subscribe(data => (this.isAdmin = data));
        this.auth.getPersonDataForCurrentUser().subscribe(data => (this.currentUserData = data));
        this.loadLocations();
        this.loadActiveOrganizations();
        this.loadOrganizationGroups();

        if (this.updating()) {
            this.service
                .currentUserIsContributorForHiram(this.hiramId)
                .subscribe(value => (this.isContributor = value));
        }
    }

    @Input() set hiramId(value: number) {
        if (this.hiramIdValue !== value) {
            this.hiramIdValue = value;
            this.hiram = this.emptyHiram();
            if (this.updating()) {
                this.loadHiram();
            }
        }
    }

    get hiramId(): number {
        return this.hiramIdValue;
    }

    private loadOrganizationGroups() {
        this.organizationService.getOrganizationGroups().subscribe(data => {
            this.organizationGroups = data;
        });
    }

    private loadLocations() {
        this.loadingLocationList = true;
        this.locationService.getAll().subscribe(result => {
            this.loadingLocationList = false;
            this.locationsFromEmoc = result;
            this.locationsFromEmoc.sort(this.sortLocationsArray);
            this.locationList.controls.forEach(control => {
                control.setValidators([LocationValidator(this.locationsFromEmoc)]);
            });
        });
    }

    private loadActiveOrganizations() {
        const orgsObs = this.organizationService.getAllActive().pipe(sortByName());

        this.organizations$ = orgsObs.pipe(
            map(orgsObs =>
                orgsObs.filter(
                    p =>
                        !this.detailsForm.get('organizationGroup').value ||
                        p.groupName == this.detailsForm.get('organizationGroup').value.name
                )
            )
        );
    }

    private loadActiveOrganizationsAndGiven(businessUnitName: string) {
        if (businessUnitName) {
            this.organizations$ = this.organizationService
                .getAllActiveAndGiven(businessUnitName)
                .pipe(sortByName())
                .pipe(
                    map(orgsObs =>
                        orgsObs.filter(
                            p =>
                                !this.detailsForm.get('organizationGroup').value ||
                                p.groupName == this.detailsForm.get('organizationGroup').value.name
                        )
                    )
                );
        } else {
            this.loadActiveOrganizations();
        }
    }

    private sortLocationsArray(locationA, locationB) {
        if (locationA.name < locationB.name) {
            return -1;
        }
        if (locationA.name > locationB.name) {
            return 1;
        }
        return 0;
    }

    onChangeOrgGroup(event: any) {
        console.log(event);
        const orgsObs = this.organizationService.getAllActive().pipe(sortByName());

        this.organizations$ = null;
        this.organizations$ = orgsObs.pipe(
            map(orgsObs =>
                orgsObs.filter(
                    p =>
                        !this.detailsForm.get('organizationGroup').value ||
                        p.groupName == this.detailsForm.get('organizationGroup').value.name
                )
            )
        );

        this.detailsForm.get('businessUnitName').reset();
        this.detailsForm.get('businessUnitName').setValue(null);
    }

    isSubmitEnabled(): boolean {
        return (
            this.detailsForm.valid &&
            !this.detailsForm.pristine &&
            !this.operationInProgress &&
            this.detailsForm.get('locationList').valid
        );
    }

    saveIfNotCreating() {
        if (!this.creating()) {
            this.save(null);
        }
    }

    checkLocationAndSaveIfNotCreating() {
        if (this.locationList.valid) {
            this.updateLocationsAndSave();
            return;
        }
    }

    selectLocationAndSave(event: TypeaheadMatch) {
        this.checkLocationAndSaveIfNotCreating();
    }

    removeLocation(index: number) {
        this.locationList.removeAt(index);
        this.updateLocationsAndSave();
    }

    private updateLocationsAndSave() {
        this.detailsForm.patchValue({
            location: this.detailsForm.get('locationList').value.join(';'),
        });
        this.saveIfNotCreating();
    }

    save(config: { showToastr: boolean } = null) {
        if (this.readonly === true) {
            return;
        }

        if (this.creating()) {
            this.toastr.info('Please wait while HIRAM is created...');
        }

        this.operationInProgress = true;
        console.log('data being saved', this.detailsForm.value);

        const hiramDetails: HiramDetails = {
            id: this.detailsForm.value.id,
            title: this.detailsForm.value.title,
            experimentDescription: this.detailsForm.value.experimentDescription,
            site: this.detailsForm.value.site,
            location: this.detailsForm.value.location,
            locationList: this.detailsForm.value.locationList,
            businessUnitName: this.detailsForm.value.businessUnitName,
            businessUnitDescription: this.detailsForm.value.businessUnitDescription,
            volume: this.detailsForm.value.volume,
            pressure: this.detailsForm.value.pressure,
            temperature: this.detailsForm.value.temperature,
            runLength: this.detailsForm.value.runLength,
            runFrequency: this.detailsForm.value.runFrequency,
            organizationGroupId: this.detailsForm.value.organizationGroup.id,
        };

        this.service
            .save(hiramDetails)
            .pipe(
                tap(() => (this.operationInProgress = false)),
                tap(() => {
                    if (config && config.showToastr) {
                        this.toastr.success('HIRAM details successfully saved!');
                    }
                })
            )
            .subscribe(savedItem => this.redirectAfterSaving(savedItem));
    }

    delete() {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.toastr.info('Removing HIRAM...');
                this.operationInProgress = true;
                this.service
                    .delete(this.hiramId)
                    .pipe(
                        tap(() => (this.operationInProgress = false)),
                        tap(() => this.toastr.success('HIRAM successfully removed!'))
                    )
                    .subscribe(_ => this.navigation.gotoHome());
            },
        });
    }

    renew() {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.operationInProgress = true;
                this.toastr.info('HIRAM renewal in progress...');
                this.service
                    .renew(this.hiramIdValue)
                    .pipe(
                        tap(() => (this.operationInProgress = false)),
                        tap(() => this.toastr.success('HIRAM successfully renewed!'))
                    )
                    .subscribe(() => this.navigation.gotoHome());
            },
        });
    }

    retire() {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.operationInProgress = true;
                this.toastr.info('HIRAM retiring in progress...');
                this.service
                    .retire(this.hiramIdValue)
                    .pipe(
                        tap(() => (this.operationInProgress = false)),
                        tap(() => this.toastr.success('HIRAM successfully retired!'))
                    )
                    .subscribe(() => this.navigation.gotoHome());
            },
        });
    }

    clone() {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.operationInProgress = true;
                this.toastr.info('HIRAM cloning in progress...');
                this.service
                    .clone(this.hiramIdValue)
                    .pipe(
                        tap(() => (this.operationInProgress = false)),
                        tap(() => this.toastr.success('HIRAM successfully cloned!'))
                    )
                    .subscribe(() => this.navigation.gotoHome());
            },
            message: 'Please update the cloned Hiram organization and location after cloning it',
            displayDefaultMessage: true,
        });
    }

    withdraw() {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.operationInProgress = true;
                this.service
                    .withdraw(this.hiramIdValue)
                    .pipe(
                        tap(() => (this.operationInProgress = false)),
                        tap(() => this.toastr.success('HIRAM successfully withdrawn!'))
                    )
                    .subscribe(_ => this.navigation.gotoHome());
            },
        });
    }

    daysToExpiration() {
        if (!this.hiram || !this.hiram.expirationDate) {
            return null;
        }

        const expirationDate = new Date(Date.parse(this.hiram.expirationDate.toString()));

        const diff = expirationDate.getTime() - Date.now();
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    changeOwner() {
        const owner = { id: this.hiram.ownerId, displayName: this.hiram.ownerDisplayName };

        const initialState = {
            hiramId: this.hiramId,
            originalOwner: owner,
            owner,
        };

        const modalRef = this.modalService.show(ChangeOwnerComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.content.closing.subscribe((newOwner: Person) => {
            if (!newOwner) {
                return;
            }
            this.hiram.ownerId = newOwner.id;
            this.hiram.ownerDisplayName = newOwner.displayName;
        });
    }

    changeTitle() {
        const initialState = {
            hiramId: this.hiramId,
            title: this.hiram.title,
        };

        const modalRef = this.modalService.show(ChangeTitleComponent, {
            initialState,
            backdrop: 'static',
            keyboard: false,
        });
        modalRef.content.closing.subscribe((newTitle: string) => {
            console.log('out', newTitle);
            if (!newTitle) {
                return;
            }
            this.detailsForm.controls.title.setValue(newTitle);
            this.hiram.title = newTitle
        });
    }

    goBackToList() {
        this.navigation.gotoHiramList();
    }

    updating(): boolean {
        return !this.creating();
    }

    creating(): boolean {
        // tslint:disable-next-line: triple-equals
        return this.hiramIdValue == -1;
    }

    private redirectAfterSaving(savedItem: { id: number }) {
        if (this.creating()) {
            this.navigation.gotoHiramDetails(savedItem.id);
        }
    }

    showSaveButton() {
        if (this.readonly === true) {
            return false;
        }

        if (this.creating()) {
            return true;
        }

        return this.hiram.statusDescription === 'Draft' && this.userHasWriteAccessToHiram();
    }

    showRenewButton() {
        return this.hiram && this.userHasWriteAccessToHiram() && this.hiram.statusDescription === 'Active';
    }

    showWithdrawButton() {
        return (
            this.hiram &&
            this.userHasWriteAccessToHiram() &&
            (this.hiram.statusDescription === 'Awaiting Endorsements' ||
                this.hiram.statusDescription === 'Awaiting Approval')
        );
    }

    showCloneButton() {
        return this.updating();
    }

    showRetireButton() {
        return this.hiram && this.userHasWriteAccessToHiram() && this.hiram.statusDescription === 'Active';
    }

    showDeleteButton() {
        if (this.creating()) {
            return false;
        }

        if (!this.isAdmin && !this.userHasWriteAccessToHiram()) {
            return false;
        }

        return this.hiram.statusDescription === 'Draft';
    }

    public userHasWriteAccessToHiram(): boolean {
        return this.isAdmin || this.isOwner() || this.isCreator() || this.isContributor;
    }

    public currentHiramStatusAllowsEdit(): boolean {
        return this.isAdmin && [2, 3, 4].some(hiramStatusId => this.hiram.statusId === hiramStatusId);
    }

    private setInitialValues(): void {
        if (this.hiram) {
            this.detailsForm.patchValue(this.hiram);
            this.detailsForm.setControl('locationList', this.formBuilder.array(this.buildLocationComponents()));
        }
    }

    private buildLocationComponents() {
        const locationList = [];
        if (this.hiram.location === null) {
            return locationList;
        }

        this.hiram.location.split(';').forEach(element => {
            locationList.push(this.formBuilder.control(element, LocationValidator(this.locationsFromEmoc)));
        });
        return locationList;
    }

    addLocation() {
        this.locationList.push(this.formBuilder.control('', LocationValidator(this.locationsFromEmoc)));
    }

    private loadHiram(): void {
        this.loading = true;
        this.service
            .byId(this.hiramIdValue)
            .pipe(tap(() => (this.loading = false)))
            .subscribe((hiramDetails: HiramDetails) => {
                this.hiram = hiramDetails;
                this.setInitialValues();
                this.loadActiveOrganizationsAndGiven(hiramDetails.businessUnitName);
                this.showAcceptOwnershipMessageIfRequired();

                this.organizationService.getOrganizationGroups().subscribe(data => {
                    this.organizationGroups = data;
                    this.detailsForm
                        .get('organizationGroup')
                        .setValue(this.organizationGroups.find(p => p.id == this.hiram.organizationGroupId));
                });
            });
    }

    private showAcceptOwnershipMessageIfRequired() {
        this.shouldShowAcceptOwnershipMessage().subscribe((showMessage: boolean) => {
            if (showMessage) {
                this.firstAccessAsOwnerModalRef = this.modalService.show(this.firstAccessAsOwner, {
                    class: 'animated bounceInDown',
                });
            }
        });
    }

    acceptOwnership() {
        this.acceptingOwnershipInProgress = true;
        this.service
            .acceptOwnership(this.hiramId)
            .pipe(tap(() => (this.acceptingOwnershipInProgress = false)))
            .subscribe(() => {
                this.firstAccessAsOwnerModalRef.hide();
            });
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            id: [-1],
            title: [null, Validators.required],
            experimentDescription: [null, Validators.required],
            site: ['CL'],
            location: [null],
            locationList: this.formBuilder.array(['']),
            businessUnitName: [null],
            businessUnitDescription: [null],
            volume: [null],
            pressure: [null],
            temperature: [null],
            runLength: [null],
            runFrequency: [null],
            organizationGroup: [null, Validators.required],
        });
    }

    get locationList(): UntypedFormArray {
        return this.detailsForm.get('locationList') as UntypedFormArray;
    }

    private isOwner(): boolean {
        if (!this.currentUserData || !this.hiram) {
            return false;
        }

        return this.currentUserData.id === this.hiram.ownerId;
    }

    private isCreator(): boolean {
        if (!this.currentUserData || !this.hiram) {
            return false;
        }

        return this.currentUserData.id === this.hiram.createdById;
    }

    private emptyHiram(): HiramDetails {
        return { id: -1, title: '', statusId: 1 };
    }

    public shouldShowAcceptOwnershipMessage(): Observable<boolean> {
        return this.auth.getPersonDataForCurrentUser().pipe(
            map((user: Person) => {
                return (
                    user.id === this.hiram.ownerId &&
                    this.hiram.ownerId !== this.hiram.createdById &&
                    !this.hiram.ownerAcceptanceDate
                );
            })
        );
    }
}

function sortByName() {
    // tslint:disable-next-line: no-misleading-array-reverse
    return map((items: Organization[]) => items.sort(byName()));
}

function byName() {
    return (a: Organization, b: Organization) => a.name.localeCompare(b.name);
}
