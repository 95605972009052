// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-badge-1 {
  color: white;
  text-shadow: 1px 1px 2px black;
  background-color: #c60d57;
}

.risk-badge-2 {
  color: black;
  background-color: #ffe500;
}

.risk-badge-3 {
  color: white;
  text-shadow: 1px 1px 2px black;
  background-color: #286cff;
}

.risk-badge-4 {
  color: white;
  text-shadow: 1px 1px 2px black;
  background-color: #069b17;
}

span.round-tabs {
  width: 35px;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  border-radius: 100px;
  background: white;
  z-index: 2;
  left: 0;
  text-align: center;
  font-size: 20px;
}

.nav-item:not(.active) {
  filter: opacity(50%) grayscale(50%);
}

.nav-item:not(.active):hover {
  filter: opacity(80%) grayscale(10%);
}

span.round-tabs.one {
  color: #ddd;
  border: 2px solid #ddd;
}

span.round-tabs.one {
  background: #fff !important;
  border: 2px solid rgb(34, 194, 34);
  color: rgb(34, 194, 34);
}

span.round-tabs.two {
  background: #fff !important;
  border: 2px solid #febe29;
  color: #febe29;
}

div.tab-button-label {
  font-size: 0.8rem;
  color: black;
  text-shadow: 1px 1px 0px gray !important;
}

button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.dashboard-border {
  box-shadow: 0 15px 15px -15px #28a745 !important;
}

.scenarios-border {
  box-shadow: 0 15px 15px -15px #febe29;
}

.card.c2 {
  width: 49.5% !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
