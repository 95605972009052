import { Component, OnInit, Output } from '@angular/core';
import { HiramMaterialHMISValueItem } from 'src/app/core/model/hiram-materialHMIS-value-item';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, Observable } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { tap } from 'rxjs/operators';
import { MaterialHmisService } from 'src/app/core/services/material-hmis/material-hmis.service';
import { MaterialHMISUnit } from 'src/app/core/model/materialHMISUnit';
import { MaterialHMIS } from 'src/app/core/model/materialHMIS';

export function requireInteger(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value == null || control.value === '') {
            return null;
        }

        const validNumber = /^\d+$/.test(control.value);
        return validNumber ? null : { requireInteger: { value: control.value } };
    };
}

export function requireNoNegativeNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value == null || control.value === '') {
            return null;
        }

        const valid = /^-?\d*(\.\d+)?$/.test(control.value);
        if (!valid) {
            return { requireNoNegativeNumber: { value: control.value } };
        } else {
            return null;
        }
    };
}

@Component({
    selector: 'hiram-material-hmis-details',
    templateUrl: './material-hmis-details.component.html',
    styleUrls: ['./material-hmis-details.component.scss'],
})
export class MaterialHmisDetailsComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: UntypedFormBuilder,
        private hiramService: HiramService,
        private materialService: MaterialHmisService
    ) {}

    @Output() OnProductUpdated: Subject<HiramMaterialHMISValueItem>;

    item: HiramMaterialHMISValueItem;
    units: MaterialHMISUnit[];
    detailsForm: UntypedFormGroup;

    submittingForm = false;

    ngOnInit() {
        this.materialService.allUnits().subscribe(units => (this.units = units));
        this.OnProductUpdated = new Subject();
        this.detailsForm = this.buildForm(this.item);
        this.detailsForm.controls.included.setValue(this.item.excluded ? 'N' : 'Y');
    }

    submit() {
        let material: MaterialHMIS = { ...this.detailsForm.getRawValue() };
        material.excluded = material.included !== 'Y';
        console.log('Material', material);
        this.updatePhsCategoriesOnFormToIntValues(material);
        let addOrUpdateMaterial: (hiramId: number, item: MaterialHMIS) => Observable<HiramMaterialHMISValueItem>;
        if (material.id !== 0) {
            addOrUpdateMaterial = this.hiramService.updateMaterialHMIS.bind(this.hiramService);
        } else {
            addOrUpdateMaterial = this.hiramService.addMaterialHMIS.bind(this.hiramService);
        }
        addOrUpdateMaterial(this.item.hiramId, material)
            .pipe(tap(result => this.OnProductUpdated.next(result)))
            .subscribe(() => {
                this.bsModalRef.hide();
            });
    }

    updatePhsCategoriesOnFormToIntValues(material: MaterialHMIS) {
        material.categoryOnePhs = material.categoryOnePhs ? 1 : 0;
        material.categoryTwoPhs = material.categoryTwoPhs ? 2 : 0;
        material.categoryThreePhs = material.categoryThreePhs ? 3 : 0;
    }

    get f() {
        return this.detailsForm.controls;
    }

    close() {
        this.bsModalRef.hide();
    }

    isSubmitEnabled(): boolean {
        return this.detailsForm.valid && !this.detailsForm.pristine && !this.submittingForm;
    }

    readyToShowErrorMessages(control: any) {
        return control.invalid && (control.dirty || control.touched);
    }

    phsLongName(value: number) {
        return this.materialService.phsLongName(value);
    }

    phsShortName(phsValue: number) {
        return this.materialService.phsShortName(phsValue);
    }

    private buildForm(item: HiramMaterialHMISValueItem): UntypedFormGroup {
        return this.formBuilder.group({
            id: [{ value: item.id, disabled: true }],
            hiramId: [{ value: item.hiramId, disabled: true }],
            casNumber: [{ value: item.casNumber, disabled: true }, Validators.required],
            title: [item.title, Validators.required],
            health: [{ value: item.health, disabled: item.fromMidas }, requireInteger()],
            flammability: [{ value: item.flammability, disabled: item.fromMidas }, requireNoNegativeNumber()],
            reactivity: [{ value: item.reactivity, disabled: item.fromMidas }, requireInteger()],
            chronicToxicity: [{ value: item.chronicToxicity, disabled: item.fromMidas }, requireInteger()],
            phs: [{ value: item.phs, disabled: item.fromMidas }],
            comments: [item.comments],
            included: [!item.excluded, Validators.required],
            amount: [item.amount, requireNoNegativeNumber()],
            unitId: [item.unitId],
            fromMidas: [{ value: item.fromMidas, disabled: true }, Validators.required],
            categoryOnePhs: [{ value: item.categoryOnePhs, disabled: item.fromMidas }],
            categoryTwoPhs: [{ value: item.categoryTwoPhs, disabled: item.fromMidas }],
            categoryThreePhs: [{ value: item.categoryThreePhs, disabled: item.fromMidas }],
        });
    }
}
