/* Core Imports */
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private readonly currentUserSubject: ReplaySubject<any> =
    new ReplaySubject(1);

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService
  ) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status) => InteractionStatus.None === status),
        distinctUntilChanged(),
        tap(() => this.setActiveUser())
      )
      .subscribe();
  }

  getCurrentUser(): Observable<any> {
    return this.currentUserSubject.asObservable();
  }

  private async setActiveUser() {
    const msalAccount =
      this.msalService.instance.getActiveAccount() ??
      this.msalService.instance.getAllAccounts()[0];

    const roles = msalAccount.idTokenClaims?.roles ?? [];

    if (msalAccount) {
      this.currentUserSubject.next({
        guid: msalAccount.localAccountId,
        displayName: msalAccount.name,
        email: msalAccount.username,
      });
    }
  }
}
