import { Component, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { SBRAControl } from 'src/app/core/model/SBRAControl';

@Component({
    selector: 'hiram-sbra-form-control-details',
    templateUrl: './sbra-form-control-details.component.html',
    styleUrls: ['./sbra-form-control-details.component.scss'],
})
export class SbraFormControlDetailsComponent implements OnInit {
    @Output() OnUpdate: Subject<SBRAControl>;

    public item: SBRAControl;
    public detailsForm: UntypedFormGroup;
    private submittingForm = false;

    constructor(public bsModalRef: BsModalRef, private formBuilder: UntypedFormBuilder) {}

    ngOnInit() {
        this.OnUpdate = new Subject();
        this.detailsForm = this.buildForm();
        if (this.item) {
            this.detailsForm.patchValue(this.item);
        }
    }

    submit() {
        this.OnUpdate.next({ id: this.item ? this.item.id : 0, ...this.detailsForm.value });
        this.bsModalRef.hide();
    }

    close() {
        this.bsModalRef.hide();
    }

    isSubmitEnabled(): boolean {
        return this.detailsForm.valid && !this.detailsForm.pristine && !this.submittingForm;
    }

    readyToShowErrorMessages(control: any) {
        return control.invalid && (control.dirty || control.touched);
    }

    get f() {
        return this.detailsForm.controls;
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            prevent: [null],
            alert: [null],
            mitigate: [null],
        });
    }
}
