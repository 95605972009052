import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition, useAnimation } from '@angular/animations';
import { MaterialHmisService } from 'src/app/core/services/material-hmis/material-hmis.service';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';
import { Person } from 'src/app/core/model/person';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { MaterialOverviewItem } from 'src/app/core/model/material-overview-item';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { fadeIn } from 'ng-animate';

@Component({
    selector: 'hiram-chemical-hazard-overview',
    templateUrl: './chemical-hazard-overview.component.html',
    styleUrls: ['./chemical-hazard-overview.component.scss'],
    animations: [
        trigger('fadeIn', [transition(':enter', useAnimation(fadeIn))]),
        trigger('openClose', [
            state(
                'open',
                style({
                    width: '80%',
                    opacity: 1,
                })
            ),
            state(
                'closed',
                style({
                    width: '60px',
                    opacity: 0.5,
                })
            ),
            transition('open => closed', [animate('.3s')]),
            transition('closed => open', [animate('0.3s')]),
        ]),
    ],
})
export class ChemicalHazardOverviewComponent implements OnInit {
    constructor(
        private hiramService: HiramService,
        private materialService: MaterialHmisService,
        private formBuilder: UntypedFormBuilder
    ) {}

    productSearchForm: UntypedFormGroup;
    searchingProduct = false;

    products$: Observable<MaterialOverviewItem[]>;
    selectedProduct: MaterialOverviewItem;
    hirams: HiramListItem[] = [];
    showProductList = false;
    showHiramList = false;
    loadingHiramList = false;

    ngOnInit() {
        this.productSearchForm = this.createProductSearchForm();
    }

    searchProduct() {
        this.selectedProduct = null;
        this.searchingProduct = true;
        this.products$ = this.hiramService.materialOverview(this.productSearchForm.value.textSearchCriteria).pipe(
            tap(() => (this.searchingProduct = false)),
            tap(() => (this.showProductList = true))
        );
    }

    selectProduct(product: MaterialOverviewItem) {
        this.selectedProduct = product;
        this.hirams = [];
        this.loadingHiramList = true;
        this.materialService.getHiramsByMaterial(product.casNumber, product.title).subscribe(hirams => {
            console.log('hirams', hirams);
            this.loadingHiramList = false;
            this.hirams = hirams;
            this.showHiramList = true;
        });
    }

    ownedBy(hiram: HiramListItem): Person {
        const domain = hiram.ownerDomainName.includes('.')
            ? hiram.ownerDomainName.substring(0, hiram.createdByDomainName.indexOf('.'))
            : hiram.ownerDomainName;

        return {
            displayName: hiram.ownerDisplayName,
            domainUserName: hiram.ownerDomainUserName,
            domainName: domain,
        };
    }

    private createProductSearchForm(): UntypedFormGroup {
        return this.formBuilder.group({
            textSearchCriteria: [null],
        });
    }
}
