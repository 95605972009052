import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class OimsProceduresFlagsService {
    constructor() {}

    public flags = [
        {
            id: 'she-critical',
            description:
                'This procedure is the last line of defense in mitigating a level 1 or 2 risk to level 3 or 4 (SHE-critical)',
        },
        {
            id: 'is-6.1',
            description: 'This procedure mitigates a level 3 risk to level 4 (OIMS 6.1 procedure)',
        },
    ];
}
