export class HiramOtherHazard {
    id?: number;
    hiramId?: number;
    otherHazardId?: number;
    otherHazardRiskFactor?: number;
    otherHazardDescription?: string;
    otherHazardTypeId?: number;
    otherHazardTypeName?: string;
    otherHazardApprovalRoleName?: string;
}
