import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../core/services/authentication/authentication.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'hiram-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    constructor(
        private formBuilder: UntypedFormBuilder,
        private authService: AuthenticationService,
        private router: Router,
        private modalService: BsModalService
    ) {}

    searchForm: UntypedFormGroup;
    isAdmin$ = this.authService.isAdmin().pipe(tap(data => console.log('isAdmin', data)));
    version = environment.version;

    ngOnInit() {
        this.searchForm = this.buildSearchForm();
    }

    search() {
        this.router.navigate(['/search', this.searchForm.value.criteria]);
    }

    showContactUsWindow() {
        this.modalService.show(ContactUsComponent);
    }

    private buildSearchForm(): UntypedFormGroup {
        return this.formBuilder.group({
            criteria: [null],
        });
    }
}
