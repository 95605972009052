import { Injectable } from '@angular/core';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { Organization } from '../../model/organization';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { OrganizationGroup } from '../../model/organization-group';

@Injectable({
    providedIn: 'root',
})
export class OrganizationService {
    constructor(private api: HiramAPIService) {}

    private allOrgs$ = this.api.httpGetMulti<Organization>('organizations').pipe(shareReplay());

    getAll(): Observable<Organization[]> {
        return this.allOrgs$;
    }

    getAllActive(): Observable<Organization[]> {
        return this.allOrgs$.pipe(map(items => items.filter(item => !item.isLegacy)));
    }

    getAllActiveAndGiven(businessUnitName: string): Observable<Organization[]> {
        if (!businessUnitName) {
            this.getAllActive();
        }

        return this.allOrgs$.pipe(map(items => items.filter(item => !item.isLegacy || item.name === businessUnitName)));
    }

    getOrganizationGroups(): Observable<OrganizationGroup[]> {
        return this.api.httpGetMulti<OrganizationGroup>('OrganizationGroups');
    }
}
