import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [],
    imports: [FontAwesomeModule],
    exports: [FontAwesomeModule],
})
export class IconModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
    }
}
