import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentCategory } from '../../model/document-category';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { UploadService } from '../upload/upload.service';
import { Document } from '../../model/document';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(private api: HiramAPIService, private uploadService: UploadService) {}

    private categories$ = this.api.httpGetMulti<DocumentCategory>('DocumentCategories').pipe(shareReplay());

    all(): Observable<Document[]> {
        return this.api.httpGetMulti<Document>('Documents');
    }

    allCategories(): Observable<DocumentCategory[]> {
        return this.categories$;
    }

    add(category: DocumentCategory, fileName: string, file: File, description: string = null): Observable<any> {
        const data = [];
        data.push({ name: 'categoryId', value: category.id.toString() });

        if (description) {
            data.push({ name: 'description', value: description });
        }

        return this.uploadService.post('DocumentUploader', fileName, file, data);
    }

    changeDescription(id: number, description: string): Observable<Document> {
        return this.api.post(`Documents(${id})/ChangeDescription`, description);
    }

    delete({ id }) {
        return this.api.delete(`Documents(${id})`, {});
    }
}
