import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { SBRA } from 'src/app/core/model/sbra';

@Component({
    selector: 'hiram-controls',
    templateUrl: './controls.component.html',
    styleUrls: ['./controls.component.scss'],
})
export class ControlsComponent implements OnInit {
    constructor(private confirmationService: ConfirmationService) {}

    @Input() sbra: SBRA;
    @Input() readonly = false;
    @Output() controlsChance = new EventEmitter();

    public preventSuggestions: string[] = [
        'Arc Flash Rating',
        'Barriers (e.g. Radiation/Laser Curtains/Shields)',
        'Basic Process Control System (BPCS)',
        'Blinding',
        'Car Seal Open/Closed Valves',
        'Check Valves',
        'Constantly Closed Systems',
        'Critical Alarms/Human Intervention',
        'Design To Standards (No Deviations)',
        'Detonation/Flame Arrestors',
        'Double/Tandem Seals',
        'Electrical Classification',
        'Fireproofing',
        'Forced/Dilution Ventilation (Hoods LEV’s Snorkels)',
        'Grounding/Bonding',
        'Guarding',
        'Heat Tracing',
        'Housekeeping/Maintenance',
        'IH Certification',
        'Inserting/Dilution',
        'Insulation',
        'Mechanical Stops',
        'Normal Procedures',
        'Normal Testing & Inspection',
        'PHS/SHC Memo',
        'PMI',
        'Potable Water Backflow Preventers',
        'PPE',
        'Pressure Relief Devices',
        'Proof/Pressure/Leak Testing',
        'Purging/Pressurization',
        'Restriction Orifices/Flow Limiting Devices',
        'Safety Instrumented Systems/Functions (SIS/SIF)',
        'Segregation',
        'SHE Critical Equipment',
        'SHE Critical Procedures',
        'Sound Proofing',
        'Switches/Interlocks/Trips',
        'Training & Certification',
    ];

    public alertSuggestions: string[] = [
        'Audible Annunciation (Horn, PA, Speakers)',
        'Barricade Tapes',
        'Basic Process Control System (BPCS)',
        'Camera',
        'Color Coding',
        'Combustible Detector',
        'Fire Eye/Detector',
        'General Alarm (GF-FAS)',
        'Heat/Smoke Detector',
        'IH/Emissions Monitoring',
        'Local Alarm',
        'Motion/Flow Sensors',
        'MSA Gas Detector',
        'Oxygen Detector',
        'Personal Monitor',
        'SHE Critical Alarms',
        'Sight',
        'Signs/Labels/Tags',
        'Toxic Detector',
        'Visual Annunciation (Alarm/Strobe Lights)',
    ];

    public mitigateSuggestions: string[] = [
        'Alarm/Shutdown Systems',
        'Concentration < IDLH (Immediately dangerous to life and health)',
        'Concentration < OEL (occupational exposure limit)',
        'Critical Alarms/Human Intervention',
        'Decontamination',
        'Deluge Systems',
        'Design To Standards (No Deviations)',
        'Emergency Response',
        'Emergency Shower/ Eyewash',
        'Evacuation Plans',
        'Excess Flow Shutoff Valves',
        'Fire Extinguishers',
        'Forced/Dilution Ventilation (Hoods/LEV/Snorkels)',
        'Isolation Valves',
        'Medical Response (MOH)',
        'Reactor Quenching',
        'Restricted Access',
        'Routine PPE',
        'Safety Instrumented Systems/Functions (SIS/SIF)',
        'SCBA/Air Line',
        'Secondary Containment',
        'Small Volumes (< 500 cc Flammable Liquids)',
        'Special PPE/RPE',
        'Spill Containment (Berms/Dikes/Catch Pans)',
        'Splash Guards',
        'Sprinkler System',
        'Vapor/Fire Suppression Systems',
    ];

    ngOnInit() {}

    addItem(list: { id: number; text: string }[]) {
        list.push({ id: -1, text: '' });
        this.controlsChance.emit();
    }
}
