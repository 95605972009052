import { Component, OnInit, Input } from '@angular/core';
import { HiramStatus } from 'src/app/core/model/hiram-status';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramDetails } from 'src/app/core/model/hiram-details';

@Component({
    selector: 'hiram-status-bar',
    templateUrl: './status-bar.component.html',
    styleUrls: ['./status-bar.component.scss'],
})
export class StatusBarComponent implements OnInit {
    constructor(private service: HiramService) {}

    @Input() hiramId: number;
    hiramDetails: HiramDetails;
    items: HiramStatus[];

    ngOnInit() {
        this.service.allStatus().subscribe(items => (this.items = items));

        if (this.hiramId > 0) {
            this.service.byId(this.hiramId).subscribe(data => (this.hiramDetails = data));
        }
    }

    isActive(item: HiramStatus) {
        return this.hiramDetails && this.hiramDetails.statusId === item.id;
    }

    statusesOnRegularSequence() {
        if (!this.items) {
            return [];
        }

        return this.items.filter(each => each.step !== null && each.step > -1).sort(this.byStep);
    }

    byStep(item1: HiramStatus, item2: HiramStatus) {
        if (item1.step > item2.step) {
            return 1;
        } else if (item1.step < item2.step) {
            return -1;
        } else {
            return 0;
        }
    }
}
