import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';
import { Person } from 'src/app/core/model/person';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { tap, map } from 'rxjs/operators';

@Component({
    selector: 'hiram-related-hirams-list',
    templateUrl: './related-hirams-list.component.html',
    styleUrls: ['./related-hirams-list.component.scss'],
})
export class RelatedHiramsListComponent implements OnInit {
    constructor(private service: HiramService) {}

    items: HiramListItem[];
    personValue: Person;
    loading = false;
    selected: HiramListItem[] = [];

    @Input() newPerson: Person;
    @Output() selectedItemsChange = new EventEmitter<HiramListItem[]>();
    @Output() cancel = new EventEmitter();

    @Input() set person(value: Person) {
        if (value) {
            this.personValue = value;
            this.loadHIRAMsForPersonToBeReplaced();
        }
    }

    get person() {
        return this.personValue;
    }

    ngOnInit() {}

    next() {
        this.selectedItemsChange.emit(this.selected);
    }

    back() {
        this.cancel.emit();
    }

    selectAll() {
        this.selected = [];
        this.items.forEach(each => this.selected.push(each));
    }

    toggleSelection(hiram: HiramListItem) {
        if (!this.isSelected(hiram)) {
            this.selected.push(hiram);
        } else {
            this.selected.splice(this.selected.indexOf(hiram), 1);
        }
    }

    isSelected(hiram: HiramListItem) {
        return this.selected.indexOf(hiram) !== -1;
    }

    buttonClass(hiram: HiramListItem) {
        if (this.isSelected(hiram)) {
            return 'btn-dark';
        } else {
            return 'btn-outline-secondary';
        }
    }

    loadHIRAMsForPersonToBeReplaced() {
        this.loading = true;
        this.service
            .relatedToUserIgnoringSignedApprovals(this.personValue.id)
            .pipe(
                map(onlyHiramsThatAreNotRetired()),
                tap(() => (this.loading = false))
            )
            .subscribe(data => (this.items = data));
    }
}

function onlyHiramsThatAreNotRetired() {
    return (hirams: HiramListItem[]) => {
        return hirams.filter(each => each.statusDescription !== 'Retired' && each.statusDescription !== 'Inactive');
    };
}
