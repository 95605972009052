import { Injectable, EventEmitter } from '@angular/core';
import { HiramDetails } from '../../model/hiram-details';

@Injectable({
    providedIn: 'root',
})
export class HiramLoadedObserverService {
    constructor() {}

    newHiramLoaded = new EventEmitter<HiramDetails>();
}
