import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { HiramContributor } from 'src/app/core/model/hiram-contributor';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { tap } from 'rxjs/operators';
import { Person } from 'src/app/core/model/person';
import { UntypedFormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { HiramDetails } from 'src/app/core/model/hiram-details';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

@Component({
    selector: 'hiram-contributors',
    templateUrl: './contributors.component.html',
    styleUrls: ['./contributors.component.scss'],
})
export class ContributorsComponent implements OnInit {
    constructor(
        private service: HiramService,
        private modalService: BsModalService,
        private confirmationService: ConfirmationService,
        private auth: AuthenticationService
    ) {}

    @Input() hiram: HiramDetails;

    items: HiramContributor[];
    selectedUser: Person;
    working = false;
    loading = false;
    formGroup: UntypedFormGroup;
    bsModalRef: BsModalRef;
    isAdmin = false;
    currentUserData: Person;

    ngOnInit() {
        this.refresh();
        this.auth.isAdmin().subscribe(data => (this.isAdmin = data));
        this.auth.getPersonDataForCurrentUser().subscribe(data => (this.currentUserData = data));
    }

    canAdd() {
        return (
            this.isAdmin ||
            this.hiram.ownerId === this.currentUserData.id ||
            this.hiram.ownerId === this.currentUserData.id
        );
    }

    showModalForAdding(template: TemplateRef<any>) {
        this.selectedUser = null;
        this.bsModalRef = this.modalService.show(template);
    }

    delete(item: HiramContributor) {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.service.deleteContributor(item.id).subscribe(_ => this.refresh());
            },
        });
    }

    isSubmitEnabled() {
        return this.selectedUser != null;
    }

    submit() {
        this.service.addContributor(this.hiram.id, this.selectedUser.id).subscribe(_ => {
            this.bsModalRef.hide();
            this.refresh();
        });
    }

    cancel() {
        this.bsModalRef.hide();
    }

    private refresh() {
        this.service
            .getContributors(this.hiram.id)
            .pipe(tap(() => (this.loading = false)))
            .subscribe(data => (this.items = data));
    }
}
