import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { tap } from 'rxjs/operators';

export class NameValuePair {
    constructor(public name: string, public value: string) {}
}

@Injectable({
    providedIn: 'root',
})
export class UploadService {
    constructor(private http: HttpClient) {}

    public post(type: string, fileName: string, file: File, formDataItems: NameValuePair[]) {
        const formData = new FormData();

        formData.append('file-name', fileName);
        formData.append('file', file, fileName);

        formDataItems.forEach(item => {
            formData.append(item.name, item.value);
        });

        return this.http.post(this.uploadUrl(type), formData, { withCredentials: true });
    }

    private uploadUrl(type: string): string {
        return `${environment.APIUrl}/${type}`;
    }

    public downloadHiramAttachment(id: number, fileName: string) {
        return this.downloadFromUrl(`${this.downloadUrlForHiramAttachment()}${id}`, fileName);
    }

    public downloadDocument(id: number, fileName: string) {
        return this.downloadFromUrl(`${this.downloadUrlForDocuments()}${id}`, fileName);
    }

    public downloadStandardDocument(id: number, fileName: string) {
        return this.downloadFromUrl(`${this.downloadUrlForStandardDocuments()}${id}`, fileName);
    }

    public downloadFromUrl(url: string, fileName: string) {
        console.log('downloadFromUrl', this.http);
        return this.http
            .get(url, { responseType: 'blob', withCredentials: true })
            .pipe(tap(response => saveFileAs(response, fileName)));
    }

    private downloadUrlForHiramAttachment(): string {
        return `${environment.APIUrl}/Download/GetHiramAttachment/`;
    }

    private downloadUrlForDocuments(): string {
        return `${environment.APIUrl}/Download/GetDocument/`;
    }

    private downloadUrlForStandardDocuments(): string {
        return `${environment.APIUrl}/Download/GetStandardDocument/`;
    }
}

function saveFileAs(response, fileName) {
    const blob = new Blob([response]);
    saveAs(blob, fileName);
}
