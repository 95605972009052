import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';

const min = (a, b) => (a > b ? b : a);

@Component({
    selector: 'hiram-level-card',
    templateUrl: './level-card.component.html',
    styleUrls: ['./level-card.component.scss'],
})
export class LevelCardComponent implements OnInit {
    constructor(private service: HiramService) {}

    @Input() hiramId: number;

    riskCategory: number;
    hazardsLevel: number;
    overallLevel: number;

    ngOnInit() {
        this.service.hiramLevelDetails(this.hiramId).subscribe(data => {
            this.riskCategory = data.sbraRisk;
            this.hazardsLevel = min(data.processHazard, data.chemicalHazard);
            this.overallLevel = data.overall;
        });
    }
}
