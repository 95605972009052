import { Injectable } from '@angular/core';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { Observable } from 'rxjs';
import { ProcessPhysicalHazardItem } from '../../model/process-physical-hazard-item';
import { HiramService } from '../hiram-service/hiram.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ProcessPhysicalHazardsService {
    constructor(private apiService: HiramAPIService, private hiramService: HiramService) {}

    public items(): Observable<ProcessPhysicalHazardItem[]> {
        return this.apiService.httpGetMulti<ProcessPhysicalHazardItem>('ProcessPhysicalHazardItems');
    }

    public updateDescription(item: ProcessPhysicalHazardItem): Observable<ProcessPhysicalHazardItem> {
        return this.apiService.put<ProcessPhysicalHazardItem>(`ProcessPhysicalHazardItems(${item.id})`, item);
    }

    public toggleSelection(hiramId: number, itemId: number): Observable<any> {
        return this.apiService
            .put('HiramProcessPhysicalHazardSelections', { itemId, hiramId })
            .pipe(tap(() => this.hiramService.refreshHiramLevel(hiramId)));
    }

    public loadSelection(hiramId: number): Observable<{ itemCategoryName: string; itemRiskFactor: number }[]> {
        return this.apiService.httpGetMulti(`hirams(${hiramId})/ProcessPhysicalHazardSelection`, null, { hiramId });
    }
}
