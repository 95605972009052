import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'hirams-session',
    templateUrl: './hirams-session.component.html',
    styleUrls: ['./hirams-session.component.scss'],
})
export class HiramsSessionComponent implements OnInit {
    constructor(private navigationService: NavigationService) {}

    ngOnInit() {}

    gotoNewHiramPage() {
        this.navigationService.gotoNewHiram();
    }
}
