import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HiramAPIService } from './hiramapi/hiramapi.service';

@Injectable({
    providedIn: 'root',
})
export class FeedbackService {
    constructor(private api: HiramAPIService) {}

    send(text: string): Observable<any> {
        return this.api.post('SendFeedback', text);
    }
}
