import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { IconModule } from 'src/app/icon.module';
import { OtherHazardsTypeListComponent } from './other-hazards-type-list/other-hazards-type-list.component';
import { OtherHazardsDetailsComponent } from './other-hazards-details/other-hazards-details.component';
import { OtherHazardsListComponent } from './other-hazards-list/other-hazards-list.component';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';

@NgModule({
    declarations: [OtherHazardsListComponent, OtherHazardsTypeListComponent, OtherHazardsDetailsComponent],
    imports: [CommonModule, HiramCommonsModule, IconModule, ModalModule.forRoot(), FormsModule, ReactiveFormsModule],
    exports: [OtherHazardsListComponent, OtherHazardsTypeListComponent]
})
export class OtherHazardsModule {}
