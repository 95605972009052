import { Component, OnInit } from '@angular/core';
import { Endorser } from 'src/app/core/model/endorser';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';
import { ApprovalRole } from 'src/app/core/model/approval-role';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { AddMemberModalComponent } from '../add-member-modal/add-member-modal.component';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'hiram-approval-role-members-list',
    templateUrl: './approval-role-members-list.component.html',
    styleUrls: ['./approval-role-members-list.component.scss'],
})
export class ApprovalRoleMembersListComponent implements OnInit {
    items: Endorser[];
    roles: ApprovalRole[];
    operationInProgress = false;
    modalRef: BsModalRef;
    itemsInProgress = [];

    constructor(
        private service: ApprovalService,
        private modalService: BsModalService,
        private confirmationService: ConfirmationService
    ) {}

    ngOnInit() {
        this.service
            .getApprovalRoles()
            .subscribe(data => (this.roles = data.filter(each => each.isEndorser && !each.userDefined)));
        this.service.getEndorsers().subscribe(data => (this.items = data));
    }

    membersForRole(role: ApprovalRole) {
        if (!this.items) {
            return [];
        }
        return this.items.filter(each => each.approvalRole.name === role.name && each.id > 0);
    }

    addForRole(role: ApprovalRole) {
        const initialState = { role };

        const modalRef = this.modalService.show(AddMemberModalComponent, { initialState });
        modalRef.content.closing.subscribe((item: Endorser) => this.onModalClosing(item));
    }

    onModalClosing(item: Endorser) {
        if (this.modalRef) {
            this.modalRef.hide();
        }

        this.service.getEndorsers().subscribe(data => (this.items = data));
    }

    delete(item: Endorser) {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.itemsInProgress.push(item);
                this.service
                    .deleteEndorser(item)
                    .pipe(tap(() => (this.operationInProgress = false)))
                    .subscribe(_ => {
                        this.items.splice(this.items.indexOf(item), 1);
                        this.itemsInProgress.splice(this.itemsInProgress.indexOf(item), 1);
                    });
            },
        });
    }

    itemIsInProgress(item: Endorser) {
        return this.itemsInProgress.indexOf(item) !== -1;
    }
}
