import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Observable } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { HiramAssociate } from 'src/app/core/model/hiram-associate';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';

@Component({
    selector: 'hiram-researchers-list',
    templateUrl: './hiram-researchers-list.component.html',
    styleUrls: ['./hiram-researchers-list.component.scss'],
})
export class HiramResearchersListComponent implements OnInit {
    @Input() hiramId: number;

    constructor(
        private service: HiramService,
        private modalService: BsModalService,
        private confirmationService: ConfirmationService
    ) {}

    loading = false;
    researchers: { id: number; displayName: string; title: string }[];
    researchersWithOperationInProgress = [];

    modalRef: BsModalRef;

    ngOnInit() {
        this.loadResearchers();
    }

    openAddUserWindow(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    onModalClosing(item: HiramAssociate) {
        this.modalRef.hide();
        if (item && !this.researchers.find(x => x.id === item.id)) {
            this.researchers.push(this.mapAssociateToLocalItem(item));
        }
    }

    deleteResearcher(researcher) {
        this.researchersWithOperationInProgress.push(researcher);
        this.confirmationService.ask({
            andRunIfConfirmed: () => this.deleteAndRemoveFromList(researcher),
            otherwise: () => this.removeFromInProgress(researcher),
        });
    }

    inProgress(researcher) {
        return this.researchersWithOperationInProgress.indexOf(researcher) !== -1;
    }

    noAssignedResearcher() {
        return !this.loading && (!this.researchers || this.researchers.length === 0);
    }

    private removeFromInProgress(researcher) {
        this.researchersWithOperationInProgress.splice(this.researchersWithOperationInProgress.indexOf(researcher), 1);
    }

    private deleteAndRemoveFromList(researcher) {
        this.service
            .deleteAssociate(researcher)
            .subscribe(() => this.researchers.splice(this.researchers.indexOf(researcher), 1));
    }

    private loadResearchers() {
        this.loading = true;
        this.getResearchers()
            .pipe(
                mergeMap(x => x),
                map(x => this.mapAssociateToLocalItem(x)),
                toArray()
            )
            .subscribe(items => {
                this.researchers = items;
                this.loading = false;
            });
    }

    private mapAssociateToLocalItem(x: HiramAssociate): {
        id: number;
        displayName: string;
        title: string;
        domainName: string;
        domainUserName: string;
    } {
        return {
            id: x.id,
            displayName: x.personDisplayName,
            domainName: x.personDomainName,
            domainUserName: x.personDomainUserName,
            title: x.hiramAssociateTypeDescription,
        };
    }

    private getResearchers(): Observable<HiramAssociate[]> {
        return this.service.getAssociates(this.hiramId);
    }
}
