import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
    selector: 'hiram-list-filter-buttons',
    templateUrl: './list-filter-buttons.component.html',
    styleUrls: ['./list-filter-buttons.component.scss'],
})
export class ListFilterButtonsComponent implements OnInit {
    constructor() {}

    listModeValue: 'showAll' | 'onlyMyHirams' = 'onlyMyHirams';
    activeFilterValue: 'all' | 'onlyActive' = 'onlyActive';
    expirationFilterValue: 'all' | 'onlyExpired' = 'all';

    @Output() listModeChange: EventEmitter<'showAll' | 'onlyMyHirams'> = new EventEmitter();
    @Output() activeFilterChange = new EventEmitter();
    @Output() expirationFilterChange = new EventEmitter();

    ngOnInit() {}

    @Input()
    set expirationFilter(value) {
        this.expirationFilterValue = value;
        this.expirationFilterChange.emit(value);
    }

    get expirationFilter() {
        return this.expirationFilterValue;
    }

    @Input()
    set activeFilter(value) {
        this.activeFilterValue = value;
        this.activeFilterChange.emit(this.activeFilterValue);
    }

    get activeFilter() {
        return this.activeFilterValue;
    }

    @Input()
    set listMode(value: 'showAll' | 'onlyMyHirams') {
        const oldValue = this.listModeValue;
        this.listModeValue = value;

        if (oldValue !== value) {
            this.listModeChange.emit(value);
        }
    }

    get listMode(): 'showAll' | 'onlyMyHirams' {
        return this.listModeValue;
    }

    toggleListMode() {
        this.listMode = this.listMode === 'onlyMyHirams' ? 'showAll' : 'onlyMyHirams';
    }

    toggleActiveFilter() {
        this.activeFilter = this.activeFilter === 'onlyActive' ? 'all' : 'onlyActive';
    }
}
