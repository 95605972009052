import { Injectable } from '@angular/core';
import { HiramAPIService } from './hiramapi/hiramapi.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SplashScreenService {
    constructor(private api: HiramAPIService) {}

    public getForCurrentUser(): Observable<string> {
        return this.api.single('CurrentSplashScreenMessage').pipe(map(data => (data ? data.text : null)));
    }

    public lastest(): Observable<string> {
        return this.api
            .httpGetMulti('SplashScreenMessages', { top: 1, orderBy: 'id desc' })
            .pipe(map(data => (!data || data.length === 0 ? '' : data[0].text)));
    }

    public suppressForCurrentUser(): Observable<any> {
        return this.api.post('SuppressSplashScreenForCurrentUser');
    }

    public add(text: string): Observable<any> {
        return this.api.post('SplashScreenMessages', text);
    }
}
