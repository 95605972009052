import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'hiram-risk-level-badge',
    templateUrl: './risk-level-badge.component.html',
    styleUrls: ['./risk-level-badge.component.scss'],
})
export class RiskLevelBadgeComponent implements OnInit {
    constructor() {}

    @Input() value: number;

    ngOnInit() {}

    className() {
        if (this.value < 1 || this.value > 4) {
            return '';
        }

        return `risk-level-${this.value}-bg`;
    }
}
