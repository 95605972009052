import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiramLogListComponent } from './hiram-log-list/hiram-log-list.component';
import { IconModule } from 'src/app/icon.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [HiramLogListComponent],
    imports: [CommonModule, IconModule, FontAwesomeModule, HiramCommonsModule, BrowserAnimationsModule],
    exports: [HiramLogListComponent],
})
export class HiramLogModule {}
