import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Observable } from 'rxjs';
import { HiramLog } from 'src/app/core/model/hiram-log-entry';
import { trigger, style, transition, stagger, animate, query } from '@angular/animations';

@Component({
    selector: 'hiram-log-list',
    templateUrl: './hiram-log-list.component.html',
    styleUrls: ['./hiram-log-list.component.scss'],
    animations: [
        trigger('listAnimation', [
            transition(':enter', [
                query(
                    '.logItem',
                    [
                        style({ opacity: 0, transform: 'translateX(-100px)' }),
                        stagger(100, [
                            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' })),
                        ]),
                    ],
                    { optional: true }
                ),
            ]),
        ]),
    ],
})
export class HiramLogListComponent implements OnInit {
    @Input()
    hiramId: number;
    items$: Observable<HiramLog[]>;

    constructor(private service: HiramService) {}

    ngOnInit() {
        this.items$ = this.service.changeLog(this.hiramId);
    }

    badgeClass(item: HiramLog) {
        switch (item.category) {
            case 'Submit':
                return 'bg-success';
            case 'Created':
            case 'Withdrawn':
            case 'Cloned':
                return 'bg-danger';
            case 'Details':
            case 'Other Hazards':
            case 'Materials':
            case 'Attachments':
                return 'bg-info';
            case 'Approvals':
                return 'bg-dark';
            case 'Ownership':
                return 'bg-warning';
        }

        return 'bg-primary';
    }
}
