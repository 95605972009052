import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { Document } from 'src/app/core/model/document';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { HiramAttachment } from 'src/app/core/model/hiram-attachment';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';

@Component({
    selector: 'hiram-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
    fileBeingAdded: File;

    @ViewChild('attachmentsInput', { static: false }) fileInput;
    @ViewChild('inputForm', { static: false }) inputForm;

    @Input() hiramId: number;

    @Output() fileSelected = new EventEmitter();
    @Output() cancelFileSelection = new EventEmitter();
    @Output() uploadComplete = new EventEmitter<Document>();

    inProgress = false;
    fileDescription = '';

    constructor(private service: HiramService, private toastr: ToastrService) {}

    ngOnInit() {}

    dropped(files: NgxFileDropEntry[]) {
        if (files.length > 1) {
            this.toastr.error('Only one file should be dropped at a time');
            return;
        }

        const droppedFile = files[0];
        if (!droppedFile.fileEntry.isFile) {
            this.toastr.error('Directories cannot be uploaded!');
            return;
        }

        (droppedFile.fileEntry as FileSystemFileEntry).file(file => (this.fileBeingAdded = file));

        this.fileSelected.emit();
    }

    filesSelectedFromFileInput(fileInput) {
        const files: FileList = fileInput.files;

        if (files.length > 1) {
            this.toastr.error('Only one file should be dropped at a time');
            return;
        }

        const droppedFile = files[0];

        this.fileBeingAdded = droppedFile;
        this.fileSelected.emit();
    }

    openFileExplorer() {
        this.fileInput.nativeElement.click();
    }

    cancel() {
        this.fileBeingAdded = null;
        this.cancelFileSelection.emit();
    }

    upload() {
        this.inProgress = true;

        const attachment: HiramAttachment = {
            id: -1,
            hiramId: this.hiramId,
            fileName: this.fileBeingAdded.name,
            description: this.fileDescription,
        };

        this.service
            .addAttachment(attachment, this.fileBeingAdded)
            .pipe(tap(() => (this.inProgress = false)))
            .subscribe(
                newItem => {
                    const newAttachment = this.newAttachment(this.fileBeingAdded);
                    newAttachment.id = newItem.id;
                    this.uploadComplete.emit(newAttachment);
                },
                error => this.errorOnUpload(error)
            );
    }

    private newAttachment(file: File): HiramAttachment {
        return {
            id: -1,
            fileName: file.name,
            hiramId: this.hiramId,
            description: this.fileDescription,
        };
    }

    private errorOnUpload(error) {
        this.inProgress = false;
        this.toastr.error(error.message);
    }
}
