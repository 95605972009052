import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialHmisListComponent, ToNumberPipe } from './material-hmis-list/material-hmis-list.component';
import { MaterialHmisAddComponent } from './material-hmis-add/material-hmis-add.component';
import { MaterialHmisDetailsComponent } from './material-hmis-details/material-hmis-details.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { IconModule } from 'src/app/icon.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
// tslint:disable-next-line
import { MaterialHazardStatementsDetailsComponent } from './material-hazard-statements-details/material-hazard-statements-details.component';

@NgModule({
    declarations: [
        MaterialHazardStatementsDetailsComponent,
        MaterialHmisListComponent,
        MaterialHmisDetailsComponent,
        MaterialHmisAddComponent,
        ToNumberPipe,
    ],
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        IconModule,
        FormsModule,
        ReactiveFormsModule,
        HiramCommonsModule,
        TooltipModule.forRoot(),
    ],
    exports: [MaterialHmisListComponent]
})
export class MaterialHmisModule {}
