import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProceduresListComponent } from './procedures-list/procedures-list.component';
import { IconModule } from 'src/app/icon.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ListItemComponent } from './procedures-list/list-item/list-item.component';
import { NewItemComponent } from './new-item/new-item.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileUploadComponent } from './new-item/file-upload/file-upload.component';
import { HiramCommonsModule } from 'src/app/components/hiram-commons/hiram-commons.module';

@NgModule({
    declarations: [ProceduresListComponent, ListItemComponent, NewItemComponent, FileUploadComponent],
    exports: [ProceduresListComponent],
    imports: [
        CommonModule,
        IconModule,
        HiramCommonsModule,
        TooltipModule.forRoot(),
        FormsModule,
        NgxFileDropModule,
        ReactiveFormsModule,
        AlertModule.forRoot(),
    ]
})
export class ProceduresModule {}
