import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { HiramListItem } from '../../model/hiram-list-item';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { HiramDetails } from '../../model/hiram-details';
import { HiramAssociate } from '../../model/hiram-associate';
import { HiramMaterialHMISValueItem } from '../../model/hiram-materialHMIS-value-item';
import { MaterialHMIS } from '../../model/materialHMIS';
import { ProcessPhysicalAnalysisResult } from '../../model/process-physical-analysis-result';
import { HazardStatement } from '../../model/hazard-statement';
import { SBRA } from '../../model/sbra';
import { map, shareReplay, tap, switchMap } from 'rxjs/operators';
import { HiramOtherHazard as HiramOtherHazard } from '../../model/hiram-other-hazard';
import { SBRAItem } from '../../model/sbra-item';
import { Approval, ApprovalLog } from '../../model/approval';
import { HiramStatus } from '../../model/hiram-status';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SubmitValidationResult } from '../../model/submit-validation-result';
import { HiramAttachment } from '../../model/hiram-attachment';
import { UploadService } from '../upload/upload.service';
import { Person } from '../../model/person';
import { MaterialSummary } from '../../model/material-summary';
import { SBRATickler } from '../../model/sbra-tickler';
import { HiramLog } from '../../model/hiram-log-entry';
import { ExpirationOutlookItem } from '../../model/expiration-outlook-item';
import moment from 'moment';
import { OimsProcedureReportItem } from '../../model/oims-procedure-report-item';
import { EndorsementPreviewItem } from '../../model/endorsement-preview-item';
import { MaterialByOrgNameItem } from '../../model/material-by-org-name-item';
import { HiramsByChemicalItem } from '../../model/hirams-by-chemical-item';
import { MaterialOverviewItem } from '../../model/material-overview-item';
import { HiramRiskLevel } from '../../model/hiram-risk-level';
import { HiramContributor } from '../../model/hiram-contributor';
import { AuthenticationService } from '../authentication/authentication.service';
import { MaterialByLocationItem } from '../../model/material-by-location-item';
import { IODataParams } from '../odata';
import { HiramOtherInfoQuestionResponse } from '../../model/hiram-other-info-question-response';
import { HiramByLocationItem } from '../../model/hiram-by-location-item';

@Injectable({
    providedIn: 'root',
})
export class HiramService {
    constructor(
        private api: HiramAPIService,
        private uploadService: UploadService,
        private http: HttpClient,
        private auth: AuthenticationService
    ) {}

    private allStatuses$ = this.api.httpGetMulti<HiramStatus>('hiramstatus').pipe(shareReplay());

    public hiramCategoryChange = new EventEmitter<{ hiramId: number; category: number }>();
    public hiramLevelChange = new EventEmitter<{ hiramId: number; level: number }>();

    all({ top, orderBy, filter, skip }): Observable<HiramListItem[]> {
        return this.api.httpGetMulti<HiramListItem>('hirams', { top, orderBy, filter, skip });
    }

    search(term: string, { top, orderBy, filter, skip }): Observable<HiramListItem[]> {
        return this.api.httpGetMulti<HiramListItem>('search', { top, orderBy, filter, skip }, { term });
    }

    myHirams(oDataParams: IODataParams = {}): Observable<HiramListItem[]> {
        return this.api.httpGetMulti<HiramListItem>('MyHirams', oDataParams);
    }

    getVersions(id: number): Observable<HiramListItem[]> {
        return this.api.httpGetMulti<HiramListItem>(`hirams(${id})/OtherVersions`);
    }

    getOtherInfoQuestionResponses(hiramId: number): Observable<HiramOtherInfoQuestionResponse[]> {
        return this.api.httpGetMulti<HiramOtherInfoQuestionResponse>(`hirams(${hiramId})/OtherInfoQuestions`);
    }

    updateOtherInfoQuestionResponse(
        id: number,
        response: boolean,
        comments: string
    ): Observable<HiramOtherInfoQuestionResponse> {
        return this.api.put<HiramOtherInfoQuestionResponse>(`OtherInfoQuestionResponse(${id})`, {
            id,
            response,
            comments,
        });
    }

    hiramsOwnedByUser(userId: number): Observable<HiramListItem[]> {
        return this.api.httpGetMulti<HiramListItem>('hirams', { filter: `ownerId eq ${userId}` });
    }

    excelReport(options: { thenRunWhenCompleted: () => void; onError: (errorData: any) => void } = null) {
        this.http
            .get(environment.baseUrl + '/api/XLReports', { responseType: 'arraybuffer', withCredentials: true })
            .subscribe(
                response => {
                    this.downLoadFile(response, 'application/ms-excel');
                    if (options && options.thenRunWhenCompleted) {
                        options.thenRunWhenCompleted();
                    }
                },
                errorData => {
                    if (options && options.onError) {
                        if (errorData.error instanceof ArrayBuffer) {
                            const enc = new TextDecoder('utf-8');
                            options.onError(enc.decode(errorData.error));
                        } else {
                            options.onError(errorData.error);
                        }
                    }
                }
            );
    }

    oimsProceduresReport(): Observable<OimsProcedureReportItem[]> {
        return this.api.httpGetMulti<OimsProcedureReportItem>('oimsProcedures');
    }

    materialOverview(searchTerm: string): Observable<MaterialOverviewItem[]> {
        return this.api.httpGetMulti<any>('materialOverview', null, { searchTerm });
    }

    expirationOutlookReport(fromDate: Date, endDate: Date): Observable<ExpirationOutlookItem[]> {
        const fromDateString = moment(fromDate).format('YYYY-MM-DD');
        const endDateString = moment(endDate).format('YYYY-MM-DD');

        return this.api.httpGetMulti<ExpirationOutlookItem>(
            `ExpirationOutlook(fromDate='${fromDateString}',endDate='${endDateString}')`
        );
    }

    private downLoadFile(data: any, type: string) {
        const blob = new Blob([data], { type });
        const a = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'hirams.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    allStatus(): Observable<HiramStatus[]> {
        return this.allStatuses$;
    }

    byId(id: number): Observable<HiramDetails> {
        return this.api.single<HiramDetails>(`hirams(${id})`);
    }

    acceptOwnership(id: number): Observable<any> {
        return this.api.post(`hirams(${id})/acceptOwnership`);
    }

    updateOtherInfo(hiramId: number, text: any): Observable<any> {
        return this.api.post(`hirams(${hiramId})/UpdateOtherInfo`, text);
    }

    updateRequiredSOCFlag(hiramId: number, value: boolean): Observable<any> {
        return this.api.post(`hirams(${hiramId})/UpdateRequiredSOCFlag`, { value });
    }

    readonly(hiramId: number): Observable<boolean> {
        return this.api.single<HiramDetails>(`hirams(${hiramId})/readonly`).pipe(map(data => data.value));
    }

    canAddAttachments(hiramId: number): Observable<boolean> {
        return this.api.single<HiramDetails>(`hirams(${hiramId})/canAddAttachments`).pipe(map(data => data.value));
    }

    unreadMessageCount(): Observable<{ hiramId: number; unreadMessages: number }[]> {
        return this.api.httpGetMulti('HiramUnreadMessages');
    }

    changeLog(hiramId): Observable<HiramLog[]> {
        return this.api.httpGetMulti<HiramLog>(`hirams(${hiramId})/ChangeLog`);
    }

    materialSummary(hiramId: number): Observable<MaterialSummary> {
        return this.api.single<MaterialHMIS>(`hirams(${hiramId})/MaterialSummary`, null, null);
    }

    delete(id: number): Observable<any> {
        return this.api.delete(`hirams(${id})`, {});
    }

    changeOwner(id: number, newOwnerId: number): Observable<any> {
        return this.api.post(`hirams(${id})/changeOwner`, { newOwnerId });
    }
    changeTitle(id: number, newTitle: string): Observable<any> {
        return this.api.post(`hirams(${id})/changeTitle`, { newTitle });
    }

    loadChildren(parentHiramId: number): Observable<HiramDetails[]> {
        return this.api.httpGetMulti<HiramDetails>('hirams', { filter: `parentHiramId eq ${parentHiramId}` });
    }

    submit(id: number): Observable<HiramDetails> {
        return this.api.post<HiramDetails>(`hirams(${id})/submit`, {});
    }

    renew(id: number): Observable<HiramDetails> {
        return this.api.post<HiramDetails>(`hirams(${id})/renew`, {});
    }

    withdraw(id: number): Observable<HiramDetails> {
        return this.api.post<HiramDetails>(`hirams(${id})/withdraw`, {});
    }

    retire(id: number): Observable<HiramDetails> {
        return this.api.post<HiramDetails>(`hirams(${id})/retire`, {});
    }

    clone(id: number): Observable<HiramDetails> {
        return this.api.post<HiramDetails>(`hirams(${id})/clone`, {});
    }

    runSubmitValidation(id: number): Observable<SubmitValidationResult> {
        return this.api.single(`hirams(${id})/RunSubmitValidation`, {});
    }

    getApprovalLog(hiramId: number): Observable<ApprovalLog[]> {
        return this.api.httpGetMulti<Approval>(`hirams(${hiramId})/ApprovalLog`);
    }

    getEndorsementPreview(hiramId: number): Observable<EndorsementPreviewItem[]> {
        return this.api.httpGetMulti<EndorsementPreviewItem>(`hirams(${hiramId})/EndorsementPreview`);
    }

    getApprovals(hiramId: number): Observable<Approval[]> {
        return this.api.httpGetMulti<Approval>(`approvals/${hiramId}`);
    }

    addApproval(hiramId: number, approval: Approval): Observable<Approval> {
        return this.api.post<Approval>(`approvals/${hiramId}`, approval);
    }

    replaceApproval(hiramId: number, approval: Approval): Observable<Approval> {
        return this.api.put<Approval>(`approvals/${hiramId}`, approval);
    }

    deleteApproval(approval: Approval): Observable<Approval> {
        return this.api.delete(`approvals/${approval.id}`, {});
    }

    getAssociates(hiramId: number): Observable<HiramAssociate[]> {
        return this.api.httpGetMulti<HiramAssociate>(`hirams(${hiramId})/associates`);
    }

    deleteAssociate(associate: HiramAssociate): Observable<any> {
        return this.api.delete(`associates(${associate.id})`, {});
    }

    score(hiramId: number): Observable<ProcessPhysicalAnalysisResult> {
        return this.api.single<HiramDetails>(`hirams(${hiramId})/ProcessPhysicalAnalysisScore`);
    }

    getMaterialHMISItems(hiramId: number): Observable<HiramMaterialHMISValueItem[]> {
        return this.api.httpGetMulti<HiramAssociate>(`hirams(${hiramId})/materials`);
    }

    toggleMaterialExclusionFlag(hiramId: number, materialId: number): Observable<HiramMaterialHMISValueItem> {
        return this.api
            .post(`hirams(${hiramId})/togglematerialexclusion`, { id: materialId })
            .pipe(tap(() => this.refreshHiramLevel(hiramId)));
    }

    getHazardStatements(hiramId: number): Observable<HazardStatement[]> {
        return this.api.httpGetMulti<HazardStatement>(`hirams(${hiramId})/hazardstatements`);
    }

    getOtherHazards(hiramId: number): Observable<HiramOtherHazard[]> {
        return this.api.httpGetMulti<HiramOtherHazard>(`hirams(${hiramId})/otherhazards`);
    }

    deleteOtherHazard(item: HiramOtherHazard): Observable<any> {
        return this.api.delete<HiramOtherHazard>(`hiramotherhazards(${item.id})`, {});
    }

    postOtherHazard(item: HiramOtherHazard): Observable<HiramOtherHazard> {
        return this.api.post(`hiramotherhazards`, item);
    }

    calculateRiskCategory(hiramId: number, items: SBRAItem[] = null): Observable<number> {
        return this.api.post(`hirams(${hiramId})/riskCategoryCalculator`, items).pipe(map(x => (x as any).value));
    }

    hiramLevel(hiramId: number): Observable<number> {
        return this.hiramLevelDetails(hiramId).pipe(map(data => data.overall));
    }

    hiramLevelDetails(hiramId: number): Observable<HiramRiskLevel> {
        return this.api.single(`hirams(${hiramId})/riskLevel`);
    }

    refreshHiramLevel(hiramId: number) {
        this.hiramLevel(hiramId)
            .pipe(tap(level => console.log('level', level)))
            .subscribe(level => this.hiramLevelChange.emit({ hiramId, level }));
    }

    getSBRAs(hiramId: number): Observable<SBRA[]> {
        return this.api.httpGetMulti<SBRA>(`hirams(${hiramId})/sbras`);
    }

    getSBRATicklers(): Observable<SBRATickler[]> {
        return this.api.httpGetMulti<SBRATickler>('SBRATicklers');
    }

    createSBRA(hiramId: number): Observable<SBRA> {
        return this.api.post<SBRA>(`hirams(${hiramId})/sbras`, {});
    }

    createSBRATickler(data: SBRATickler): Observable<SBRATickler> {
        return this.api.post<SBRATickler>(`SBRATicklers(${data.id})`, data);
    }

    updateSBRATickler(data: SBRATickler): Observable<SBRATickler> {
        return this.api.put<SBRATickler>(`SBRATicklers(${data.id})`, data);
    }

    materialsByOrg(organizationName: string): Observable<MaterialByOrgNameItem[]> {
        const data = { organizationName };
        return this.api.httpGetMulti<any>('materialsByOrg', null, data);
    }

    materialsByLocation(locationName: string): Observable<MaterialByLocationItem[]> {
        const data = { locationName };
        return this.api.httpGetMulti<any>('materialsByLoc', null, data);
    }

    hiramsByLocation(locationName: string): Observable<HiramByLocationItem[]> {
        const data = { locationName };
        return this.api.httpGetMulti<any>('hiramsByLoc', null, data);
    }

    hiramsByMaterial(
        searchTerm: string,
        config: { sortByField: string; sortDirection: string }
    ): Observable<HiramsByChemicalItem[]> {
        return this.api.httpGetMulti<any>(
            'hiramsByMaterial',
            {
                orderBy: `${config.sortByField} ${config.sortDirection}`,
            },
            { searchTerm }
        );
    }

    deleteSBRATickler({ id }): Observable<SBRATickler> {
        return this.api.delete<SBRATickler>(`SBRATicklers(${id})`, {});
    }

    relatedToUser(userId: number): Observable<HiramListItem[]> {
        return this.api.httpGetMulti<SBRA>(`relatedToUser?userId=${userId}`);
    }

    relatedToUserIgnoringSignedApprovals(userId: number): Observable<HiramListItem[]> {
        return this.api.httpGetMulti<HiramListItem>(`relatedToUserIgnoringSignedApprovals?userId=${userId}`);
    }

    replacePerson(toBeReplaced: Person, newPerson: Person, hiramIds: number[]): Observable<any> {
        return this.api.post(`people(${toBeReplaced.id})/Replace`, { newPersonId: newPerson.id, hiramIds });
    }

    updateSBRAs(hiramId: number, item: SBRA): Observable<SBRA> {
        return this.api.put<SBRA>(`hirams(${hiramId})/sbras`, item).pipe(
            tap(() => this.triggerRiskCategoryChange(hiramId)),
            tap(() => this.refreshHiramLevel(hiramId))
        );
    }

    triggerRiskCategoryChange(hiramId: number) {
        this.calculateRiskCategory(hiramId).subscribe(category => this.hiramCategoryChange.emit({ hiramId, category }));
    }

    deleteSBRA(hiramId: number, item: SBRA): Observable<any> {
        return this.api.delete<SBRA>(`sbras(${item.id})`, {}).pipe(tap(() => this.refreshHiramLevel(hiramId)));
    }

    save(item: HiramDetails): Observable<HiramDetails> {
        // tslint:disable-next-line: triple-equals
        if (item.id == -1) {
            return this.api.post<HiramDetails>(`hirams`, item);
        } else {
            return this.api.put<HiramDetails>(`hirams`, item);
        }
    }

    addAssociate(item: HiramAssociate): Observable<HiramAssociate> {
        return this.api.post<HiramAssociate>(`hirams(${item.hiramId})/associates`, item);
    }

    addMaterialHMIS(hiramId: number, item: MaterialHMIS): Observable<HiramMaterialHMISValueItem> {
        return this.api
            .post<HiramMaterialHMISValueItem>(`hirams(${hiramId})/materials`, { hiramId, ...item })
            .pipe(tap(() => this.refreshHiramLevel(hiramId)));
    }

    updateMaterialHMIS(hiramId: number, item: MaterialHMIS): Observable<HiramMaterialHMISValueItem> {
        return this.api
            .put<HiramMaterialHMISValueItem>(`hirams(${hiramId})/materials`, { hiramId, ...item })
            .pipe(tap(() => this.refreshHiramLevel(hiramId)));
    }

    deleteMaterialHMIS(hiramId: number, materialId: number) {
        return this.api.delete(`MaterialHMIS(${materialId})`, {}).pipe(tap(() => this.refreshHiramLevel(hiramId)));
    }

    invalidSBRAItem(item: SBRAItem): boolean {
        return this.oneOfTheItemsAreNA(item) && !this.bothItemsAreNA(item);
    }

    getAttachments(hiramId: number): Observable<HiramAttachment[]> {
        return this.api.httpGetMulti<HiramAttachment>(`hirams(${hiramId})/attachments`);
    }

    addAttachment(attachment: HiramAttachment, file: File): Observable<any> {
        return this.uploadService.post('Attachments', attachment.fileName, file, [
            { name: 'hiramId', value: attachment.hiramId.toString() },
            { name: 'attachmentType', value: 'ChangeWorkItem' },
            { name: 'description', value: attachment.description },
            {
                name: 'otherHazardsCheckList',
                value: attachment.otherHazardsCheckList ? attachment.otherHazardsCheckList.toString() : 'false',
            },
        ]);
    }

    updateAttachment(id: number, fileName: string, file: File): Observable<any> {
        return this.uploadService.post('Attachments', fileName, file, [{ name: 'id', value: id.toString() }]);
    }

    updateAttachmentDescription(id: number, description: string): Observable<HiramAttachment> {
        return this.api.put(`HiramAttachments(${id})`, { id, description });
    }

    deleteAttachment({ id }) {
        return this.api.delete(`HiramAttachments(${id})`, {});
    }

    getContributors(hiramId: number): Observable<HiramContributor[]> {
        return this.api.httpGetMulti(`Contributors/${hiramId}`);
    }

    currentUserIsContributorForHiram(hiramId: number): Observable<boolean> {
        return this.auth.getPersonDataForCurrentUser().pipe(
            switchMap(user => this.api.httpGetMulti(`Contributors/${hiramId}`, { filter: `personId eq ${user.id}` })),
            map(queryResult => queryResult.length > 0),
            tap(result => console.log('current user is contributor for HIRAM', result))
        );
    }

    addContributor(hiramId: number, personId: number): Observable<HiramContributor> {
        return this.api.post(`Contributors/${hiramId}`, { hiramId, personId });
    }

    deleteContributor(id: number): Observable<any> {
        return this.api.delete(`Contributors/${id}`, {});
    }

    private oneOfTheItemsAreNA(item: SBRAItem) {
        return item.riskAssessmentConsequenceLevel === 'N/A' || item.riskAssessmentFrequencyId === 'N/A';
    }

    private bothItemsAreNA(item: SBRAItem) {
        return item.riskAssessmentConsequenceLevel === 'N/A' && item.riskAssessmentFrequencyId === 'N/A';
    }

    getBypassChemicals(hiramId: number): Observable<any> {
        return this.api.single<any>(`hirams(${hiramId})/bypassChemicals`);
    }

    setBypassChemicals(hiramId: number, value: boolean): Observable<any> {
        return this.api.put<any>(`hirams(${hiramId})/updateBypassChemicals`, { Id: hiramId, BypassChemicals: value });
    }
}
