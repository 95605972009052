import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { HiramAttachment } from 'src/app/core/model/hiram-attachment';
import { tap } from 'rxjs/operators';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceOut, bounceIn } from 'ng-animate';
import { HiramDetails } from 'src/app/core/model/hiram-details';

// tslint:disable: no-console
@Component({
    selector: 'hiram-attachments-list',
    templateUrl: './attachments-list.component.html',
    styleUrls: ['./attachments-list.component.scss'],
    animations: [
        trigger('bounce', [
            transition(':enter', useAnimation(bounceIn)),
            transition(':leave', useAnimation(bounceOut)),
        ]),
    ],
})
export class AttachmentsListComponent implements OnInit {
    @Input() hiram: HiramDetails;
    @Input() readonly = false;

    @ViewChild('attachmentsInput', { static: false }) fileInput;
    @ViewChild('inputForm', { static: false }) inputForm;

    loading = false;
    items = [];

    public filesInProgress = [];

    constructor(
        private service: HiramService,
        private toastr: ToastrService,
        private confirmationService: ConfirmationService
    ) {}

    ngOnInit() {
        this.loadList();
    }

    public dropped(files: NgxFileDropEntry[]) {
        console.log('dropped file');

        if (files.length > 1) {
            this.toastr.error('Only one file should be dropped at a time');
            return;
        }

        const droppedFile = files[0];
        if (!droppedFile.fileEntry.isFile) {
            this.toastr.error('Directories cannot be uploaded!');
            return;
        }

        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => this.triggerFileUpload(file));
    }

    filesSelectedFromFileInput(fileInput) {
        const files: FileList = fileInput.files;
        console.log(files);
        for (const key in files) {
            if (files.hasOwnProperty(key)) {
                const file: File = files[key];
                this.triggerFileUpload(file);
            }
        }
        this.inputForm.nativeElement.reset();
    }

    public openFileExplorer() {
        this.fileInput.nativeElement.click();
    }

    private loadList() {
        this.loading = true;
        this.service
            .getAttachments(this.hiram.id)
            .pipe(tap(() => (this.loading = false)))
            .subscribe(data => (this.items = data));
    }

    private triggerFileUpload(file: File) {
        const attachment: HiramAttachment = {
            id: -1,
            hiramId: this.hiram.id,
            fileName: file.name,
            description: '',
        };

        this.items.push(attachment);

        console.time('file upload');
        this.filesInProgress.push(attachment);
        this.service
            .addAttachment(attachment, file)
            .pipe(
                tap(() => this.filesInProgress.splice(this.filesInProgress.indexOf(attachment), 1)),
                tap(() => console.timeEnd('file upload'))
            )
            .subscribe(
                newItem => (attachment.id = newItem.id),
                error => this.errorOnUpload(error, attachment)
            );
    }

    delete(item) {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.filesInProgress.push(item);
                this.service.deleteAttachment(item).subscribe(() => {
                    this.items.splice(this.items.indexOf(item), 1);
                    this.filesInProgress.splice(this.filesInProgress.indexOf(item), 1);
                });
            },
        });
    }

    inProgress(file) {
        return this.filesInProgress.indexOf(file) !== -1;
    }

    errorOnUpload(error, attachment) {
        this.filesInProgress.length = 0;
        this.items.splice(this.items.indexOf(attachment), 1);
        this.toastr.error(error.message);
    }
}
