import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OperationsAndMaintenanceProcedure } from 'src/app/core/model/operations-and-maintenance-procedure';
import { HiramAttachment } from 'src/app/core/model/hiram-attachment';
import { OimsProceduresFlagsService } from 'src/app/core/services/oims-procedures-flags.service';

@Component({
    selector: 'hiram-new-item',
    templateUrl: './new-item.component.html',
    styleUrls: ['./new-item.component.scss'],
})
export class NewItemComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef, private flagsService: OimsProceduresFlagsService) {}

    hiramId: number;
    allAttachments: HiramAttachment[] = [];
    loading = false;
    uploadingFile = false;
    selectedFlag: { id: string; description: string } = null;
    selectedAttachment: HiramAttachment = null;
    flags: { id: string; description: string }[];
    invalidFlag = false;

    @Output() newItemSelected = new EventEmitter<OperationsAndMaintenanceProcedure>();

    ngOnInit() {
        this.flags = [{ id: '', description: '(Select an item)' }, ...this.flagsService.flags];
    }

    procedureFlagChange(event: { target: { value: string } }) {
        this.selectedFlag = this.flags.find(each => each.id === event.target.value);
        this.invalidFlag = false;
    }

    procedureIsSelected(): boolean {
        return this.selectedAttachment !== null;
    }

    fileSelected() {
        this.uploadingFile = true;
    }

    uploadComplete(attachment: HiramAttachment) {
        this.selectedAttachment = attachment;
    }

    cancelFileSelection() {
        this.uploadingFile = false;
    }

    select(attachment: HiramAttachment) {
        this.selectedAttachment = attachment;
    }

    readyToConfirm(): boolean {
        return this.selectedAttachment !== null && this.selectedFlag !== null;
    }

    filteredFiles() {
        return this.allAttachments;
    }

    confirmProcedureSelection() {
        if (this.validate()) {
            this.newItemSelected.emit(this.createOperationsAndMaintenanceProcedure(this.selectedAttachment));
        }
    }

    private validate(): boolean {
        if (this.selectedAttachment === null) {
            return false;
        }

        if (this.selectedFlag === null) {
            this.invalidFlag = true;
            return false;
        }

        return true;
    }

    private createOperationsAndMaintenanceProcedure(attachment: HiramAttachment): OperationsAndMaintenanceProcedure {
        return {
            id: -1,
            attachmentId: attachment.id,
            attachmentFileName: attachment.fileName,
            attachmentDescription: attachment.description,
            sheCritical: this.selectedFlag.id === 'she-critical',
            is61procedure: this.selectedFlag.id === 'is-6.1',
        };
    }
}
