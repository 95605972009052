import { Component, OnInit } from '@angular/core';
import { HiramDetails } from 'src/app/core/model/hiram-details';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MainPageLayoutService } from 'src/app/core/services/main-page-layout/main-page-layout.service';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
    selector: 'hiram-print',
    templateUrl: './print.component.html',
    styleUrls: ['./print.component.scss'],
})
export class PrintComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private service: HiramService,
        private mainPageLayout: MainPageLayoutService,
        private navigation: NavigationService
    ) {}

    hiramId: number;
    details$: Observable<HiramDetails>;
    now = Date.now();

    ngOnInit() {
        this.route.paramMap.subscribe(params => this.handleRouteParamsChange(params));
    }

    close() {
        this.mainPageLayout.changeHeaderVisibility.emit(true);
        this.navigation.gotoHiramDetails(this.hiramId);
    }

    private handleRouteParamsChange(params: ParamMap) {
        // tslint:disable-next-line: radix
        this.hiramId = parseInt(params.get('id'));
        this.details$ = this.service.byId(this.hiramId);
        this.mainPageLayout.changeHeaderVisibility.emit(false);
    }
}
