import { Component, OnInit, EventEmitter } from '@angular/core';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Approval } from 'src/app/core/model/approval';
import { Person } from 'src/app/core/model/person';
import { ApprovalRole } from 'src/app/core/model/approval-role';
import { Endorser } from 'src/app/core/model/endorser';
import { OrganizationGroup } from 'src/app/core/model/organization-group';
import { OrganizationService } from 'src/app/core/services/organization/organization.service';

@Component({
    selector: 'hiram-add-member-modal',
    templateUrl: './add-member-modal.component.html',
    styleUrls: ['./add-member-modal.component.scss'],
})
export class AddMemberModalComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: UntypedFormBuilder,
        private approvalService: ApprovalService,
        private organizationService: OrganizationService
    ) {}

    closing: EventEmitter<Approval> = new EventEmitter();
    detailsForm: UntypedFormGroup;
    role: ApprovalRole;
    organizationGroups: OrganizationGroup[];
    submittingForm = false;
    selectedUserValue: Person;

    ngOnInit() {
        this.detailsForm = this.buildForm();
        this.organizationService.getOrganizationGroups().subscribe(data => {
            this.organizationGroups = data;
            this.detailsForm.controls.organizationGroup.setValue(
                this.organizationGroups.find(group => group.name === 'Clinton')
            );
        });
    }

    get selectedUser(): Person {
        return this.selectedUserValue;
    }

    set selectedUser(value: Person) {
        if (!value) {
            this.selectedUserValue = null;
            this.detailsForm.value.personId = null;
            return;
        }

        this.selectedUserValue = value;
        this.detailsForm.controls.personId.setValue(value.id);
        this.detailsForm.markAsDirty();
    }

    submit() {
        const data: Endorser = {
            id: undefined,
            personId: this.detailsForm.value.personId,
            approvalRoleId: this.role.id,
            organizationGroupId: this.detailsForm.value.organizationGroup.id,
        };

        this.approvalService.addEndorser(data).subscribe((result: Endorser) => {
            this.closing.emit(result);
            this.bsModalRef.hide();
        });
    }

    cancel() {
        this.closing.emit(null);
        this.bsModalRef.hide();
    }

    isSubmitEnabled(): boolean {
        return this.detailsForm.valid && !this.detailsForm.pristine && !this.submittingForm;
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            personId: [null, Validators.required],
            organizationGroup: [null, Validators.required],
        });
    }
}
