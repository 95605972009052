import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'hiram-number-tile',
    templateUrl: './number-tile.component.html',
    styleUrls: ['./number-tile.component.scss'],
})
export class NumberTileComponent implements OnInit {
    @Input() title = '';
    @Input() content = '';
    @Input() redirectTo: any = null;
    @Input() showGrayWhenNull = false;
    // tslint:disable-next-line: max-union-size
    @Input() colorStyle: 'success' | 'dark' | 'secondary' | 'danger' | 'warning' | 'info' | 'light' = 'dark';

    constructor(private router: Router) {}

    ngOnInit() {}

    tileClass() {
        if (this.showGrayWhenNull && (!this.content || this.content === '' || this.content === '0')) {
            return 'secondary';
        } else {
            return this.colorStyle;
        }
    }

    navigate() {
        if (this.redirectTo) {
            this.router.navigate(this.redirectTo);
        }
    }
}
