import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavigationService } from 'src/app/core/services/navigation/navigation.service';

@Component({
    selector: 'hiram-new-hiram-button',
    templateUrl: './new-hiram-button.component.html',
    styleUrls: ['./new-hiram-button.component.scss'],
})
export class NewHiramButtonComponent implements OnInit {
    constructor(private navigationService: NavigationService) {}

    @Output() click = new EventEmitter();

    ngOnInit() {}

    navigate() {
        this.navigationService.gotoNewHiram();
    }
}
