import { Injectable } from '@angular/core';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { DiscussionItem } from '../../model/discussion-item';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DiscussionService {
    constructor(private hiramApi: HiramAPIService) {}

    all(hiramId: number): Observable<DiscussionItem[]> {
        return this.hiramApi.httpGetMulti<DiscussionItem>(`DiscussionItems/${hiramId}`);
    }

    insert(item: DiscussionItem): Observable<DiscussionItem> {
        console.log('item', item);
        return this.hiramApi.post<DiscussionItem>('DiscussionItems', item);
    }
}
