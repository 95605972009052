import { Component, OnInit, Input, NgZone, EventEmitter, Output } from '@angular/core';
import { OperationsAndMaintenanceProcedure } from 'src/app/core/model/operations-and-maintenance-procedure';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceOut, bounceIn } from 'ng-animate';
import { UploadService } from 'src/app/core/services/upload/upload.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NewItemComponent } from '../new-item/new-item.component';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramAttachment } from 'src/app/core/model/hiram-attachment';
import { StandardDocument } from 'src/app/core/model/standard-document';
import { StandardDocumentsService } from 'src/app/core/services/standard-documents/standard-documents.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'hiram-procedures-list',
    templateUrl: './procedures-list.component.html',
    styleUrls: ['./procedures-list.component.scss'],
    animations: [
        trigger('bounce', [
            transition(':enter', useAnimation(bounceIn)),
            transition(':leave', useAnimation(bounceOut)),
        ]),
    ],
})
export class ProceduresListComponent implements OnInit {
    @Input() hiramId: number;
    @Input() readonly = false;
    @Input() items: OperationsAndMaintenanceProcedure[] = [];
    @Output() listChange = new EventEmitter();

    loading = false;
    modalRef: BsModalRef;
    allAttachments: HiramAttachment[];
    standardDocuments: StandardDocument[];

    constructor(
        private confirmationService: ConfirmationService,
        private modalService: BsModalService,
        private zone: NgZone,
        private hiramService: HiramService,
        private standardDocumentService: StandardDocumentsService,
        private uploadService: UploadService,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        this.hiramService.getAttachments(this.hiramId).subscribe(data => (this.allAttachments = data));
        this.standardDocumentService.all().subscribe(data => (this.standardDocuments = data));
    }

    addProcedure() {
        const initialState = {
            hiramId: this.hiramId,
            allAttachments: this.allAttachments,
        };
        this.modalRef = this.modalService.show(NewItemComponent, {
            initialState,
            backdrop: 'static',
            class: 'modal-lg',
        });

        this.modalRef.content.newItemSelected.subscribe(data => this.onDocumentSelected(data));
    }

    private onDocumentSelected(item: OperationsAndMaintenanceProcedure) {
        this.zone.run(() => {
            this.items.push(item);

            this.listChange.emit();

            if (this.modalRef) {
                this.modalRef.hide();
            }
        });
    }

    delete(item: OperationsAndMaintenanceProcedure) {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.items.splice(this.items.indexOf(item), 1);
            },
        });
    }

    downloadProcedureTemplate() {
        this.downloadStandardDocument('OIMS 6.1 Procedure Template');
    }

    showTips() {
        this.downloadStandardDocument('OIMS 6.1 Procedure Tips');
    }

    private downloadStandardDocument(name: string) {
        const template = this.standardDocuments.find(each => each.description === name);

        this.uploadService
            .downloadStandardDocument(template.id, template.fileName)
            .subscribe(_ => this.toastr.success('Download completed!'));
    }
}
