import packageInfo from '../../package.json';
import config from '../config.json';

const endpoints = {
    [config.baseApiUrl]: config.clientId,
    'https://graph.microsoft.com': 'https://graph.microsoft.com',
};

export const environment = {
    production: false,
    baseUrl: config.baseApiUrl,
    authUrl: config.authApiUrl,
    homeUrl: config.homeUrl,
    baseDomain: config.baseDomain,
    version: packageInfo.version,
    APIUrl: `${config.baseApiUrl}/api`,
    midasApiUrl: config.midasApiUrl,
    appInsightsInstrumentationKey: config.appInsightsInstrumentationKey,
    adalConfig: {
        tenant: config.tenantId,
        clientId: config.clientId,
        redirectUri: config.redirectUri,
        authority: config.authority,
        endpoints: endpoints,
    },
};