import { Component, OnInit, Input } from '@angular/core';
import { DiscussionService } from 'src/app/core/services/discussion/discussion.service';
import { DiscussionItem } from 'src/app/core/model/discussion-item';
import { tap } from 'rxjs/operators';
import { trigger, style, transition, stagger, animate, query } from '@angular/animations';

@Component({
    selector: 'hiram-discussion-list',
    templateUrl: './discussion-list.component.html',
    styleUrls: ['./discussion-list.component.scss'],
    animations: [
        trigger('listAnimation', [
            transition(':enter', [
                query(
                    '.discussionItem',
                    [
                        style({ opacity: 0, transform: 'translateX(-100px)' }),
                        stagger(-100, [
                            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' })),
                        ]),
                    ],
                    { optional: true }
                ),
            ]),
        ]),
    ],
})
export class DiscussionListComponent implements OnInit {
    constructor(private service: DiscussionService) {}

    @Input() hiramId: number;

    items: DiscussionItem[];
    loading = false;
    adding = false;
    itemsInProgress = [];
    textForNewItem = '';

    ngOnInit() {
        this.loadItems();
    }

    insert() {
        this.adding = true;
        this.service
            .insert({ text: this.textForNewItem.trim(), hiramId: this.hiramId })
            .pipe(tap(() => (this.adding = false)))
            .subscribe(newItem => {
                this.textForNewItem = '';
                this.items.push(newItem);
            });
    }

    isItemInProgress(item: DiscussionItem) {
        return this.itemsInProgress.indexOf(item) !== -1;
    }

    readyToSend() {
        return !this.adding && this.textForNewItem !== null && this.textForNewItem.trim().length > 0;
    }

    private loadItems() {
        this.loading = true;
        this.service
            .all(this.hiramId)
            .pipe(tap(() => (this.loading = false)))
            .subscribe(result => (this.items = result));
    }
}
