import { Component, OnInit } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { SBRATickler } from 'src/app/core/model/sbra-tickler';
import { tap } from 'rxjs/operators';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceInRight, bounceOutRight } from 'ng-animate';

@Component({
    selector: 'hiram-sbra-tickler-list',
    templateUrl: './sbra-tickler-list.component.html',
    styleUrls: ['./sbra-tickler-list.component.scss'],
    animations: [
        trigger('bounce', [
            transition(':enter', useAnimation(bounceInRight)),
            transition(':leave', useAnimation(bounceOutRight)),
        ]),
    ],
})
export class SbraTicklerListComponent implements OnInit {
    constructor(private service: HiramService) {}

    items: SBRATickler[];
    loading = false;
    itemBeingEditted: SBRATickler;
    itemInProgress: SBRATickler;
    backup: SBRATickler;

    ngOnInit() {
        const allTicklers$ = this.service.getSBRATicklers();
        if (allTicklers$) {
            this.loading = true;
            allTicklers$.pipe(tap(() => (this.loading = false))).subscribe(data => (this.items = data));
        }
    }

    create() {
        const newItem = { id: -1, hazard: '', potentialRiskScenario: '', notes: '' };
        this.items.unshift(newItem);
        this.itemBeingEditted = newItem;
    }

    itemDelete(item: SBRATickler) {
        this.items.splice(this.items.indexOf(item), 1);
    }
}
