import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';

@Component({
    selector: 'hiram-change-title',
    templateUrl: './change-title.component.html',
    styleUrls: ['./change-title.component.scss'],
})
export class ChangeTitleComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: UntypedFormBuilder,
        private service: HiramService,
        private toastr: ToastrService
    ) {
        this.detailsForm = this.buildForm();
    }
    title: string;
    hiramId: number;
    closing: EventEmitter<string> = new EventEmitter();
    newTitle: string;
    detailsForm: UntypedFormGroup;
    submittingForm = false;

    ngOnInit() {
        this.detailsForm.controls.title.setValue(this.title);
    }

    submit() {
        let value = this.detailsForm.controls.title.value;
        this.service
            .changeTitle(this.hiramId, this.detailsForm.controls.title.value)
            .pipe(tap(this.showSuccessfulReassignmentMessage()))
            .subscribe(() => {
                this.closing.emit(value);
                this.bsModalRef.hide();
            });
    }

    private showSuccessfulReassignmentMessage(): (x: any) => void {
        return () => this.toastr.success(`The title has been changed to ${this.detailsForm.controls.title.value}`);
    }

    isSubmitEnabled(): boolean {
        return this.detailsForm.valid;
    }

    cancel() {
        this.closing.emit(null);
        this.bsModalRef.hide();
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            title: [null, Validators.required],
        });
    }
}
