import { Component, OnInit, Input } from '@angular/core';
import { Approval } from 'src/app/core/model/approval';

@Component({
    selector: 'hiram-approval-statement',
    templateUrl: './approval-statement.component.html',
    styleUrls: ['./approval-statement.component.scss'],
})
export class ApprovalStatementComponent implements OnInit {
    constructor() {}

    @Input() item: Approval;

    ngOnInit() {}
}
