import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Person } from 'src/app/core/model/person';

@Component({
    selector: 'hiram-researcher-card',
    templateUrl: './researcher-card.component.html',
    styleUrls: ['./researcher-card.component.scss'],
})
export class ResearcherCardComponent implements OnInit {
    constructor() {}

    @Input() person: Person;
    @Input() title: string;
    @Output() deleteClick = new EventEmitter();
    @Input() operationInProgress = false;

    ngOnInit() {}
}
