import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UnsupportedBrowserCheckerService } from '../unsupported-browser-checker/unsupported-browser-checker.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UnsupportedBrowserGuard {
    constructor(private router: Router, private checker: UnsupportedBrowserCheckerService) {}

    canActivate() {
        if (this.checker.usingCompatibleBrowser()) {
            return true;
        } else {
            this.router.navigate(['/unsupported-browser']);
            return false;
        }
    }
}

export const UnsupportedBrowser: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean => {
    return inject(UnsupportedBrowserGuard).canActivate();
}