import { Component, OnInit, Input, Pipe, PipeTransform } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramMaterialHMISValueItem } from 'src/app/core/model/hiram-materialHMIS-value-item';
import { MaterialHmisService } from 'src/app/core/services/material-hmis/material-hmis.service';
import { MaterialSummary } from 'src/app/core/model/material-summary';
import { HfrCutPoints } from 'src/app/core/model/hfr-cut-points';
import { HfrCutPointsService } from 'src/app/core/services/hfr-cut-points.service';
import { formatNumber } from '@angular/common';

@Pipe({
    name: 'formattedNumber',
})
export class ToNumberPipe implements PipeTransform {
    transform(value: string): any {
        const numberValue = Number(value);
        const absNumber = Math.abs(numberValue);

        if (absNumber > 0 && (absNumber > 1e7 || absNumber < 1e-3)) {
            return numberValue.toPrecision(2);
        }

        return formatNumber(numberValue, 'en-US', '1.0-0');
    }
}

@Component({
    selector: 'hiram-chemicals',
    templateUrl: './chemicals.component.html',
    styleUrls: ['../print.component.scss'],
})
export class ChemicalsComponent implements OnInit {
    items: HiramMaterialHMISValueItem[];
    summary: MaterialSummary;
    hfrCutPoints: HfrCutPoints;

    constructor(
        private service: HiramService,
        private materialService: MaterialHmisService,
        private hfrCutPointsService: HfrCutPointsService
    ) {}

    ngOnInit() {}

    @Input() set hiramId(value: number) {
        if (value) {
            this.service.getMaterialHMISItems(value).subscribe(data => (this.items = data));

            this.service.materialSummary(value).subscribe(data => (this.summary = data));

            this.hfrCutPointsService.get().subscribe(data => (this.hfrCutPoints = data));
        }
    }

    showSOCBadge(value: number, type: 'health' | 'flammability' | 'reactivity') {
        return this.hfrCutPoints && value >= this.hfrCutPoints[`${type}CutPoint2to3`];
    }

    showPeerReviewerBadge(value: number, type: 'health' | 'flammability' | 'reactivity') {
        return (
            !this.showSOCBadge(value, type) && this.hfrCutPoints && value >= this.hfrCutPoints[`${type}CutPoint3to4`]
        );
    }

    RCalcTotal() {
        if (!this.items) {
            return null;
        }

        return this.items.reduce((sum: number, item: HiramMaterialHMISValueItem) => sum + item.rCalc, 0);
    }

    HCalcTotal() {
        if (!this.items) {
            return null;
        }

        return this.items.reduce((sum: number, item: HiramMaterialHMISValueItem) => sum + item.hCalc, 0);
    }

    FCalcTotal() {
        if (!this.items) {
            return null;
        }

        return this.items.reduce((sum: number, item: HiramMaterialHMISValueItem) => sum + item.fCalc, 0);
    }

    phsShortName(phsValue: number) {
        return this.materialService.phsShortName(phsValue);
    }
}
