import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ProcessPhysicalAnalysisResult } from 'src/app/core/model/process-physical-analysis-result';
import { trigger, style, transition, useAnimation } from '@angular/animations';
import { rubberBand, fadeIn } from 'ng-animate';
import { min } from '../../../../core/constants/min.constant';
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'risk-summary',
    templateUrl: './process-physical-hazard-summary.component.html',
    styleUrls: ['./process-physical-hazard-summary.component.scss'],
    animations: [
        trigger('valueAnimation', [
            transition(':increment', [
                style({ backgroundColor: 'yellow', transform: 'scale(2)' }),
                useAnimation(rubberBand),
            ]),
            transition(':decrement', [
                style({ backgroundColor: 'yellow', transform: 'scale(2)' }),
                useAnimation(rubberBand),
            ]),
        ]),
        trigger('expand', [transition(':enter', [useAnimation(fadeIn)])]),
    ],
})
export class ProcessPhysicalHazardSummaryComponent implements OnInit {
    constructor(private service: HiramService) {}

    @Input() public hiramId: number;

    showDetails = false;

    public riskAnalysisResult: ProcessPhysicalAnalysisResult;

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.service.score(this.hiramId).subscribe(results => {
            this.riskAnalysisResult = results;
            this.service.hiramLevelDetails(this.hiramId).subscribe(data => {
                const hazardsLevel = min(data.processHazard, data.chemicalHazard);
                if (hazardsLevel <= 1) {
                    this.service.updateRequiredSOCFlag(this.hiramId, true).subscribe(_ => {});
                }
            });
        });
    }
}
