import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendingReponseComponent } from './pending-reponse/pending-reponse.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
    declarations: [PendingReponseComponent],
    imports: [CommonModule, FontAwesomeModule, HiramCommonsModule, TooltipModule.forRoot(), RouterModule],
    exports: [PendingReponseComponent],
})
export class PendingResponseModule {}
