import { Component, OnInit, Input } from '@angular/core';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';

@Component({
    selector: 'hiram-status-badge',
    templateUrl: './status-badge.component.html',
    styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent implements OnInit {
    @Input() item: HiramListItem;

    constructor() {}

    ngOnInit() {}
}
