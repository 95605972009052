import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbraDashboardComponent } from './sbra-dashboard/sbra-dashboard.component';
import { MaterialHmisModule } from '../material-hmis/material-hmis.module';
import { HazardStatementsListComponent } from './hazard-statements-list/hazard-statements-list.component';
import { IconModule } from 'src/app/icon.module';
import { RiskMatrixComponent } from './risk-matrix/risk-matrix.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SbraFormComponent } from './sbra-form/sbra-form.component';
import { SbraFormControlDetailsComponent } from './sbra-form/sbra-form-control-details/sbra-form-control-details.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SbraListComponent } from './sbra-list/sbra-list.component';
import { RiskAssessmentItemsComponent } from './sbra-form/risk-assessment-items/risk-assessment-items.component';
import { ControlsComponent } from './sbra-form/controls/controls.component';
import { RiskCategoryDetailsComponent } from './risk-category-details/risk-category-details.component';
import { ScenarioSummaryModule } from './scenario-summary/scenario-summary.module';
import { RiskCategoryTileModule } from './risk-category-tile/risk-category-tile.module';
import { ControlListComponent } from './sbra-form/controls/control-list/control-list.component';
import { ControlListItemComponent } from './sbra-form/controls/control-list/control-list-item/control-list-item.component';
import { HiramOtherHazardsModule } from '../hiram-other-hazards/hiram-other-hazards.module';
import { SbraDashboardHazardsListComponent } from './sbra-dashboard-hazards-list/sbra-dashboard-hazards-list.component';
import { SbraChemicalHazardProfileComponent } from './sbra-chemical-hazard-profile/sbra-chemical-hazard-profile.component';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { ProceduresModule } from './sbra-form/procedures/procedures.module';
import { QuintileDefTableComponent } from './sbra-chemical-hazard-profile/quintile-def-table/quintile-def-table.component';
import { SbraTicklersComponent } from './sbra-ticklers/sbra-ticklers.component';
import { SbraHelpComponent } from './sbra-help/sbra-help.component';
import { LevelCardComponent } from './sbra-dashboard/level-card/level-card.component';

@NgModule({
    declarations: [
        SbraDashboardComponent,
        HazardStatementsListComponent,
        RiskMatrixComponent,
        SbraFormComponent,
        SbraFormControlDetailsComponent,
        SbraListComponent,
        RiskAssessmentItemsComponent,
        ControlsComponent,
        RiskCategoryDetailsComponent,
        ControlListComponent,
        ControlListItemComponent,
        SbraDashboardHazardsListComponent,
        SbraChemicalHazardProfileComponent,
        QuintileDefTableComponent,
        SbraTicklersComponent,
        SbraHelpComponent,
        LevelCardComponent,
    ],
    imports: [
        CommonModule,
        MaterialHmisModule,
        FormsModule,
        ReactiveFormsModule,
        IconModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        AlertModule.forRoot(),
        HiramOtherHazardsModule,
        RiskCategoryTileModule,
        ScenarioSummaryModule,
        HiramCommonsModule,
        ProceduresModule,
    ],
    exports: [SbraDashboardComponent]
})
export class SbraModule {}
