import { Component, OnInit, Input } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { ProcessPhysicalHazardsService } from 'src/app/core/services/process-physical-hazards-service/process-physical-hazards-service.service';
import { ProcessPhysicalHazardItem } from 'src/app/core/model/process-physical-hazard-item';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'hiram-process',
    templateUrl: './process.component.html',
    styleUrls: ['../print.component.scss'],
})
export class ProcessComponent implements OnInit {
    constructor(private service: ProcessPhysicalHazardsService) {}

    selection$: Observable<{ category: string; riskFactor?: number; description: string }[]>;

    public categoryMapping = {
        'Metal (SS) - Temperature': 'Metal - Temperature',
        'Metal (SS) - Pressure': 'Metal - Pressure',
    };

    ngOnInit() {}

    @Input() set hiramId(value: number) {
        if (value) {
            this.selection$ = this.service.items().pipe(
                switchMap(items => {
                    return this.service.loadSelection(value).pipe(map(addDescriptionToSelection(items)));
                })
            );
        }
    }

    categoryRiskClass(item) {
        switch (item.riskFactor) {
            case 1:
                return 'text-danger';
            case 2:
                return 'text-warning';
            case 3:
            case 4:
                return 'text-success';
            default:
                return 'text-secondary';
        }
    }

    categoryDescription(category: string): string {
        if (this.categoryMapping[category]) {
            return this.categoryMapping[category];
        }
        return category;
    }
}

function addDescriptionToSelection(items: ProcessPhysicalHazardItem[]) {
    return selection => selection.map(each => selectionWithDescription(items, each));
}

function selectionWithDescription(
    items: ProcessPhysicalHazardItem[],
    selection: { itemCategoryName: string; itemRiskFactor: number }
): { category: string; description: string; riskFactor: number } {
    const itemSelection = items.find(
        x => x.categoryName === selection.itemCategoryName && x.riskFactor === selection.itemRiskFactor
    );
    return {
        category: selection.itemCategoryName,
        description: itemSelection.description,
        riskFactor: itemSelection.riskFactor,
    };
}
