// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-text-box {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: transparent;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  border-right: 0;
  width: 20rem;
}

.search-text-box::placeholder {
  color: lightblue;
}

.search-text-box-icon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: transparent;
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  border-left: 0;
  width: auto;
}

.version-label {
  font-size: 0.7rem;
  color: gray !important;
}

.help-link {
  text-shadow: 1px 1px 1px whitesmoke;
}

.version-label:link {
  color: white;
}

.version-label:visited {
  color: white;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
