import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    constructor(private router: Router) {}

    gotoHome() {
        this.router.navigateByUrl('/');
    }

    gotoHiramList() {
        this.router.navigateByUrl('/hirams/all');
    }

    gotoNewHiram() {
        return this.gotoHiramDetails(-1);
    }

    gotoHiramDetails(id: number) {
        this.router.navigateByUrl(`/hiramdetails/${id}`);
    }

    gotoHiramPrint(id: number) {
        this.router.navigateByUrl(`/print-version/${id}`);
    }
}
