export class OtherHazard {
    id: number;
    description: string;
    riskFactor: number;
    tooltip?: string;
    otherHazardTypeId?: number;
    otherHazardTypeName?: string;
    approvalRoleId?: number;
    approvalRoleName?: string;
}
