import { Component, OnInit, Input } from '@angular/core';
import { SBRA } from 'src/app/core/model/sbra';

@Component({
    selector: 'hiram-scenario-details',
    templateUrl: './scenario-details.component.html',
    styleUrls: ['./scenario-details.component.scss'],
})
export class ScenarioDetailsComponent implements OnInit {
    constructor() {}

    @Input() scenario: SBRA;

    ngOnInit() {}

    shortText(text: string) {
        const maxLength = 100;
        if (text && text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        } else {
            return text;
        }
    }
}
