// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-cell {
  color: white;
  text-shadow: 1px 1px 2px black;
  width: 50px;
  height: 50px;
}

.risk-cell-sm {
  color: white;
  text-shadow: 1px 1px 2px black;
  width: 30px;
  height: 30px;
}

.risk-cell-1 {
  background-color: #c60d57;
}

.risk-cell-2 {
  background-color: #ffe500;
}

.risk-cell-3 {
  background-color: #286cff;
}

.risk-cell-4 {
  background-color: #069b17;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
