import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MainPageLayoutService {
    constructor() {}

    public changeHeaderVisibility = new EventEmitter<boolean>();
}
