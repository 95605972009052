import { Component, OnInit, Input } from '@angular/core';
import { HiramOtherInfoQuestionResponse } from 'src/app/core/model/hiram-other-info-question-response';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';

@Component({
    selector: 'hiram-question-item',
    templateUrl: './question-item.component.html',
    styleUrls: ['./question-item.component.scss'],
})
export class QuestionItemComponent implements OnInit {
    constructor(private service: HiramService, private formBuilder: UntypedFormBuilder) {}

    @Input() hiramId: number;
    @Input() item: HiramOtherInfoQuestionResponse;

    formGroup: UntypedFormGroup;

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            comments: [this.item.comments],
            response: [this.item.response === null ? null : this.item.response ? 'Yes' : 'No'],
        });
    }

    save() {
        this.service
            .updateOtherInfoQuestionResponse(
                this.item.id,
                this.formGroup.value.response === null ? null : this.formGroup.value.response === 'Yes',
                this.formGroup.value.comments
            )
            .subscribe(() => {
                this.item.response =
                    this.formGroup.value.response === null ? null : this.formGroup.value.response === 'Yes';
                this.item.comments = this.formGroup.value.comments;
            });
    }
}
