import { EventEmitter } from '@angular/core';

export class SortController {
    private fieldValue: string;
    private directionValue: 'asc' | 'desc';

    public SortParametersChange = new EventEmitter();

    get field(): string {
        return this.fieldValue;
    }

    set field(value: string) {
        const oldValue = this.fieldValue;
        this.fieldValue = value;

        if (oldValue !== value) {
            this.SortParametersChange.emit();
        }
    }

    get direction(): 'asc' | 'desc' {
        return this.directionValue;
    }

    set direction(value: 'asc' | 'desc') {
        const oldValue = this.directionValue;
        this.directionValue = value;

        if (oldValue !== value) {
            this.SortParametersChange.emit();
        }
    }
}
