import { Component, OnInit, Input } from '@angular/core';
import { ApprovalLog } from 'src/app/core/model/approval';
import { Person } from 'src/app/core/model/person';

@Component({
    selector: 'hiram-approver-picture',
    templateUrl: './approver-picture.component.html',
    styleUrls: ['./approver-picture.component.scss'],
})
export class ApproverPictureComponent implements OnInit {
    constructor() {}

    @Input() item: ApprovalLog;

    ngOnInit() {}

    getPerson(item: ApprovalLog): Person {
        return {
            displayName: item.personDisplayName,
            domainName: item.personDomainName,
            domainUserName: item.personDomainUserName,
        };
    }

    accepted() {
        return this.item.description === 'Approved';
    }

    tooltipText() {
        if (this.item.approvalRole === 'Researcher') {
            return 'Ackowledged';
        }
        return this.item.description;
    }
}
