import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { HiramAssociate } from 'src/app/core/model/hiram-associate';
import { Person } from 'src/app/core/model/person';

@Component({
    selector: 'hiram-researchers-add',
    templateUrl: './hiram-researchers-add.component.html',
    styleUrls: ['./hiram-researchers-add.component.scss'],
})
export class HiramResearchersAddComponent implements OnInit {
    @Input() hiramId: number;
    @Output() OnClosing = new EventEmitter<HiramAssociate>();

    public detailsForm: UntypedFormGroup;
    public submittingForm = false;
    public selectedUser: Person;

    get signer(): Person {
        return this.selectedUser;
    }

    set signer(value: Person) {
        this.selectedUser = value;
        this.detailsForm.markAsDirty();
    }

    constructor(private service: HiramService, private formBuilder: UntypedFormBuilder) {
        this.detailsForm = this.buildForm();
    }

    ngOnInit() {}

    submit() {
        const associateTypeIdForReasearcher = 1;

        this.service
            .addAssociate({
                id: -1,
                hiramId: this.hiramId,
                personId: this.selectedUser.id,
                hiramAssociateTypeId: associateTypeIdForReasearcher,
            })
            .subscribe(result => {
                this.OnClosing.emit(result);
            });
    }

    cancel() {
        this.OnClosing.emit(null);
    }

    isSubmitEnabled(): boolean {
        return this.detailsForm.valid && !this.detailsForm.pristine && !this.submittingForm;
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            personId: [null, Validators.required],
        });
    }
}
