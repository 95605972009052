import { Component, OnInit } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'hiram-xls-report',
    templateUrl: './xls-report.component.html',
    styleUrls: ['./xls-report.component.sass'],
})
export class XlsReportComponent implements OnInit {
    constructor(private service: HiramService, private toastr: ToastrService) {}

    inProgress = false;
    errorMessage: string = null;

    ngOnInit() {}

    createReport() {
        this.errorMessage = null;
        this.inProgress = true;
        this.toastr.info('The report is being generated and will be automatically downloaded when completed');
        this.service.excelReport({
            thenRunWhenCompleted: () => {
                this.toastr.info('Report generation complete! Download will be started now.');
                this.inProgress = false;
            },
            onError: err => {
                this.inProgress = false;
                this.errorMessage = err.message;
                console.error('error requesting excel report:', err);
            },
        });
    }
}
