import { Component, OnInit, Input } from '@angular/core';
import { Approval } from 'src/app/core/model/approval';

@Component({
    selector: 'hiram-history-timeline',
    templateUrl: './history-timeline.component.html',
    styleUrls: ['./history-timeline.component.scss'],
})
export class HistoryTimelineComponent implements OnInit {
    @Input() item: Approval;

    constructor() {}

    ngOnInit() {}

    logsToDisplay() {
        if (!this.item.approvalLogs) {
            return [];
        }

        return this.item.approvalLogs.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
    }
}
