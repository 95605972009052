import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpirationOutlookComponent } from './expiration-outlook/expiration-outlook.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
    declarations: [ExpirationOutlookComponent],
    imports: [
        CommonModule,
        FormsModule,
        HiramCommonsModule,
        RouterModule,
        BsDatepickerModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
    ],
    exports: [ExpirationOutlookComponent],
})
export class ExpirationOutlookModule {}
