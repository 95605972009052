// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.risk-assessment-table-container {
  border-style: solid;
  border-radius: 10px;
  border-color: gray;
  border-width: 0.5px;
  padding: 10px;
}

.risk-assessment-table th {
  margin: 0px;
  border: 0px;
}

button:disabled.active {
  background-color: #6c757d;
  color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
