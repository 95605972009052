import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'hiram-get-help-button',
    templateUrl: './get-help-button.component.html',
    styleUrls: ['./get-help-button.component.scss'],
})
export class GetHelpButtonComponent implements OnInit {
    constructor(private router: Router) {}

    ngOnInit() {}

    navigate() {
        this.router.navigate(['help']);
    }
}
