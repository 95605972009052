// tslint:disable: max-line-length

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HiramListComponent } from './components/hiram-list/hiram-list.component';
import { HiramDetailsComponent } from './components/hiram-details/hiram-details.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ChemicalHazardOverviewComponent } from './components/reports/chemical-hazard-overview/chemical-hazard-overview.component';
import { ProcessPhysicalHazardItemsListComponent } from './components/admin/process-physical-hazard-items/process-physical-hazard-items-list/process-physical-hazard-items-list.component';
import { MyApprovalsComponent } from './components/approvals/my-approvals/my-approvals.component';
import { OtherHazardsListComponent } from './components/admin/other-hazards/other-hazards-list/other-hazards-list.component';
import { ApprovalRoleMembersListComponent } from './components/admin/approval-roles-members/approval-role-members-list/approval-role-members-list.component';
import { ChangelogComponent } from './components/changelog/changelog.component';
import { ReplacePersonComponent } from './components/admin/replace-person/replace-person/replace-person.component';
import { DocumentListComponent } from './components/documents/document-list/document-list.component';
import { SbraTicklerListComponent } from './components/admin/sbra-tickler/sbra-tickler-list/sbra-tickler-list.component';
import { UnsupportedBrowserPageComponent } from './components/unsupported-browser-page/unsupported-browser-page.component';
import { UnsupportedBrowser } from './core/services/unsupported-browser-guard/unsupported-browser-guard.service';
import { PrintComponent } from './components/hiram-details/print/print.component';
import { ExpirationOutlookComponent } from './components/reports/expiration-outlook/expiration-outlook/expiration-outlook.component';
import { OimsProcedureReportComponent } from './components/reports/oims-procedure-report/oims-procedure-report/oims-procedure-report.component';
import { StandardDocumentsListComponent } from './components/admin/standard-documents/standard-documents-list/standard-documents-list.component';
import { ErrorComponent } from './components/error/error.component';
import { AdminListComponent } from './components/admin/administrators-module/admin-list/admin-list.component';
import { ChemicalsByOrganizationComponent } from './components/reports/chemicals-by-organization/chemicals-by-organization.component';
import { HiramsByChemicalComponent } from './components/reports/hirams-by-chemical/hirams-by-chemical.component';
import { UrlListComponent } from './components/admin/urls/url-list/url-list.component';
import { HelpComponent } from './components/help/help.component';
import { SplashScreenComponent } from './components/admin/splash-screen/splash-screen.component';
import { EnablePersonComponent } from './components/admin/enable-person/enable-person.component';
import { HfrCutPointsAdminComponent } from './components/admin/hfr-cut-points/hfr-cut-points-admin/hfr-cut-points-admin.component';
import { ChemicalsByLocationComponent } from './components/reports/chemicals-by-location/chemicals-by-location/chemicals-by-location.component';
import { PendingReponseComponent } from './components/reports/pending-response/pending-reponse/pending-reponse.component';
import { XlsReportComponent } from './components/admin/xls-report/xls-report.component';
import { OtherInfoQuestionsComponent } from './components/admin/other-info-questions/other-info-questions.component';
import { HiramsByLocationComponent } from './components/reports/hirams-by-location/hirams-by-location/hirams-by-location.component';
import { MsalGuard } from '@azure/msal-angular';

const routesWithHeaderAndFooter: Routes = [
    {
        path: 'hirams/:mode',
        component: HiramListComponent,
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'app-down-error',
        component: ErrorComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser],
    },
    {
        path: 'search/:term',
        component: HiramListComponent,
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'hiramdetails/:id',
        component: HiramDetailsComponent,
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/replace-person',
        component: ReplacePersonComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'changelog',
        component: ChangelogComponent,
        pathMatch: 'full',
    },
    {
        path: 'documents',
        component: DocumentListComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/hfr-cut-points',
        component: HfrCutPointsAdminComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'help',
        component: HelpComponent,
        pathMatch: 'full',
    },
    {
        path: 'myapprovals',
        component: MyApprovalsComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/other-hazards-list',
        component: OtherHazardsListComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/approvals-role-members',
        component: ApprovalRoleMembersListComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/administrators',
        component: AdminListComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/splashscreen',
        component: SplashScreenComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/enable-person',
        component: EnablePersonComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/standard-documents',
        component: StandardDocumentsListComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/process-physical-hz-items',
        component: ProcessPhysicalHazardItemsListComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/links',
        component: UrlListComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/sbra-ticklers',
        component: SbraTicklerListComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/other-info-questions',
        component: OtherInfoQuestionsComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'admin/xls-report',
        component: XlsReportComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'reports/chemicalHzOverview',
        component: ChemicalHazardOverviewComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'reports/expirationOutlook',
        component: ExpirationOutlookComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'reports/oimsProceduresReport',
        component: OimsProcedureReportComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'reports/chemicalsByOrg',
        component: ChemicalsByOrganizationComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'reports/hiramsByChemical',
        component: HiramsByChemicalComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'reports/chemicalsByLocation',
        component: ChemicalsByLocationComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'reports/pendingResponses',
        component: PendingReponseComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'unsupported-browser',
        pathMatch: 'full',
        component: UnsupportedBrowserPageComponent,
    },
    {
        path: 'print-version/:id',
        component: PrintComponent,
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
    {
        path: 'reports/hiramsByLocation',
        component: HiramsByLocationComponent,
        pathMatch: 'full',
        canActivate: [UnsupportedBrowser, MsalGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routesWithHeaderAndFooter, { enableTracing: false })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
