import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Approval } from 'src/app/core/model/approval';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flipOutY, bounce, slideInRight } from 'ng-animate';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';

@Component({
    selector: 'hiram-report-item',
    templateUrl: './report-item.component.html',
    styleUrls: ['./report-item.component.scss'],
    animations: [
        trigger('approvalEnter', [transition(':enter', useAnimation(slideInRight, { params: { timing: 0.5 } }))]),
        trigger('flipOut', [transition(':leave', useAnimation(flipOutY))]),
        trigger('bounce', [transition(':enter', useAnimation(bounce)), transition(':leave', useAnimation(bounce))]),
    ],
})
export class ReportItemComponent implements OnInit {
    constructor(private modalService: BsModalService, private service: ApprovalService) {}

    @Input() approval: Approval;
    @Input() pendingApprovalForItem: Approval;
    @Input() readonly: boolean;
    @Input() isAdmin: boolean;
    @Input() readyToApprove: boolean;
    @Output() itemChange = new EventEmitter();
    @Output() delete = new EventEmitter();

    showApprovalControl = false;
    comments: string;

    ngOnInit() {}

    showDeleteButton(): boolean {
        return !this.readonly && this.approval.approvalRole.name === 'Researcher' && this.approval.accepted === null;
    }

    textForAccepted() {
        return this.approval.approvalRole.ackOnly ? 'Acknowledged' : 'Accepted';
    }

    lastEvent() {
        if (!this.approval.approvalLogs || this.approval.approvalLogs.length === 0) {
            return null;
        }

        return this.approval.approvalLogs[this.approval.approvalLogs.length - 1].timestamp;
    }

    showBypassModal(template: TemplateRef<void>) {
        this.modalService.show(template, {
            animated: true,
        });
    }
    confirm() {
        this.service
            .approve(
                this.approval.id,
                `APPROVED ON BEHALF OF ${this.approval.signer.displayName.toUpperCase()} - ${this.comments}`
            )
            .subscribe(() => {
                this.itemChange.emit();
                this.modalService.hide();
            });
    }
    closeModal() {
        this.modalService.hide();
    }
}
