import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessPhysicalHazardCellComponent } from './process-physical-hazard-cell/process-physical-hazard-cell.component';
import { ProcessPhysicalHazardSummaryComponent } from './process-physical-hazard-summary/process-physical-hazard-summary.component';
import { ProcessPhysicalHazardTableComponent } from './process-physical-hazard-table/process-physical-hazard-table.component';
// tslint:disable-next-line: max-line-length
import { ProcessPhysicalHazardComponent } from './process-physical-hazard.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProcessPhysicalHazardTableHeaderItemComponent } from './header-item/header-item.component';

@NgModule({
    declarations: [
        ProcessPhysicalHazardCellComponent,
        ProcessPhysicalHazardSummaryComponent,
        ProcessPhysicalHazardTableComponent,
        ProcessPhysicalHazardTableHeaderItemComponent,
        ProcessPhysicalHazardComponent,
    ],
    imports: [CommonModule, TooltipModule.forRoot()],
    exports: [ProcessPhysicalHazardComponent],
})
export class ProcessPhysicalHazardModule {}
