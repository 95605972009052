import { Component, OnInit } from '@angular/core';
// tslint:disable-next-line: max-line-length
import { ProcessPhysicalHazardsService } from 'src/app/core/services/process-physical-hazards-service/process-physical-hazards-service.service';
import { ProcessPhysicalHazardItem } from 'src/app/core/model/process-physical-hazard-item';

@Component({
    selector: 'hiram-process-physical-hazard-items-list',
    templateUrl: './process-physical-hazard-items-list.component.html',
    styleUrls: ['./process-physical-hazard-items-list.component.scss'],
})
export class ProcessPhysicalHazardItemsListComponent implements OnInit {
    constructor(private service: ProcessPhysicalHazardsService) {}

    loading = false;
    items: ProcessPhysicalHazardItem[];
    categories: string[];
    selectedCategory: string;

    ngOnInit() {
        this.loading = true;
        this.service.items().subscribe(items => {
            this.items = items;
            this.loadCategoriesFromItems();
            this.selectedCategory = this.categories[0];
            this.loading = false;
        });
    }

    itemsForSelectedCategory() {
        return this.items.filter(each => each.categoryName === this.selectedCategory);
    }

    private loadCategoriesFromItems() {
        this.categories = [];
        this.categoryNamesFromItems().forEach(each => {
            if (this.categories.indexOf(each) === -1) {
                this.categories.push(each);
            }
        });
    }

    private categoryNamesFromItems() {
        return this.items.map(each => each.categoryName);
    }
}
