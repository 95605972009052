import {
    Component,
    OnInit,
    Input,
    TemplateRef,
    ViewChild,
    Output,
    EventEmitter,
    PipeTransform,
    Pipe,
} from '@angular/core';
import { HiramMaterialHMISValueItem } from 'src/app/core/model/hiram-materialHMIS-value-item';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MaterialHmisDetailsComponent } from '../material-hmis-details/material-hmis-details.component';
import { MaterialHmisAddComponent } from '../material-hmis-add/material-hmis-add.component';
import { MaterialHMIS } from 'src/app/core/model/materialHMIS';
import { MaterialHMISUnit } from 'src/app/core/model/materialHMISUnit';
import { MaterialHmisService } from 'src/app/core/services/material-hmis/material-hmis.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { flash, bounce } from 'ng-animate';
import { tap } from 'rxjs/operators';
// tslint:disable-next-line
import { MaterialHazardStatementsDetailsComponent } from '../material-hazard-statements-details/material-hazard-statements-details.component';
import { MaterialSummary } from 'src/app/core/model/material-summary';
import { formatNumber } from '@angular/common';
import { HfrCutPoints } from 'src/app/core/model/hfr-cut-points';
import { HfrCutPointsService } from 'src/app/core/services/hfr-cut-points.service';
import { ToastrService } from 'ngx-toastr';
// tslint:disable: no-misleading-array-reverse

@Pipe({
    name: 'formattedNumber',
})
export class ToNumberPipe implements PipeTransform {
    transform(value: string): any {
        const numberValue = Number(value);
        const absNumber = Math.abs(numberValue);

        if (absNumber > 0 && (absNumber > 1e7 || absNumber < 1e-3)) {
            return numberValue.toPrecision(2);
        }

        return formatNumber(numberValue, 'en-US', '1.0-0');
    }
}

@Component({
    selector: 'hiram-material-hmis-list',
    templateUrl: './material-hmis-list.component.html',
    styleUrls: ['./material-hmis-list.component.scss'],
    animations: [
        trigger('valueAnimation', [
            transition(':increment', useAnimation(flash)),
            transition(':decrement', useAnimation(flash)),
        ]),
        trigger('bounce', [transition(':enter', useAnimation(bounce))]),
    ],
})
export class MaterialHmisListComponent implements OnInit {
    @Input() hiramId: number;
    @Input() readonly = false;
    @Output() change: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('templateForModal', { static: false }) templateForModal: TemplateRef<any>;

    constructor(
        private hiramService: HiramService,
        private materialService: MaterialHmisService,
        private modalService: BsModalService,
        private hfrCutPointsService: HfrCutPointsService,
        private toastr: ToastrService
    ) {}

    items: HiramMaterialHMISValueItem[];
    modifiedItems = {};
    units: MaterialHMISUnit[];
    itemsInProgress = [];
    itemsWithInvalidMaterialAmount = [];
    loading = false;
    profileSummary: MaterialSummary;
    hfrCutPoints: HfrCutPoints;
    bypassChemicalIcon: string;

    ngOnInit() {
        this.loading = true;
        this.hiramService
            .getMaterialHMISItems(this.hiramId)
            .pipe(tap(materialList => materialList.forEach(material => (material.showHazards = false))))
            .subscribe(items => {
                this.items = items.sort(byOrder());
                this.hiramService.getBypassChemicals(this.hiramId).subscribe(isBypassed => {
                    if (isBypassed.value) {
                        this.bypassChemicalIcon = 'check';
                    } else {
                        this.bypassChemicalIcon = 'times';
                    }
                    this.loading = false;
                });
            });
        this.materialService.allUnits().subscribe(units => (this.units = units));
        this.hfrCutPointsService.get().subscribe(data => (this.hfrCutPoints = data));

        this.updateMaterialProfileSummary();
    }

    bypassChemicalsClick() {
        this.loading = true;
        try {
            if (this.bypassChemicalIcon == 'times') {
                this.hiramService.setBypassChemicals(this.hiramId, true).subscribe(() => {
                    this.bypassChemicalIcon = 'check';
                    this.loading = false;
                });
            } else {
                this.hiramService.setBypassChemicals(this.hiramId, false).subscribe(() => {
                    this.bypassChemicalIcon = 'times';
                    this.loading = false;
                });
            }
        } catch {
            this.loading = false;
            this.toastr.error(`bypass chemicals not saved`);
        }
    }

    createNewMaterial() {
        const initialState: { item: HiramMaterialHMISValueItem } = {
            item: { id: 0, hiramId: this.hiramId, casNumber: null, fromMidas: false, hazardStatement: null },
        };
        const modalRef = this.modalService.show(MaterialHmisDetailsComponent, {
            initialState,
            class: 'modal-lg',
            backdrop: 'static',
        });
        modalRef.content.OnProductUpdated.subscribe((item: HiramMaterialHMISValueItem) => this.addItemToList(item));
    }

    createMaterialFromMidas() {
        const initialState = {
            hiramId: this.hiramId,
        };

        const modalRef = this.modalService.show(MaterialHmisAddComponent, {
            initialState,
            class: 'modal-xl modal-dialog-scrollable',
            backdrop: 'static',
        });
        modalRef.content.OnProductAdded.subscribe((item: HiramMaterialHMISValueItem) => this.addItemToList(item));
    }

    edit(item: HiramMaterialHMISValueItem) {
        const initialState = { item };
        const modalRef = this.modalService.show(MaterialHmisDetailsComponent, {
            initialState,
            class: 'modal-lg',
            backdrop: 'static',
        });
        modalRef.content.OnProductUpdated.subscribe((updatedItem: HiramMaterialHMISValueItem) =>
            this.replaceItemInList(updatedItem)
        );
    }

    moveUp(item: HiramMaterialHMISValueItem) {
        const minOrder = Math.min(...this.items.map(each => each.order));
        if (item.order === minOrder) {
            return;
        }

        const previous = this.items.find(each => each.order === item.order - 1);

        this.itemsInProgress.push(item);
        this.itemsInProgress.push(previous);

        this.materialService.moveUp(item.id).subscribe(_ => {
            previous.order++;
            item.order--;
            this.items = this.items.sort(byOrder());
            this.itemsInProgress.splice(this.itemsInProgress.indexOf(item), 1);
            this.itemsInProgress.splice(this.itemsInProgress.indexOf(previous), 1);
        });
    }

    canMoveUp(item: HiramMaterialHMISValueItem): boolean {
        const minOrder = Math.min(...this.items.map(each => each.order));
        return !this.readonly && item.order > minOrder;
    }

    canMoveDown(item: HiramMaterialHMISValueItem): boolean {
        const maxOrder = Math.max(...this.items.map(each => each.order));
        return !this.readonly && item.order < maxOrder;
    }

    moveDown(item: HiramMaterialHMISValueItem) {
        const maxOrder = Math.max(...this.items.map(each => each.order));
        if (item.order === maxOrder) {
            return;
        }

        const next = this.items.find(each => each.order === item.order + 1);

        this.itemsInProgress.push(item);
        this.itemsInProgress.push(next);

        this.materialService.moveDown(item.id).subscribe(_ => {
            next.order--;
            item.order++;
            this.items = this.items.sort(byOrder());
            this.itemsInProgress.splice(this.itemsInProgress.indexOf(item), 1);
            this.itemsInProgress.splice(this.itemsInProgress.indexOf(next), 1);
        });
    }

    phsShortName(phsValue: number) {
        return this.materialService.phsShortName(phsValue);
    }

    phsLongName(phsValue: number) {
        return this.materialService.phsLongName(phsValue);
    }

    remove(item: HiramMaterialHMISValueItem) {
        this.itemsInProgress.push(item);
        this.hiramService.deleteMaterialHMIS(this.hiramId, item.id).subscribe(response => {
            this.removeItemFromItemsInProgress(item);
            this.removeItemByIndex(this.items.findIndex(x => x.id === item.id));
            this.updateMaterialProfileSummary();
        });
    }

    updateUnitOfMeasureAndSave(item: HiramMaterialHMISValueItem, property: string, value: any) {
        this.updateItemValue(item, property, value);
        this.saveChanges();
    }

    updateItemValue(item: HiramMaterialHMISValueItem, property: string, value: any) {
        if (this.isItemWithInvalidMaterialAmount(item) && property === 'amount' && value >= 0) {
            this.itemsWithInvalidMaterialAmount.splice(this.itemsWithInvalidMaterialAmount.indexOf(item), 1);
        }

        if (this.isItemWithInvalidMaterialAmount(item) || (item[property] !== value && value)) {
            if (property === 'amount' && value < 0) {
                this.itemsWithInvalidMaterialAmount.push(item);
                return;
            }

            if (!this.modifiedItems[item.id]) {
                this.modifiedItems[item.id] = {};
            }
            this.modifiedItems[item.id][property] = value;
        } else if (this.modifiedItems[item.id] && this.modifiedItems[item.id][property]) {
            delete this.modifiedItems[item.id][property];
            if (Object.keys(this.modifiedItems[item.id]).length === 0) {
                delete this.modifiedItems[item.id];
            }
        }
    }

    anyItemsModified() {
        return Object.keys(this.modifiedItems).length !== 0;
    }

    saveChanges() {
        if (this.anyItemsModified()) {
            Object.keys(this.modifiedItems).forEach(materialId => {
                this.saveChangesForItem(
                    this.items.find(item => item.id === +materialId),
                    this.modifiedItems[materialId]
                );
                delete this.modifiedItems[materialId];
            });
        }
    }

    saveChangesForItem(itemToUpdate: HiramMaterialHMISValueItem, newValues: any) {
        this.itemsInProgress.push(itemToUpdate);
        const item = { ...itemToUpdate, ...newValues } as MaterialHMIS;
        this.hiramService
            .updateMaterialHMIS(this.hiramId, item)
            .pipe(tap(() => console.log('updating materials...')))
            .subscribe(updatedItem => {
                this.removeItemFromItemsInProgress(itemToUpdate);
                this.replaceItemInList(updatedItem);
                this.updateMaterialProfileSummary();
            });
    }

    isItemInProgress(item: HiramMaterialHMISValueItem) {
        return this.itemsInProgress.indexOf(item) !== -1;
    }

    isItemWithInvalidMaterialAmount(item: HiramMaterialHMISValueItem) {
        return this.itemsWithInvalidMaterialAmount.indexOf(item) !== -1;
    }

    toggleMaterial(item: HiramMaterialHMISValueItem) {
        this.itemsInProgress.push(item);
        this.hiramService
            .toggleMaterialExclusionFlag(this.hiramId, item.id)
            .subscribe((updatedItem: HiramMaterialHMISValueItem) => {
                this.replaceItemInList(updatedItem);
                this.removeItemFromItemsInProgress(item);
                this.updateMaterialProfileSummary();
            });
    }

    RCalcTotal() {
        if (this.items == null) {
            return null;
        }

        return this.items.reduce((sum: number, item: HiramMaterialHMISValueItem) => sum + item.rCalc, 0);
    }

    HCalcTotal() {
        if (this.items == null) {
            return null;
        }

        return this.items.reduce((sum: number, item: HiramMaterialHMISValueItem) => sum + item.hCalc, 0);
    }

    FCalcTotal() {
        if (this.items == null) {
            return null;
        }

        return this.items.reduce((sum: number, item: HiramMaterialHMISValueItem) => sum + item.fCalc, 0);
    }

    showSOCBadge(value: number, type: 'health' | 'flammability' | 'reactivity') {
        return this.hfrCutPoints && value >= this.hfrCutPoints[`${type}CutPoint2to3`];
    }

    showPeerReviewerBadge(value: number, type: 'health' | 'flammability' | 'reactivity') {
        return (
            !this.showSOCBadge(value, type) && this.hfrCutPoints && value >= this.hfrCutPoints[`${type}CutPoint3to4`]
        );
    }

    private updateMaterialProfileSummary() {
        this.profileSummary = null;

        const materialSummary$ = this.hiramService.materialSummary(this.hiramId);
        if (materialSummary$) {
            materialSummary$.subscribe(data => (this.profileSummary = data));
        }
    }

    private removeItemFromItemsInProgress(item: HiramMaterialHMISValueItem) {
        const index = this.itemsInProgress.indexOf(item);
        if (index !== -1) {
            this.itemsInProgress.splice(index, 1);
        }
    }

    private replaceItemInList(data: HiramMaterialHMISValueItem): void {
        const itemIndex = this.items.findIndex(x => x.id === data.id);
        if (itemIndex === -1) {
            return;
        }

        this.removeItemByIndex(itemIndex);
        this.addItemAt(itemIndex, data);
        this.change.emit();
    }

    private removeItemByIndex(index: number) {
        this.items.splice(index, 1);
    }

    private addItemToList(item: HiramMaterialHMISValueItem) {
        this.items.push(item);
        this.change.emit();
    }

    public addItemAt(index: number, item: HiramMaterialHMISValueItem) {
        this.items.splice(index, 0, item);
    }

    showHazards(item: HiramMaterialHMISValueItem) {
        const initialState = { item };
        this.modalService.show(MaterialHazardStatementsDetailsComponent, {
            initialState,
            class: 'modal-lg',
            backdrop: 'static',
        });
    }
}

function byOrder() {
    return (a: HiramMaterialHMISValueItem, b: HiramMaterialHMISValueItem) => {
        if (a.order > b.order) {
            return 1;
        } else if (a.order < b.order) {
            return -1;
        } else {
            return 0;
        }
    };
}
