import { Injectable } from '@angular/core';
import { ODataService, IODataParams } from '../odata';
import { Observable, of, throwError } from 'rxjs';
import { shareReplay, catchError, map, switchMap, tap } from 'rxjs/operators';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { PeopleService } from '../people-service/people.service';
import { Person } from '../../model/person';
import { HttpClient } from '@angular/common/http';
import { User } from '../../model/user';
import { environment } from 'src/environments/environment';
import { UnsupportedBrowserCheckerService } from '../unsupported-browser-checker/unsupported-browser-checker.service';

const AuthMeRequestWindowTime = 1000 * 60 * 30;

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    private graphUserUrl = 'https://graph.microsoft.com/v1.0/users';
    private users = {};

    constructor(
        private odataService: ODataService,
        private hiramApiService: HiramAPIService,
        private peopleService: PeopleService,
        private http: HttpClient,
        private browserChecker: UnsupportedBrowserCheckerService
    ) {}

    private getPersonDataForCurrentUserCache: Person = null;

    private isAdminCache: boolean;
    private isAdmin$ = this.hiramApiService.single('Authentication').pipe(
        map(data => data.value),
        tap(data => (this.isAdminCache = data)),
        shareReplay(null, AuthMeRequestWindowTime)
    );

    private authMeBaseRequest$ = this.http.get<User>(environment.baseUrl + '/auth/me', { withCredentials: true });

    private authMeRequest$ = (this.authMeBaseRequest$ || of({ oid: '' })).pipe(
        shareReplay(null, AuthMeRequestWindowTime)
    );

    public getUser(userId: string, oDataParams: IODataParams = null): Observable<any> {
        oDataParams = oDataParams || {};
        const cachedUser = this.getCachedUserObservable(userId);
        if (cachedUser) {
            return cachedUser;
        }

        const observable$ = this.odataService.httpGetSingleAndIgnoreError<any>(
            `${this.graphUserUrl}/${userId}`,
            oDataParams
        );

        this.storeUserObservable(userId, observable$);
        return observable$;
    }

    public isAdmin(): Observable<boolean> {
        if (!this.browserChecker.usingCompatibleBrowser()) {
            return of(false);
        }

        if (this.isAdminCache) {
            return of(this.isAdminCache);
        }

        return this.isAuthenticated().pipe(switchMap(isAuthenticated => (isAuthenticated ? this.isAdmin$ : of(false))));
    }

    public getCurrentUser(): Observable<any> {
        if (!this.browserChecker.usingCompatibleBrowser()) {
            return of({});
        }

        return this.getCurrentUserGuid().pipe(map(guid => this.getUser(guid)));
    }

    public validate() {
        if (!this.browserChecker.usingCompatibleBrowser()) {
            return;
        }

        this.getCurrentUser().subscribe(user => {
            this.hiramApiService
                .post('authentication', { guid: user.id, email: user.mail })
                .subscribe(validatedUser => console.log('validated user', validatedUser));
        });
    }

    public getPersonDataForCurrentUser(): Observable<Person> {
        if (this.getPersonDataForCurrentUserCache) {
            return of(this.getPersonDataForCurrentUserCache);
        }

        return this.getCurrentUserGuid().pipe(
            switchMap(guid => this.peopleService.byGuid(guid)),
            tap(data => (this.getPersonDataForCurrentUserCache = data))
        );
    }

    private getCurrentUserFromAPI(): Observable<User> {
        if (!this.browserChecker.usingCompatibleBrowser()) {
            return of(null);
        }

        return this.authMeRequest$.pipe(
            catchError(error => {
                console.error('error while authenticating', error);
                return throwError(error);
            })
        );
    }

    public getCurrentUserGuid(): Observable<string> {
        if (!this.browserChecker.usingCompatibleBrowser()) {
            return of(null);
        }

        return this.getCurrentUserFromAPI().pipe(map(user => user.oid));
    }

    private isAuthenticated(): Observable<boolean> {
        if (!this.browserChecker.usingCompatibleBrowser()) {
            return of(false);
        }

        return this.getCurrentUserFromAPI().pipe(
            catchError(_ => of(false)),
            map(() => true)
        );
    }

    private storeUserObservable(userId: string, user: Observable<any>) {
        this.users[userId] = user;
    }

    private getCachedUserObservable(userId: string) {
        if (this.users[userId]) {
            return this.users[userId];
        }
        return null;
    }
}
