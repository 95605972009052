import { Component, OnInit } from '@angular/core';
import { OtherInfoQuestion } from 'src/app/core/model/other-info-question';
import { OtherInfoQuestionsService } from 'src/app/core/services/other-info-questions-service/other-info-questions-service.service';
import { tap } from 'rxjs/operators';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceInRight, bounceOutRight } from 'ng-animate';

@Component({
    selector: 'hiram-other-info-questions',
    templateUrl: './other-info-questions.component.html',
    styleUrls: ['./other-info-questions.component.scss'],
    animations: [
        trigger('bounce', [
            transition(':enter', useAnimation(bounceInRight)),
            transition(':leave', useAnimation(bounceOutRight)),
        ]),
    ],
})
export class OtherInfoQuestionsComponent implements OnInit {
    constructor(private service: OtherInfoQuestionsService) {}

    loading = false;
    items: OtherInfoQuestion[];

    ngOnInit() {
        this.loading = true;
        this.service
            .all()
            .pipe(tap(() => (this.loading = false)))
            .subscribe(data => (this.items = data));
    }

    create() {
        this.items.push({ id: -1, text: '' });
    }

    deleted(item: OtherInfoQuestion) {
        this.items.splice(this.items.indexOf(item), 1);
    }
}
