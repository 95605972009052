import { Component, OnInit, Input } from '@angular/core';
import { Person } from 'src/app/core/model/person';

@Component({
    selector: 'hiram-people-side-by-side',
    templateUrl: './people-side-by-side.component.html',
    styleUrls: ['./people-side-by-side.component.scss'],
})
export class PeopleSideBySideComponent implements OnInit {
    constructor() {}

    @Input() personToBeReplaced: Person;
    @Input() newPerson: Person;

    ngOnInit() {}
}
