import { Directive, HostBinding } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[save-button]',
})
export class SaveButtonDirective {
    constructor() {}

    @HostBinding('class')
    elementClass = 'btn btn-primary';
}
