import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';
// tslint:disable-next-line: max-line-length
import { ProcessPhysicalHazardsService } from 'src/app/core/services/process-physical-hazards-service/process-physical-hazards-service.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'risk-table',
    templateUrl: './process-physical-hazard-table.component.html',
    styleUrls: ['./process-physical-hazard-table.component.scss'],
})
export class ProcessPhysicalHazardTableComponent implements OnInit {
    constructor(private service: ProcessPhysicalHazardsService) {}
    @Input() hiramId: number;
    @Input() readonly = true;
    @Output() selectionChanged: EventEmitter<any> = new EventEmitter();

    public riskFactors = [];
    public riskLevelDescriptions = {};
    public selectionItemIds = {};
    public selected = {};
    public selectedRiskFactors = {
        'Reaction Energy': 0,
        'Volume/Quantity Factor': 0,
        'Non Metal - Temperature': 0,
        'Non Metal - Pressure': 0,
        'Metal (SS) - Temperature': 0,
        'Metal (SS) - Pressure': 0,
    };

    ngOnInit() {
        this.loadMatrixData();
    }

    toggleSelection(item: { riskFactor: 0; description: string; id: number }) {
        if (this.readonly) {
            return;
        }

        if (this.selectedRiskFactors[item.description] === item.riskFactor) {
            this.selectedRiskFactors[item.description] = 0;
        } else {
            this.selectedRiskFactors[item.description] = item.riskFactor;
        }

        this.readonly = true;
        this.service
            .toggleSelection(this.hiramId, item.id)
            .pipe(tap(() => (this.readonly = false)))
            .subscribe(_ => this.selectionChanged.emit());
    }

    private loadMatrixData() {
        this.service.items().subscribe(items => {
            this.riskLevelDescriptions = {};

            items.forEach(element => {
                if (!this.riskLevelDescriptions[element.categoryName]) {
                    this.riskLevelDescriptions[element.categoryName] = ['', '', '', ''];
                }

                if (!this.selectionItemIds[element.categoryName]) {
                    this.selectionItemIds[element.categoryName] = [0, 0, 0, 0];
                }

                this.riskLevelDescriptions[element.categoryName][element.riskFactor - 1] = element.description;
                this.selectionItemIds[element.categoryName][element.riskFactor - 1] = element.id;
            });

            this.riskFactors = [1, 2, 3, 4, 5];

            this.loadSelection();
        });
    }

    private loadSelection() {
        this.service.loadSelection(this.hiramId).subscribe(result => {
            result.forEach(item => {
                this.selectedRiskFactors[item.itemCategoryName] = item.itemRiskFactor;
            });
        });
    }
}
