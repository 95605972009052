import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChemicalHazardOverviewModule } from './chemical-hazard-overview/chemical-hazard-overview.module';
import { ChemicalHazardOverviewComponent } from './chemical-hazard-overview/chemical-hazard-overview.component';
import { ExpirationOutlookModule } from './expiration-outlook/expiration-outlook.module';
import { ExpirationOutlookComponent } from './expiration-outlook/expiration-outlook/expiration-outlook.component';
import { HiramCommonsModule } from '../hiram-commons/hiram-commons.module';
import { OimsProcedureReportModule } from './oims-procedure-report/oims-procedure-report.module';
import { OimsProcedureReportComponent } from './oims-procedure-report/oims-procedure-report/oims-procedure-report.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ChemicalsByOrganizationComponent } from './chemicals-by-organization/chemicals-by-organization.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HiramsByChemicalComponent } from './hirams-by-chemical/hirams-by-chemical.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ChemicalsByLocationModule } from './chemicals-by-location/chemicals-by-location.module';
import { ChemicalsByLocationComponent } from './chemicals-by-location/chemicals-by-location/chemicals-by-location.component';
import { PendingResponseModule } from './pending-response/pending-response.module';
import { PendingReponseComponent } from './pending-response/pending-reponse/pending-reponse.component';
import { HiramsByLocationModule } from './hirams-by-location/hirams-by-location.module';
import { HiramsByLocationComponent } from './hirams-by-location/hirams-by-location/hirams-by-location.component';

@NgModule({
    declarations: [ChemicalsByOrganizationComponent, HiramsByChemicalComponent],
    imports: [
        CommonModule,
        ChemicalHazardOverviewModule,
        ChemicalsByLocationModule,
        ExpirationOutlookModule,
        OimsProcedureReportModule,
        HiramCommonsModule,
        RouterModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        FontAwesomeModule,
        PendingResponseModule,
        HiramsByLocationModule,
        TooltipModule.forRoot(),
    ],
    exports: [
        ChemicalHazardOverviewComponent,
        ExpirationOutlookComponent,
        OimsProcedureReportComponent,
        ChemicalsByLocationComponent,
        HiramsByLocationComponent,
        PendingReponseComponent,
    ],
})
export class ReportsModule {}
