import { AbstractControl } from '@angular/forms';
import { Location } from 'src/app/core/model/location';

export function LocationValidator(locationsFromEmoc: Location[]) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (!locationsFromEmoc) {
            return null;
        }

        if (locationsFromEmoc.find(location => location.name === control.value) || control.value === '') {
            return null;
        }

        return { location: true };
    };
}
