import { Component, OnInit } from '@angular/core';
import { UrlService } from 'src/app/core/services/urls/url.service';
import { Observable } from 'rxjs';
import { Url } from 'src/app/core/model/url';
import { map } from 'rxjs/operators';

@Component({
    selector: 'hiram-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
    constructor(private urlService: UrlService) {}

    items$: Observable<Url[]>;

    ngOnInit() {
        this.items$ = this.urlService
            .all()
            .pipe(map(items => items.filter(each => each.categoryDescription === 'Help')));
    }
}
