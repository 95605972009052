import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { HiramsByLocationComponent } from './hirams-by-location/hirams-by-location.component';

@NgModule({
    declarations: [HiramsByLocationComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        HiramCommonsModule,
        FontAwesomeModule,
        TypeaheadModule.forRoot(),
        RouterModule,
    ],
    exports: [HiramsByLocationComponent],
})
export class HiramsByLocationModule {}
