import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaterialHMIS } from '../../model/materialHMIS';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { MaterialHMISUnit } from '../../model/materialHMISUnit';
import { MidasApiService } from '../midas-api/midas-api.service';
import { HiramListItem } from '../../model/hiram-list-item';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { HazardStatement } from '../../model/hazard-statement';
import { HiramService } from '../hiram-service/hiram.service';

@Injectable({
    providedIn: 'root',
})
export class MaterialHmisService {
    constructor(
        private apiService: HiramAPIService,
        private midasService: MidasApiService,
        private hiramService: HiramService
    ) {}

    find(searchString: string): Observable<MaterialHMIS[]> {
        return this.midasService
            .post<MaterialHMIS[]>('MidasChemical', { searchString })
            .pipe(tap(items => items.forEach(item => (item.fromMidas = true))));
    }

    allUnits(): Observable<MaterialHMISUnit[]> {
        return this.apiService.httpGetMulti<MaterialHMIS>('materialHMISUnits', null, null);
    }

    getHiramsByMaterial(casNumber: string, materialName: string): Observable<HiramListItem[]> {
        const encoder = new HttpUrlEncodingCodec();

        const params = {
            casNumber: casNumber ? encoder.encodeValue("'" + casNumber + "'") : null,
            materialName: materialName ? encoder.encodeValue("'" + materialName + "'") : null,
        };

        return this.apiService.httpGetMulti<HiramListItem>(`HiramsForMaterial`, null, params);
    }

    getHazardStatements(chemId: string): Observable<HazardStatement> {
        return this.midasService.post<HazardStatement>('MidasHazardStatement', { chemId });
    }

    moveUp(id: number): Observable<any> {
        return this.apiService.post(`MaterialHMIS(${id})/MoveUp`, {});
    }

    moveDown(id: number): Observable<any> {
        return this.apiService.post(`MaterialHMIS(${id})/MoveDown`, {});
    }

    phsShortName(phsValue: number) {
        if (!phsValue) {
            return '';
        }

        switch (phsValue.toString()) {
            case '1':
                return 'C';
            case '2':
                return 'R';
            case '3':
                return 'A';
        }

        return '';
    }

    // tslint:disable-next-line: no-identical-functions
    phsLongName(phsValue: number) {
        if (!phsValue) {
            return '';
        }

        switch (phsValue.toString()) {
            case '1':
                return 'Carcinogen';
            case '2':
                return 'Repro toxin';
            case '3':
                return 'Acute toxin';
        }

        return '';
    }
}
