import { Component, OnInit } from '@angular/core';
import { SBRATickler } from 'src/app/core/model/sbra-tickler';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'hiram-sbra-ticklers',
    templateUrl: './sbra-ticklers.component.html',
    styleUrls: ['./sbra-ticklers.component.scss'],
})
export class SbraTicklersComponent implements OnInit {
    constructor(private service: HiramService) {}

    items$: Observable<SBRATickler[]> = this.service.getSBRATicklers();

    ngOnInit() {}
}
