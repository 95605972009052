import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProcessPhysicalHazardSummaryComponent } from './process-physical-hazard-summary/process-physical-hazard-summary.component';

@Component({
    selector: 'hiram-process-physical-hazard',
    templateUrl: './process-physical-hazard.component.html',
    styleUrls: ['./process-physical-hazard.component.scss'],
})
export class ProcessPhysicalHazardComponent implements OnInit {
    constructor() {}

    @Input() hiramId: number;
    @Input() readonly = true;

    @ViewChild('riskSummary', { static: false }) public riskSummary: ProcessPhysicalHazardSummaryComponent;

    ngOnInit() {}

    refresh() {
        this.riskSummary.refresh();
    }

    matrixSelectionChanged() {
        this.riskSummary.refresh();
    }
}
