import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Person } from 'src/app/core/model/person';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'hiram-operation-confirmation',
    templateUrl: './operation-confirmation.component.html',
    styleUrls: ['./operation-confirmation.component.scss'],
})
export class OperationConfirmationComponent implements OnInit {
    constructor(private service: HiramService, private toastr: ToastrService) {}

    @Input() personToBeReplaced: Person;
    @Input() newPerson: Person;
    @Input() hirams: HiramListItem[];
    @Output() operationCompleted = new EventEmitter();
    @Output() cancel = new EventEmitter();

    running = false;

    ngOnInit() {}

    replacePerson() {
        this.running = true;
        this.service
            .replacePerson(
                this.personToBeReplaced,
                this.newPerson,
                this.hirams.map(each => each.id)
            )
            .subscribe(numberOfHiramsAffected => {
                this.running = false;
                this.toastr.success(`${numberOfHiramsAffected.value} HIRAMs were affected by the change!`);
                this.operationCompleted.emit();
            });
    }

    back() {
        this.cancel.emit();
    }
}
