import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'hiram-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements OnInit {
    static filterEnabledValue = false;

    @Input() field;

    @Output()
    filterEnabledChange = new EventEmitter();

    ngOnInit() {}

    filter() {
        this.toggleFilterEnabled();
        this.filterEnabledChange.emit(TableFilterComponent.filterEnabledValue);
    }

    toggleFilterEnabled() {
        TableFilterComponent.filterEnabledValue = !TableFilterComponent.filterEnabledValue;
    }
}
