/* Core Imports */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
    MsalModule,
    MSAL_INSTANCE,
    MSAL_GUARD_CONFIG,
    MSAL_INTERCEPTOR_CONFIG,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalInterceptor,
    MsalGuardConfiguration,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { APP_CONFIG, AppConfig } from '../../model/app-config';

function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read'],
        },
        loginFailedRoute: '/unauthorized',
    };
}

@NgModule({
    imports: [MsalModule],
    providers: [
        {
            provide: MSAL_INSTANCE,
            useFactory: (config: AppConfig) => {
                return new PublicClientApplication({
                    auth: {
                        clientId: config.clientId,
                        authority: config.authority,
                        redirectUri: config.redirectUri,
                        navigateToLoginRequestUrl: false,
                    },
                    cache: {
                        cacheLocation: BrowserCacheLocation.LocalStorage,
                    },
                });
            },
            deps: [APP_CONFIG],
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: (config: AppConfig) => {
                const protectedResourceMap = new Map<string, Array<string>>();

                protectedResourceMap.set(`${config.baseApiUrl}/*`, [`${config.clientId}/.default`]);

                protectedResourceMap.set(config.msGraphUri, ['user.read']);

                return {
                    interactionType: InteractionType.Redirect,
                    protectedResourceMap,
                };
            },
            deps: [APP_CONFIG],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
})
export class AppMsalModule {}
