import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScenarioSummaryComponent } from './scenario-summary.component';
import { MaterialHmisModule } from '../../material-hmis/material-hmis.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from 'src/app/icon.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RiskCategoryTileModule } from '../risk-category-tile/risk-category-tile.module';
import { MitigationTableComponent } from './mitigation-table/mitigation-table.component';
import { ScenarioDetailsComponent } from './scenario-details/scenario-details.component';
import { RiskTableComponent } from './risk-table/risk-table.component';
import { OtherHazardsModule } from 'src/app/components/admin/other-hazards/other-hazards.module';

@NgModule({
    declarations: [ScenarioSummaryComponent, MitigationTableComponent, ScenarioDetailsComponent, RiskTableComponent],
    imports: [
        CommonModule,
        MaterialHmisModule,
        FormsModule,
        ReactiveFormsModule,
        IconModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        AlertModule.forRoot(),
        OtherHazardsModule,
        RiskCategoryTileModule,
    ],
    exports: [ScenarioSummaryComponent],
})
export class ScenarioSummaryModule {}
