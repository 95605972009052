import { Injectable } from '@angular/core';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { SBRAItem } from '../../model/sbra-item';

@Injectable({
    providedIn: 'root',
})
export class RiskMatrixService {
    // tslint:disable: max-line-length
    constructor(private apiService: HiramAPIService) {}

    frequencies = [
        {
            range: 'A',
            title: 'Very Likely',
            detailItems: [
                'Similar scenario has occurred once or more at site in the last 10 years',
                'Has happened several times at site or many times in company',
            ],
            quantitativeInterpretation: '0.1 to 1 - Mid-point at 0.3',
        },
        {
            range: 'B',
            title: 'Somewhat Likely',
            detailItems: ['Has happened before at site or several times in company'],
            quantitativeInterpretation: '0.01 to 0.1 - Mid-point at 0.03',
        },
        {
            range: 'C',
            title: 'Unlikely',
            detailItems: ['Has not happened before at site or has happened a few times in company'],
            quantitativeInterpretation: '0.001 to 0.01 - Mid-point at 0.003',
        },
        {
            range: 'D',
            title: 'Very Unlikely',
            detailItems: ['Have been isolated occurrences in company or has happened several times in industry'],
            quantitativeInterpretation: '0.0001-0.001 - Mid-point at 0.0003',
        },
        {
            range: 'E',
            title: 'Very Highly Unlikely',
            detailItems: [
                'Has happened once or not at all in company',
                'Has happened a few times or not at all in industry',
            ],
            quantitativeInterpretation: '< 0.0001',
        },
    ];

    consequences = [
        {
            level: 'I',
            safetyHealth: 'Fatality(ies); Hurt Level 4 and 5; severe hurt to the public Hurt Level 3+',
            environmentalImpact:
                'Potential widespread, long term, significant adverse effects ; major emergency response, months-to-years clean-up',
            publicImpact:
                'Extended (>3 months) national or international media coverage; large community disruption or evacuation (>1000); closure of major transportation route >24 hrs.',
            financialImpact: '>$30M',
        },
        {
            level: 'II',
            safetyHealth: 'Hurt Level 3, severe lost time injury/illness, (moderate hurt to members of the public)',
            environmentalImpact:
                'Potential localized, medium term, significant adverse effects, intermediate emergency response, weeks to months clean-up',
            publicImpact:
                'National media coverage; medium community disruption or evacuation (100-1000); closure of major transportation <24 hrs.',
            financialImpact: '$1M-$30M',
        },
        {
            level: 'III',
            safetyHealth:
                'Hurt Level 2, moderate hurt medical treatment; restricted work (first aid/minor injury to the public minor hurt level 1) ',
            environmentalImpact:
                'Potential short term, minor adverse effects, local emergency response, days to weeks  clean-up',
            publicImpact:
                'Public complaints; small community impact (<100) closure of secondary transportation route <24 hrs. tier 1 pse *',
            financialImpact: '$100K-$1M',
        },
        {
            level: 'IV',
            safetyHealth: 'Minor Injury, Hurt Level 1',
            environmentalImpact: 'Inconsequential or no adverse effects, confined to site or close proximity',
            publicImpact: 'Public complaint; temporary closure of minor transportation route; minor inconvenience',
            financialImpact: '<$100K',
        },
    ];

    riskLevels = [
        { range: 'A', level: 'I', value: 1 },
        { range: 'A', level: 'II', value: 1 },
        { range: 'A', level: 'III', value: 2 },
        { range: 'A', level: 'IV', value: 3 },

        { range: 'B', level: 'I', value: 1 },
        { range: 'B', level: 'II', value: 1 },
        { range: 'B', level: 'III', value: 2 },
        { range: 'B', level: 'IV', value: 4 },

        { range: 'C', level: 'I', value: 1 },
        { range: 'C', level: 'II', value: 2 },
        { range: 'C', level: 'III', value: 3 },
        { range: 'C', level: 'IV', value: 4 },

        { range: 'D', level: 'I', value: 2 },
        { range: 'D', level: 'II', value: 3 },
        { range: 'D', level: 'III', value: 4 },
        { range: 'D', level: 'IV', value: 4 },

        { range: 'E', level: 'I', value: 3 },
        { range: 'E', level: 'II', value: 4 },
        { range: 'E', level: 'III', value: 4 },
        { range: 'E', level: 'IV', value: 4 },
    ];

    public findRiskLevel(item: SBRAItem): number {
        if (!this.itemWithSomethingDiffThenNA(item)) {
            return null;
        }

        return this.riskLevels.find(
            each => each.level === item.riskAssessmentConsequenceLevel && each.range === item.riskAssessmentFrequencyId
        ).value;
    }

    private itemWithSomethingDiffThenNA(item: SBRAItem): boolean {
        return item.riskAssessmentConsequenceLevel !== 'N/A' && item.riskAssessmentFrequencyId !== 'N/A';
    }
}
