import { Component, OnInit, Input } from '@angular/core';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'related-hirams-list',
    templateUrl: './related-hirams-list.component.html',
    styleUrls: ['./related-hirams-list.component.scss'],
})
export class RelatedHiramsListComponent implements OnInit {
    constructor() {}

    @Input() size: 'small' | 'full' = 'small';
    @Input() items: HiramListItem[];
    @Input() unreadMessages = {};
    currentUserId: number;
    sortController = { field: 'hiramNumber', direction: 'asc' };

    ngOnInit() {}

    sortedItems() {
        if (!this.items) {
            return [];
        }

        return this.items.sort((a, b) => this.sortBySelectedField(a, b));
    }

    sortBySelectedField(x, y) {
        let compare = (x[this.sortController.field] + '').localeCompare(y[this.sortController.field] + '');
        if (this.sortController.direction === 'desc' && compare !== 0) {
            compare = -1 * compare;
        }
        return compare;
    }

    numberOfUnreadMessages(item: HiramListItem): number {
        return this.unreadMessages[item.id];
    }
}
