import { Component, OnInit } from '@angular/core';
import { RiskMatrixService } from 'src/app/core/services/risk-matrix/risk-matrix.service';

@Component({
    selector: 'hiram-risk-matrix',
    templateUrl: './risk-matrix.component.html',
    styleUrls: ['./risk-matrix.component.scss'],
})
export class RiskMatrixComponent implements OnInit {
    // tslint:disable: max-line-length
    constructor(private service: RiskMatrixService) {}

    selectedConsequence: {
        safetyHealth: string;
        environmentalImpact: string;
        publicImpact: string;
        financialImpact: string;
    };
    selectedFrequency: { range: string; title: string; detailItems: []; quantitativeInterpretation: string };

    frequencies = this.service.frequencies;
    consequences = this.service.consequences;
    riskLevels = this.service.riskLevels;

    ngOnInit() {}

    getRiskLevel(range: string, level: string) {
        return this.riskLevels.find(x => x.range === range && x.level === level).value;
    }

    selectConsequence(item) {
        this.selectConsequence = item;
    }
}
