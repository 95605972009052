import { Component, OnInit, Input } from '@angular/core';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'my-hirams-list',
    templateUrl: './my-hirams-list.component.html',
    styleUrls: ['./my-hirams-list.component.scss'],
})
export class MyHiramsListComponent implements OnInit {
    constructor(private authenticationService: AuthenticationService) {}

    @Input() items: HiramListItem[];
    @Input() showStatus = true;
    @Input() showLevel = true;
    @Input() unreadMessages = {};
    currentUserId: number;

    ngOnInit() {
        this.authenticationService.getPersonDataForCurrentUser().subscribe(data => (this.currentUserId = data.id));
    }

    numberOfUnreadMessages(item: HiramListItem): number {
        return this.unreadMessages[item.id];
    }

    hiramsOwnedByCurrentUser(): HiramListItem[] {
        if (!this.items) {
            return [];
        }

        return this.items.filter(each => each.ownerId === this.currentUserId);
    }
}
