import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Approval } from 'src/app/core/model/approval';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

@Component({
    selector: 'hiram-approval-card',
    templateUrl: './approval-card.component.html',
    styleUrls: ['./approval-card.component.scss'],
})
export class ApprovalCardComponent implements OnInit {
    constructor(private authService: AuthenticationService) { }

    @Input() approval: Approval;
    @Input() operationInProgress = false;
    @Input() readonly = false;
    @Output() deleteClick = new EventEmitter();
    @Output() editClick = new EventEmitter();

    isAdmin$ = this.authService.isAdmin();

    ngOnInit() {}

    containsComments() {
        return (
            this.approval.approvalLogs &&
            this.approval.approvalLogs.length > 0 &&
            this.lastComment() &&
            this.lastComment().trim() !== ''
        );
    }

    lastComment() {
        return this.approval.approvalLogs[this.approval.approvalLogs.length - 1].comments;
    }
}
