import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { SBRA } from 'src/app/core/model/sbra';
import { SbraFormComponent } from '../sbra-form/sbra-form.component';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';

@Component({
    selector: 'hiram-sbra-list',
    templateUrl: './sbra-list.component.html',
    styleUrls: ['./sbra-list.component.scss'],
    animations: [
        trigger('detailsFormAnimation', [transition('* => *', useAnimation(fadeIn, { params: { timing: 0.5 } }))]),
    ],
})
export class SbraListComponent implements OnInit {
    constructor(private hiramService: HiramService) {}

    @Input() hiramId: number;
    @Input() readonly = false;
    @ViewChild('detailsForm', { static: false }) detailsForm: SbraFormComponent;

    items: SBRA[];
    activeIndex = -1;
    creating = false;
    showingSummary = true;

    ngOnInit() {
        this.hiramService.getSBRAs(this.hiramId).subscribe(items => {
            this.items = items;
            if (this.items && this.items.length > 0) {
                this.activeIndex = 0;
                this.activate(this.activeIndex);
                this.showingSummary = true;
            } else {
                this.showingSummary = false;
            }
        });
    }

    showSummary() {
        this.showingSummary = true;
    }

    create() {
        this.creating = true;
        this.hiramService.createSBRA(this.hiramId).subscribe(newItem => {
            this.creating = false;
            this.items.push(newItem);
            this.activate(this.items.length - 1);
        });
    }

    activate(index: number) {
        this.showingSummary = false;
        this.activeIndex = index;
        this.detailsForm.load(this.items[index]);
    }

    whenItemIsRemoved(item: SBRA) {
        this.items.splice(this.items.indexOf(item), 1);
        if (this.items.length > 0) {
            this.activate(0);
        } else {
            this.activeIndex = -1;
        }
    }

    noScenariosToDisplay() {
        return !this.items || this.items.length === 0;
    }
}
