import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MidasApiService {
    private defaultHttpParams = {
        withCredentials: true,
    };

    constructor(private http: HttpClient) {}

    public getMulti<T>(partialUrl: string) {
        return this.http.get<T[]>(this.getUrl(partialUrl), this.defaultHttpParams);
    }

    public post<T>(partialUrl: string, data: any) {
        return this.http.post<T>(this.getUrl(partialUrl), data, this.defaultHttpParams);
    }

    private getUrl(partialUrl: string): string {
        return `${environment.midasApiUrl}/${partialUrl}`;
    }
}
