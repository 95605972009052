import { Injectable } from '@angular/core';
import { ODataService, IODataParams } from '../odata';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HiramAPIService {
    constructor(private oDataService: ODataService) {}

    public httpGetMulti<T>(url: string, oDataParams: IODataParams = {}, params = null): Observable<any> {
        return this.oDataService.httpGetMulti(this.fixUrl(url), oDataParams, params);
    }

    public single<T>(url: string, oDataParams: IODataParams = {}, params = null): Observable<any> {
        return this.oDataService.httpGetSingle<T>(this.fixUrl(url), oDataParams, params);
    }

    public post<T>(url: string, data: any = null): Observable<T> {
        return this.oDataService.httpPost<T>(this.fixUrl(url), data);
    }

    public delete<T>(url: string, data: any): Observable<any> {
        return this.oDataService.httpDelete(this.fixUrl(url), data);
    }

    public put<T>(url: string, data: T): Observable<T> {
        return this.oDataService.httpPut<T>(this.fixUrl(url), data);
    }

    private fixUrl(url: string): string {
        return environment.APIUrl + '/' + url;
    }
}
