import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Person } from 'src/app/core/model/person';

@Component({
    selector: 'hiram-person-card',
    templateUrl: './person-card.component.html',
    styleUrls: ['./person-card.component.scss'],
})
export class PersonCardComponent implements OnInit {
    constructor() {}

    @Input() person: Person;
    @Input() operationInProgress = false;
    @Input() organizationName: string;
    @Input() readonly = false;
    @Output() deleteClick = new EventEmitter();

    ngOnInit() {}
}
