import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UploadService } from 'src/app/core/services/upload/upload.service';
import { Document } from 'src/app/core/model/document';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Person } from 'src/app/core/model/person';

@Component({
    selector: 'hiram-document-item',
    templateUrl: './document-item.component.html',
    styleUrls: ['./document-item.component.scss'],
})
export class DocumentItemComponent implements OnInit {
    constructor(private uploadService: UploadService, private auth: AuthenticationService) {}

    @Input() disabled = false;
    @Input() readonly = false;
    @Input() item: Document;
    @Output() delete = new EventEmitter();

    currentUser: Person;
    downloading = false;

    ngOnInit() {
        this.auth.getPersonDataForCurrentUser().subscribe(data => (this.currentUser = data));
    }

    ownedByCurrentUser() {
        return this.currentUser && this.item.ownerId === this.currentUser.id;
    }

    download() {
        this.downloading = true;
        this.uploadService
            .downloadDocument(this.item.id, this.item.fileName)
            .subscribe(_ => (this.downloading = false));
    }
}
