import { Component, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { OtherHazardType } from 'src/app/core/model/other-hazard-type';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OtherHazardsService } from 'src/app/core/services/other-hazards.service/other-hazards.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'other-hazards-type-list',
    templateUrl: './other-hazards-type-list.component.html',
    styleUrls: ['./other-hazards-type-list.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OtherHazardsTypeListComponent),
            multi: true,
        },
    ],
})
export class OtherHazardsTypeListComponent implements OnInit, ControlValueAccessor {
    constructor(private service: OtherHazardsService) {}

    items: OtherHazardType[];
    @Output() selectionChange = new EventEmitter<number>();

    // tslint:disable-next-line: variable-name
    private _selectedId: number;

    get selectedId(): number {
        return this._selectedId;
    }

    set selectedId(value) {
        this._selectedId = value;
        this.selectionChange.emit(this.selectedId);
        this.propagateChange(value);
    }

    propagateChange = (_: any) => {};

    ngOnInit() {
        this.service.allRoles().subscribe(items => (this.items = items));
    }

    writeValue(obj: any): void {
        this.selectedId = obj;
        this.propagateChange(this.selectedId);
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    setDisabledState?(isDisabled: boolean): void {}
}
