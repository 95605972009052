import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from '../confirm/confirm.component';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationService {
    constructor(private modalService: BsModalService) {}

    ask(config: {
        andRunIfConfirmed: () => void;
        otherwise?: () => void;
        message?: string;
        displayDefaultMessage?: boolean;
    }) {
        const initialState = {
            message: config.message,
            displayDefaultMessage: config.displayDefaultMessage,
        };
        const confirmationRef = this.modalService.show(ConfirmComponent, { initialState });

        confirmationRef.content.OnConfirm.subscribe((_: any) => {
            config.andRunIfConfirmed();
        });

        if (config.otherwise) {
            confirmationRef.content.OnReject.subscribe(() => {
                config.otherwise();
            });
        }
    }
}
