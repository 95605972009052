import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeIn } from 'ng-animate';

@Component({
    selector: 'hiram-sbra-dashboard',
    templateUrl: './sbra-dashboard.component.html',
    styleUrls: ['./sbra-dashboard.component.scss'],
    animations: [trigger('fade', [transition('*->*', useAnimation(fadeIn, { params: { timing: 0.5 } }))])],
})
export class SbraDashboardComponent implements OnInit {
    constructor() {}

    @Input() hiramId: number;
    @Input() readonly = true;

    currentTab: 'Dashboard' | 'Scenarios' = 'Dashboard';

    ngOnInit() {}
}
