import { Component, OnInit } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'in-progress-icon',
    templateUrl: './in-progress-icon.component.html',
    styleUrls: ['./in-progress-icon.component.scss'],
})
export class InProgressIconComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
