import { Component, OnInit, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramOtherHazard } from 'src/app/core/model/hiram-other-hazard';
// tslint:disable-next-line: max-line-length
import { ProcessPhysicalHazardsService } from 'src/app/core/services/process-physical-hazards-service/process-physical-hazards-service.service';
import { Observable } from 'rxjs';
import { HiramAttachment } from 'src/app/core/model/hiram-attachment';
import { UploadService } from 'src/app/core/services/upload/upload.service';

class PhysicalProcessHazardItem {
    description: string;
    riskFactor: number;
    category: string;
}

class Selection {
    itemCategoryName: string;
    itemRiskFactor: number;
}

@Component({
    selector: 'hiram-sbra-dashboard-hazards-list',
    templateUrl: './sbra-dashboard-hazards-list.component.html',
    styleUrls: ['./sbra-dashboard-hazards-list.component.scss'],
})
export class SbraDashboardHazardsListComponent implements OnInit {
    constructor(
        private processPhysicalHazardsService: ProcessPhysicalHazardsService,
        private hiramService: HiramService,
        private uploadService: UploadService
    ) {}

    processPhysicalHazardsSelection$: Observable<PhysicalProcessHazardItem[]>;
    otherHazardItems$: Observable<HiramOtherHazard[]>;
    checklists$: Observable<HiramAttachment[]>;

    @Input() hiramId: number;

    ngOnInit() {
        this.loadPhysicalProcessHazards();
        this.loadOtherHazards();
        this.loadChecklists();
    }

    loadOtherHazards() {
        this.otherHazardItems$ = this.hiramService.getOtherHazards(this.hiramId);
    }

    loadPhysicalProcessHazards() {
        this.processPhysicalHazardsService.items().subscribe(items => this.loadSelectionItemsUsingMatrixData(items));
    }

    loadSelectionItemsUsingMatrixData(riskMatrixItems) {
        this.processPhysicalHazardsSelection$ = this.processPhysicalHazardsService
            .loadSelection(this.hiramId)
            .pipe(
                map(removeItemsWithRiskGreaterThan(3)),
                map(fromSelectionToPhysicalProcessHazardItem(riskMatrixItems))
            );
    }

    loadChecklists() {
        this.checklists$ = this.hiramService
            .getAttachments(this.hiramId)
            .pipe(map(items => items.filter(each => each.otherHazardsCheckList)));
    }

    download(item: HiramAttachment) {
        this.uploadService
            .downloadHiramAttachment(item.id, item.fileName)
            .subscribe(_ => console.log('File successfully downloaded'));
    }

    selectionDescriptionPrefix(item: PhysicalProcessHazardItem) {
        if (item.category.includes('Non Metal')) {
            return 'Non metal';
        } else if (item.category.includes('Metal (SS)')) {
            return 'Metal';
        } else if (item.category.includes('Volume/Quantity Factor')) {
            return 'Volume';
        } else if (item.category.includes('Reaction Energy')) {
            return 'Reaction Energy';
        }
    }

    endorsementsForOtherHazardsItem(item: HiramOtherHazard) {
        const results = [];

        if (item.otherHazardApprovalRoleName) {
            results.push(item.otherHazardApprovalRoleName);
        }

        if (item.otherHazardTypeName === 'Lasers' && item.otherHazardRiskFactor <= 2) {
            pushIfNotInList(results, 'Laser Safety Officer');
        }

        if (item.otherHazardTypeName === 'Radiation' && item.otherHazardRiskFactor <= 3) {
            pushIfNotInList(results, 'Radiation Safety Coordinator');
        }

        return results;
    }
}

function pushIfNotInList(list: string[], item: string) {
    if (list.indexOf(item) === -1) {
        list.push(item);
    }
}

function fromSelectionToPhysicalProcessHazardItem(items) {
    return (data: Selection[]) => data.map(createItem(items));
}

function createItem(riskMatrixItems) {
    return (each: Selection) => {
        return {
            description: findDescription(riskMatrixItems, each),
            category: each.itemCategoryName,
            riskFactor: each.itemRiskFactor,
        };
    };
}

function removeItemsWithRiskGreaterThan(value: number) {
    return data => data.filter(each => each.itemRiskFactor < value);
}

function findDescription(items, each) {
    return items.find(byCategoryAndRiskFactor(each)).description;
}

function byCategoryAndRiskFactor(each: any): any {
    return item => {
        return each.itemRiskFactor === item.riskFactor && each.itemCategoryName === item.categoryName;
    };
}
