import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ApprovalLog } from 'src/app/core/model/approval';
import { Observable } from 'rxjs';

@Component({
    selector: 'hiram-approval-history',
    templateUrl: './approval-history.component.html',
    styleUrls: ['./approval-history.component.scss'],
})
export class ApprovalHistoryComponent implements OnInit {
    constructor(private service: HiramService) {}

    @Input() hiramId: number;

    approvalLog$: Observable<ApprovalLog[]>;

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.approvalLog$ = this.service.getApprovalLog(this.hiramId);
    }

    iconClasses(item: ApprovalLog) {
        if (item.description === 'Approved') {
            return 'text-success';
        } else if (item.description === 'Rejected') {
            return 'text-danger';
        } else {
            return 'text-secondary';
        }
    }
}
