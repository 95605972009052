import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { Approval } from 'src/app/core/model/approval';
import { AddApproverModalComponent } from '../add-approver-modal/add-approver-modal.component';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { trigger, transition, style, stagger, animate, query } from '@angular/animations';
import { ToastrService } from 'ngx-toastr';
import { EndorsersPreviewComponent } from '../endorsers-preview/endorsers-preview.component';
import { min } from '../../../../core/constants/min.constant';
import { ReplaceApproverModalComponent } from '../replace-approver/replace-approver.component';

@Component({
    selector: 'hiram-approvals-setup',
    templateUrl: './approvals-setup.component.html',
    styleUrls: ['./approvals-setup.component.scss'],
    animations: [
        trigger('listAnimation', [
            transition(':enter', [
                query(
                    '.listItem',
                    [
                        style({ opacity: 0, transform: 'translateX(-100px)' }),
                        stagger(-100, [
                            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' })),
                        ]),
                    ],
                    { optional: true }
                ),
            ]),
        ]),
    ],
})
export class ApprovalsSetupComponent implements OnInit {
    constructor(
        private service: HiramService,
        private modalService: BsModalService,
        private confirmationService: ConfirmationService,
        private toastr: ToastrService
    ) {}

    @Input() readonly = false;
    @Input() hiramId: number;
    @Input() showTitle = true;
    @Input() title = '';
    @Input() allowMultiple = true;

    loading = false;
    approvals: Approval[];
    approvalsWithOperationInProgress = [];
    modalRef: BsModalRef;
    hiramStatus: string;
    socEndorsementRequired: boolean;
    changingSocEndorsementRequired: boolean;
    @ViewChild('endorsersPreview', { static: false }) endorsersPreview: EndorsersPreviewComponent;
    @ViewChild('socEndorsementRequiredControl', { static: false }) socEndorsementRequiredControl;

    ngOnInit() {
        this.loadApprovals();
        this.service.hiramLevelDetails(this.hiramId).subscribe(data => {
            const hazardsLevel = min(data.processHazard, data.chemicalHazard);
            if (hazardsLevel <= 1) this.forceSocEndorsementRequired();
        });
    }

    openAddUserWindow() {
        const initialState = {
            hiramId: this.hiramId,
        };

        const modalRef = this.modalService.show(AddApproverModalComponent, { initialState });
        modalRef.content.closing.subscribe((item: Approval) => this.onModalClosing(item));
    }

    roles() {
        if (!this.approvals) {
            return [];
        }

        return this.approvals.map(x => x.approvalRole.name).filter(onlyUnique);
    }

    canAdd() {
        return !this.readonly && !this.loading;
    }

    onModalClosing(item: Approval) {
        if (this.modalRef) {
            this.modalRef.hide();
        }

        if (item && !this.approvals.find(x => x.id === item.id)) {
            this.approvals.push(item);
        }
    }

    delete(approval: Approval) {
        this.approvalsWithOperationInProgress.push(approval);
        this.confirmationService.ask({
            andRunIfConfirmed: () => this.deleteAndRemoveFromList(approval),
            otherwise: () => this.removeFromInProgress(approval),
        });
    }

    edit(approval: Approval) {
        const initialState = {
            hiramId: this.hiramId,
            approval: approval,
        };
        const modalRef = this.modalService.show(ReplaceApproverModalComponent, { initialState });
        modalRef.content.closing.subscribe(() => this.loadApprovals());
    }

    inProgress(approval: Approval) {
        return this.approvalsWithOperationInProgress.indexOf(approval) !== -1;
    }

    noAssignedApprover(): boolean {
        return this.noAssignmentForRole('Approver');
    }

    noAssignedResearcher(): boolean {
        return this.noAssignmentForRole('Researcher');
    }

    toggleSocEndorsementRequired() {
        const value = !this.socEndorsementRequired;
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.changingSocEndorsementRequired = true;
                this.service.updateRequiredSOCFlag(this.hiramId, value).subscribe(_ => {
                    this.changingSocEndorsementRequired = false;
                    this.socEndorsementRequired = value;
                    this.endorsersPreview.refresh();
                    if (value) {
                        this.toastr.success('SOC endorsement will be required');
                    } else {
                        this.toastr.success(
                            'SOC endorsement will only added according to the default endorsement framework'
                        );
                    }
                });
            },
            otherwise: () => {
                this.socEndorsementRequiredControl.nativeElement.checked = !value;
            },
        });
    }

    private forceSocEndorsementRequired() {
        this.socEndorsementRequiredControl.nativeElement.checked = true;
        this.socEndorsementRequiredControl.nativeElement.disabled = true;
        this.service.updateRequiredSOCFlag(this.hiramId, true).subscribe(_ => {
            this.changingSocEndorsementRequired = false;
            this.socEndorsementRequired = true;
            this.endorsersPreview.refresh();
        });
    }

    private approvalsForRole(role: string) {
        return this.approvals.filter(byRoleName(role));
    }

    private noAssignmentForRole(role: string): boolean {
        return !this.loading && this.approvalsForRole(role).length === 0;
    }

    private removeFromInProgress(approval: Approval) {
        this.approvalsWithOperationInProgress.splice(this.approvalsWithOperationInProgress.indexOf(approval), 1);
    }

    private deleteAndRemoveFromList(approval: Approval) {
        this.service
            .deleteApproval(approval)
            .subscribe(() => this.approvals.splice(this.approvals.indexOf(approval), 1));
    }

    private loadApprovals() {
        this.loading = true;

        this.loadHiramData();

        this.getApprovals()
            .pipe(tap(() => (this.loading = false)))
            .subscribe(items => (this.approvals = items));
    }

    private loadHiramData() {
        this.service.byId(this.hiramId).subscribe(data => {
            this.hiramStatus = data.statusDescription;
            this.socEndorsementRequired = data.requireSOCEndorsement;
        });
    }

    private getApprovals(): Observable<Approval[]> {
        return this.service.getApprovals(this.hiramId);
    }
}

function byRoleName(name: string) {
    return (each: Approval) => each.approvalRole.name === name;
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
