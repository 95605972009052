import { Injectable } from '@angular/core';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { Observable, of } from 'rxjs';
import { Approval } from '../../model/approval';
import { catchError, switchMap, shareReplay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../authentication/authentication.service';
import { ApprovalRole } from '../../model/approval-role';
import { Endorser } from '../../model/endorser';
import { PendingResponseReportItem } from '../../model/pending-response-report-item';

@Injectable({
    providedIn: 'root',
})
export class ApprovalService {
    constructor(
        private apiService: HiramAPIService,
        private toastr: ToastrService,
        private authenticationService: AuthenticationService
    ) {}

    private roles$ = this.apiService.httpGetMulti<ApprovalRole>('approvalRoles').pipe(shareReplay());

    getApprovals(): Observable<Approval[]> {
        return this.apiService.httpGetMulti<Approval>('approvals').pipe(
            catchError(err => {
                console.log('cautherror');
                this.toastr.error(`Could not get approvals`);
                return of([]);
            })
        );
    }

    getApprovalRoles(): Observable<ApprovalRole[]> {
        return this.roles$;
    }

    getEndorsers(): Observable<Endorser[]> {
        return this.apiService.httpGetMulti<ApprovalRole>('Endorsers', { filter: 'approvalRole/isEndorser eq true' });
    }

    addEndorser(data: Endorser): Observable<Endorser> {
        return this.apiService.post<Endorser>('Endorsers', data);
    }

    deleteEndorser(data: Endorser): Observable<any> {
        return this.apiService.delete(`Endorsers(${data.id})`, {});
    }

    getApprovalsForCurrentUser(): Observable<Approval[]> {
        return this.currentUserId().pipe(switchMap(userId => this.getApprovalsForUser(userId)));
    }

    getPendingResponseReport(): Observable<PendingResponseReportItem[]> {
        return this.apiService.httpGetMulti('pendingResponses');
    }

    private getApprovalsForUser(userId: string): Observable<Approval[]> {
        return this.apiService.httpGetMulti<Approval>('MyPendingApprovals', {
            expand: 'approvalLogs, signer, approvalRole',
        });
    }

    getApprovalsForHiram(hiramId: number): Observable<Approval[]> {
        return this.apiService.httpGetMulti<Approval>(`approvals/${hiramId}`).pipe(
            catchError(err => {
                console.log('cautherror');
                this.toastr.error(`Could not get approvals for HIRAM ${hiramId}`);
                return of([]);
            })
        );
    }

    approve(id: number, comments: string): Observable<Approval> {
        return this.apiService.put<any>(`approvals(${id})/approve`, { id, comments });
    }

    reject(id: number, comments: string): Observable<Approval> {
        return this.apiService.put<any>(`approvals(${id})/reject`, { id, comments });
    }

    private currentUserId(): Observable<string> {
        return this.authenticationService.getCurrentUserGuid();
    }
}
