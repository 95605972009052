import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SBRA } from 'src/app/core/model/sbra';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { SBRAItem } from 'src/app/core/model/sbra-item';

@Component({
    selector: 'hiram-sbras',
    templateUrl: './sbras.component.html',
    styleUrls: ['../print.component.scss'],
})
export class SbrasComponent implements OnInit {
    items$: Observable<SBRA[]>;

    constructor(private service: HiramService) {}

    ngOnInit() {}

    headerClass(item: SBRA) {
        const textClass = item.riskCategory === 2 ? 'text-dark' : 'text-white';
        return `bg-gradient-${this.classCategory(item)} ${textClass}`;
    }

    borderClass(item: SBRA) {
        return `border border-${this.classCategory(item)}`;
    }

    classCategory(item: SBRA) {
        switch (item.riskCategory) {
            case 4:
                return 'success';
            case 3:
                return 'info';
            case 2:
                return 'warning';
            case 1:
                return 'danger';
        }
    }

    validSBRAItems(items: SBRAItem[]) {
        return items.filter(each => {
            return each.riskAssessmentFrequencyId !== 'N/A' && each.riskAssessmentConsequenceLevel !== 'N/A';
        });
    }

    @Input() set hiramId(value: number) {
        if (value) {
            this.items$ = this.service.getSBRAs(value);
        }
    }
}
