import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HiramAPIService } from './hiramapi/hiramapi.service';
import { HfrCutPoints } from '../model/hfr-cut-points';

@Injectable({
    providedIn: 'root',
})
export class HfrCutPointsService {
    constructor(private api: HiramAPIService) {}

    get(): Observable<HfrCutPoints> {
        return this.api.single<HfrCutPoints>('HFRCutPoints');
    }

    update(data: HfrCutPoints): Observable<HfrCutPoints> {
        return this.api.post<HfrCutPoints>(`HFRCutPoints(${data.id})`, data);
    }
}
