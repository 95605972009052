import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Person } from 'src/app/core/model/person';
import { Approval } from 'src/app/core/model/approval';
import { ApprovalRole } from 'src/app/core/model/approval-role';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'hiram-add-approver-modal',
    templateUrl: './add-approver-modal.component.html',
    styleUrls: ['./add-approver-modal.component.scss'],
})
export class AddApproverModalComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private formBuilder: UntypedFormBuilder,
        private service: HiramService,
        private approvalService: ApprovalService,
        private toastr: ToastrService
    ) {}

    closing: EventEmitter<Approval> = new EventEmitter();
    detailsForm: UntypedFormGroup;
    submittingForm = false;
    selectedUserValue: Person;
    hiramId: number;
    singleRole: ApprovalRole = null;
    allRoles: ApprovalRole[];
    working = false;

    ngOnInit() {
        this.detailsForm = this.buildForm();

        if (this.singleRole) {
            this.detailsForm.controls.approvalRoleId.setValue(this.singleRole.id);
        }

        this.loadRoles();
    }

    get signer(): Person {
        return this.selectedUserValue;
    }

    set signer(value: Person) {
        if (!value) {
            this.detailsForm.controls.signerId.setValue(null);
            return;
        }
        this.selectedUserValue = value;
        this.detailsForm.controls.signerId.setValue(value.id);
        this.detailsForm.markAsDirty();
    }

    submit() {
        const data = this.detailsForm.value;
        data.hiramId = this.hiramId;
        data.signerId = this.selectedUserValue.id;

        if (this.singleRole) {
            data.approvalRoleId = this.singleRole.id;
        }

        this.working = true;
        this.service
            .addApproval(this.hiramId, data)
            .pipe(
                tap(() => (this.working = false)),
                tap(this.showMessageForSuccess())
            )
            .subscribe(this.closeModalAfterSuccessfullyAddingUser());
    }

    cancel() {
        this.closing.emit(null);
        this.bsModalRef.hide();
    }

    isSubmitEnabled(): boolean {
        return this.detailsForm.valid && !this.detailsForm.pristine && !this.submittingForm;
    }

    usedDefinedRoles() {
        if (!this.allRoles) {
            return [];
        }

        return this.allRoles.filter(each => each.userDefined === true);
    }

    private loadRoles() {
        this.approvalService.getApprovalRoles().subscribe(data => {
            data = data.filter(each => each.name !== 'Approver');
            this.allRoles = data;
        });
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            hiramId: [null],
            signerId: [null, Validators.required],
            approvalRoleId: [null, Validators.required],
        });
    }

    private showMessageForSuccess() {
        return _ => this.toastr.success('User successfully added to HIRAM');
    }

    private closeModalAfterSuccessfullyAddingUser() {
        return result => {
            this.closing.emit(result);
            this.bsModalRef.hide();
        };
    }
}
