import { Component, OnInit, Input } from '@angular/core';
import { SBRA } from 'src/app/core/model/sbra';
import { SBRAItem } from 'src/app/core/model/sbra-item';
import { RiskMatrixService } from 'src/app/core/services/risk-matrix/risk-matrix.service';

@Component({
    selector: 'hiram-risk-table',
    templateUrl: './risk-table.component.html',
    styleUrls: ['./risk-table.component.scss'],
})
export class RiskTableComponent implements OnInit {
    constructor(private service: RiskMatrixService) {}

    @Input() scenario: SBRA;

    ngOnInit() {}

    getDescription(item: SBRAItem) {
        if (item.description === 'Environmental Impact') {
            return 'Environmental';
        } else {
            return item.description;
        }
    }

    validItems() {
        if (!this.scenario || !this.scenario.items) {
            return [];
        }

        return this.scenario.items.filter(each => this.riskForItem(each) !== null);
    }

    riskForItem(item: SBRAItem) {
        return this.service.findRiskLevel(item);
    }
}
