import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardDocumentsListComponent } from './standard-documents-list/standard-documents-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { StandardDocumentsListItemComponent } from './standard-documents-list-item/standard-documents-list-item.component';

@NgModule({
    declarations: [StandardDocumentsListComponent, StandardDocumentsListItemComponent],
    imports: [CommonModule, FontAwesomeModule, HiramCommonsModule, TooltipModule.forRoot(), ToastrModule.forRoot()],
    exports: [StandardDocumentsListComponent],
})
export class StandardDocumentsModule {}
