import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { HiramMaterialHMISValueItem } from 'src/app/core/model/hiram-materialHMIS-value-item';
import { HazardStatementService } from 'src/app/core/services/hazard-statement/hazard-statement.service';

@Component({
    selector: 'hiram-material-hazard-statements-details',
    templateUrl: './material-hazard-statements-details.component.html',
    styleUrls: ['./material-hazard-statements-details.component.scss'],
})
export class MaterialHazardStatementsDetailsComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef, public hazardStatementService: HazardStatementService) {}

    item: HiramMaterialHMISValueItem;

    precautionaryTextSplitRegex = /(([P][0-9]{3}\s{1}\+\s)+[P][0-9]{3}:|[P][0-9]{3}:)/g;
    precautionaryTextReplacer = '###$&';
    precautionaryTextDelimiter = '###';

    ngOnInit() {}

    close() {
        this.bsModalRef.hide();
    }

    splitHazardStatementText(hazardStatement: string): string[] {
        if (hazardStatement) {
            return this.hazardStatementService.splitHazardStatementText(hazardStatement);
        }
    }

    splitPrecautionaryText(precautionaryText: string): string[] {
        if (precautionaryText) {
            const precautionaryTextWithDelimiters = precautionaryText.replace(
                this.precautionaryTextSplitRegex,
                this.precautionaryTextReplacer
            );
            const precautionaryTextList = precautionaryTextWithDelimiters.split(this.precautionaryTextDelimiter);
            precautionaryTextList.shift();
            return precautionaryTextList;
        }
    }
}
