import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Observable } from 'rxjs';
import { HiramOtherHazard } from 'src/app/core/model/hiram-other-hazard';

@Component({
    selector: 'hiram-other-hazards',
    templateUrl: './other-hazards.component.html',
    styleUrls: ['../print.component.scss'],
})
export class OtherHazardsComponent implements OnInit {
    items$: Observable<HiramOtherHazard[]>;

    constructor(private service: HiramService) {}

    ngOnInit() {}

    @Input()
    set hiramId(value: number) {
        if (value) {
            this.items$ = this.service.getOtherHazards(value);
        }
    }
}
