import { Injectable } from '@angular/core';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { Observable } from 'rxjs';
import { Location } from '../../model/location';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    constructor(private hiramApi: HiramAPIService) {}

    getAll(): Observable<Location[]> {
        return this.hiramApi.httpGetMulti<Location>('locations', null, null);
    }
}
