import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentListComponent } from './document-list/document-list.component';
import { HiramCommonsModule } from '../hiram-commons/hiram-commons.module';
import { IconModule } from 'src/app/icon.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentCategoryItemComponent } from './document-category-item/document-category-item.component';
import { ToastrModule } from 'ngx-toastr';
import { DocumentItemComponent } from './document-item/document-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DocumentItemDescriptionComponent } from './document-item/document-item-description/document-item-description.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
    declarations: [
        DocumentListComponent,
        DocumentCategoryItemComponent,
        DocumentItemComponent,
        DocumentItemDescriptionComponent,
    ],
    imports: [
        CommonModule,
        HiramCommonsModule,
        NgxFileDropModule,
        IconModule,
        BrowserAnimationsModule,
        FormsModule,
        TooltipModule.forRoot(),
        ReactiveFormsModule,
        ToastrModule.forRoot(),
    ],
    exports: [DocumentListComponent],
})
export class DocumentsModule {}
