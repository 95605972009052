import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HiramItemComponent } from './hiram-item/hiram-item.component';
import { HiramListComponent } from './hiram-list.component';
import { IconModule } from 'src/app/icon.module';
import { HiramCommonsModule } from '../hiram-commons/hiram-commons.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserRoleBadgeComponent } from './user-role-badge/user-role-badge.component';
import { ExpirationBadgesComponent } from './expiration-badges/expiration-badges.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { ItemExtendedDetailsComponent } from './item-extended-details/item-extended-details.component';
import { ListFilterButtonsComponent } from './list-filter-buttons/list-filter-buttons.component';
import { SortControlsComponent } from './sort-controls/sort-controls.component';
import { HiramItemHeaderComponent } from './hiram-item-header/hiram-item-header.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TableFilterComponent } from './hiram-item-header/table-filter/table-filter.component';

@NgModule({
    declarations: [
        HiramItemComponent,
        HiramListComponent,
        UserRoleBadgeComponent,
        ExpirationBadgesComponent,
        StatusBadgeComponent,
        ItemExtendedDetailsComponent,
        ListFilterButtonsComponent,
        SortControlsComponent,
        HiramItemHeaderComponent,
        TableFilterComponent,
    ],
    imports: [
        CommonModule,
        IconModule,
        HiramCommonsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
        FormsModule,
    ],
    exports: [HiramListComponent, ExpirationBadgesComponent],
})
export class HiramListModule {}
