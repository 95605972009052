import { Component, OnInit, Input } from '@angular/core';
import { HiramOtherHazard } from 'src/app/core/model/hiram-other-hazard';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { tap } from 'rxjs/operators';
import { OtherHazard } from 'src/app/core/model/other-hazard';
import { OtherHazardsService } from 'src/app/core/services/other-hazards.service/other-hazards.service';

@Component({
    selector: 'hiram-other-hazards-list',
    templateUrl: './hiram-other-hazards-list.component.html',
    styleUrls: ['./hiram-other-hazards-list.component.scss'],
})
export class HiramOtherHazardsListComponent implements OnInit {
    @Input() hiramId: number;
    @Input() readonly = false;

    itemsForHiram: HiramOtherHazard[];
    otherHazards: OtherHazard[];
    itemsInProgress: OtherHazard[] = [];
    loadingHiramHazards = false;
    loadingOtherHazards = false;
    noOtherHazardTypeValue = 'No Other Hazards';

    constructor(private hiramService: HiramService, private otherHazardsService: OtherHazardsService) {}

    ngOnInit() {
        this.loadingHiramHazards = true;
        this.loadingOtherHazards = true;

        this.otherHazardsService
            .all()
            .pipe(tap(() => (this.loadingOtherHazards = false)))
            .subscribe(data => (this.otherHazards = this.sortOtherHazardsByNonMiscellaneous(data)));

        this.hiramService
            .getOtherHazards(this.hiramId)
            .pipe(tap(() => (this.loadingHiramHazards = false)))
            .subscribe(data => (this.itemsForHiram = data));
    }

    sortOtherHazardsByNonMiscellaneous(otherHazards: OtherHazard[]) {
        const noHazardOtherHazard = otherHazards.splice(
            otherHazards.indexOf(otherHazards.find(each => each.otherHazardTypeName === 'No Other Hazards')),
            1
        );
        otherHazards.push(noHazardOtherHazard[0]);
        return otherHazards;
    }

    otherHazardsTypes() {
        if (!this.otherHazards) {
            return [];
        }

        const result = [];
        this.otherHazards.forEach(each => {
            if (result.indexOf(each.otherHazardTypeName) === -1) {
                result.push(each.otherHazardTypeName);
            }
        });

        return result;
    }

    hazardsForType(type: string) {
        if (!this.otherHazards) {
            return [];
        }

        return this.otherHazards.filter(each => each.otherHazardTypeName === type).sort(byRiskFactor());
    }

    isSelected(hazard: OtherHazard) {
        return this.findSelection(hazard) != null;
    }

    toggleSelection(hazard: OtherHazard) {
        if (this.readonly) {
            return;
        }

        if (this.isSelected(hazard)) {
            this.deleteItemFromHiram(hazard);
        } else {
            this.updateHazardsWhenNoHazardsIsSelected(hazard);
            this.addItemToHiram(hazard);
        }
    }

    updateHazardsWhenNoHazardsIsSelected(hazard: OtherHazard) {
        if (hazard.otherHazardTypeName === this.noOtherHazardTypeValue) {
            this.itemsForHiram = [];
            this.itemsInProgress = [];
        }
        if (
            hazard.otherHazardTypeName !== this.noOtherHazardTypeValue &&
            this.itemsForHiram.length === 1 &&
            this.itemsForHiram[0].otherHazardTypeName === this.noOtherHazardTypeValue
        ) {
            this.itemsInProgress = [];
            this.itemsForHiram = [];
            this.removeNoHazardSelection(hazard);
        }
    }

    removeNoHazardSelection(hazard: OtherHazard) {
        if (hazard.otherHazardTypeName !== this.noOtherHazardTypeValue) {
            this.itemsForHiram.forEach(selectedHazard => {
                if (selectedHazard.otherHazardTypeName === this.noOtherHazardTypeValue) {
                    this.deleteItemFromHiram(this.otherHazards.find(each => each.id === selectedHazard.otherHazardId));
                }
            });
        }
    }

    findSelection(hazard: OtherHazard) {
        if (!this.itemsForHiram) {
            return null;
        }
        return this.itemsForHiram.find(each => each.otherHazardId === hazard.id);
    }

    inProgress(item: OtherHazard) {
        return this.itemsInProgress.indexOf(item) !== -1;
    }

    addItemToHiram(hazard: OtherHazard) {
        this.itemsInProgress.push(hazard);
        this.hiramService
            .postOtherHazard({ hiramId: this.hiramId, otherHazardId: hazard.id })
            .pipe(tap(newItem => this.itemsForHiram.push(newItem)))
            .subscribe(() => this.itemsInProgress.splice(this.itemsInProgress.indexOf(hazard), 1));
    }

    deleteItemFromHiram(hazard: OtherHazard) {
        const existingSelection = this.findSelection(hazard);
        this.hiramService
            .deleteOtherHazard(existingSelection)
            .pipe(tap(() => this.itemsForHiram.splice(this.itemsForHiram.indexOf(existingSelection), 1)))
            .subscribe(() => this.itemsInProgress.splice(this.itemsInProgress.indexOf(hazard), 1));
    }

    get noOtherHazardType() {
        return this.noOtherHazardTypeValue;
    }
}

function byRiskFactor(): (a: OtherHazard, b: OtherHazard) => number {
    return (a, b) => (a.riskFactor > b.riskFactor ? -1 : 1);
}
