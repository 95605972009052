import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { HiramAttachment } from 'src/app/core/model/hiram-attachment';
import { map } from 'rxjs/operators';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from 'src/app/core/services/upload/upload.service';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { lightSpeedIn, lightSpeedOut } from 'ng-animate';

@Component({
    selector: 'hiram-other-hazards-checklist',
    templateUrl: './other-hazards-checklist.component.html',
    styleUrls: ['./other-hazards-checklist.component.sass'],
    animations: [
        trigger('listItem', [
            transition(':enter', useAnimation(lightSpeedIn)),
            transition(':leave', useAnimation(lightSpeedOut)),
        ]),
    ],
})
export class OtherHazardsChecklistComponent implements OnInit {
    @Input() hiramId: number;
    @Input() readonly: boolean;

    @ViewChild('attachmentsInput', { static: false }) fileInput;
    @ViewChild('inputForm', { static: false }) inputForm;

    items: HiramAttachment[];
    itemsInProgress: HiramAttachment[] = [];

    constructor(
        private service: HiramService,
        private toastr: ToastrService,
        private uploadService: UploadService,
        private confirmationService: ConfirmationService
    ) {}

    ngOnInit() {
        this.service
            .getAttachments(this.hiramId)
            .pipe(map(items => items.filter(each => each.otherHazardsCheckList)))
            .subscribe(data => (this.items = data));
    }

    inProgress(item: HiramAttachment) {
        return this.itemsInProgress.indexOf(item) !== -1;
    }

    download(item: HiramAttachment) {
        this.uploadService
            .downloadHiramAttachment(item.id, item.fileName)
            .subscribe(_ => this.toastr.success('File successfully downloaded'));
    }

    delete(item: HiramAttachment) {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.itemsInProgress.push(item);
                this.service.deleteAttachment(item).subscribe(() => {
                    this.items.splice(this.items.indexOf(item), 1);
                    this.itemsInProgress.splice(this.itemsInProgress.indexOf(item), 1);
                });
            },
        });
    }
}
