import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SBRAItem } from 'src/app/core/model/sbra-item';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { SBRA } from 'src/app/core/model/sbra';
import { RiskMatrixService } from 'src/app/core/services/risk-matrix/risk-matrix.service';

@Component({
    selector: 'hiram-risk-assessment-items',
    templateUrl: './risk-assessment-items.component.html',
    styleUrls: ['./risk-assessment-items.component.scss'],
})
export class RiskAssessmentItemsComponent implements OnInit {
    constructor(private service: HiramService, private riskMatrixService: RiskMatrixService) {}

    @Input() hiramId: number;
    @Input() readonly = false;
    @Input() operationInProgress = false;
    @Output() itemsUpdate = new EventEmitter<string>();
    sbra: SBRA;
    frequencies = ['N/A', 'A', 'B', 'C', 'D', 'E'];
    consequences = ['N/A', 'I', 'II', 'III', 'IV'];

    ngOnInit() {}

    load(sbra: SBRA) {
        this.sbra = sbra;
    }

    invalidItem(item: SBRAItem) {
        return this.service.invalidSBRAItem(item);
    }

    onChange(event) {
        this.updateRiskCategory();
    }

    onConsequenceChange(item: SBRAItem) {
        if (item.riskAssessmentConsequenceLevel === 'N/A') {
            item.riskAssessmentFrequencyId = 'N/A';
        }
        this.updateRiskCategory();
    }

    onFrequencyChange(item: SBRAItem) {
        if (item.riskAssessmentFrequencyId === 'N/A') {
            item.riskAssessmentConsequenceLevel = 'N/A';
        }
        this.updateRiskCategory();
    }

    riskForItem(item: SBRAItem): number {
        return this.riskMatrixService.findRiskLevel(item);
    }

    updateRiskCategory() {
        if (!this.sbra.items) {
            this.sbra.riskCategory = 0;
            return;
        }

        if (this.sbra.items.find(this.itemWithSomethingDiffThenNA)) {
            this.updateRiskCategoryFromService();
        } else {
            this.sbra.riskCategory = 0;
        }
    }

    consequenceDetails(level: string, consequenceTypeDescription: string) {
        if (level === 'N/A' || !this.riskMatrixService.consequences) {
            return '';
        }
        const consequenceType = this.consequenceTypeFromDescription(consequenceTypeDescription);
        const item = this.riskMatrixService.consequences.find(each => each.level === level);
        return item ? item[consequenceType] : '';
    }

    consequenceTypeFromDescription(description: string): string {
        switch (description) {
            case 'Health/Safety':
                return 'safetyHealth';
            case 'Public Disruption':
                return 'publicImpact';
            case 'Environmental Impact':
                return 'environmentalImpact';
            case 'Financial Impact':
                return 'financialImpact';
        }

        return null;
    }

    frequencyDetails(range: string) {
        if (!this.riskMatrixService.frequencies) {
            return '';
        }

        const item = this.riskMatrixService.frequencies.find(each => each.range === range);
        return item ? item.detailItems : '';
    }

    private updateRiskCategoryFromService() {
        this.service.calculateRiskCategory(this.hiramId, this.sbra.items).subscribe(result => {
            this.itemsUpdate.emit(result.toString());
            this.sbra.riskCategory = result;
        });
    }

    private itemWithSomethingDiffThenNA(each: SBRAItem): boolean {
        return each.riskAssessmentConsequenceLevel !== 'N/A' && each.riskAssessmentFrequencyId !== 'N/A';
    }
}
