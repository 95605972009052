import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'hiram-hiram-details-navbar-item',
    templateUrl: './hiram-details-navbar-item.component.html',
    styleUrls: ['./hiram-details-navbar-item.component.scss'],
})
export class HiramDetailsNavbarItemComponent implements OnInit {
    constructor() {}

    @Input() text: string;
    @Input() badge: string;
    @Input() icon: string;
    @Output() OnClick: EventEmitter<any> = new EventEmitter();

    ngOnInit() {}

    click() {
        this.OnClick.emit({});
    }
}
