import { Component, OnInit, ViewChild } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HiramDetails } from 'src/app/core/model/hiram-details';
import { ProcessPhysicalHazardComponent } from './process-physical-hazard/process-physical-hazard.component';
import { HiramLoadedObserverService } from 'src/app/core/services/hiram-loaded-observer/hiram-loaded-observer.service';
import { tap } from 'rxjs/operators';
import { NavBarSession } from './hiram-details-navbar/hiram-details-navbar.component';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown, fadeInUp } from 'ng-animate';
import { Observable, of } from 'rxjs';

@Component({
    selector: 'hiram-hiram-details',
    templateUrl: './hiram-details.component.html',
    styleUrls: ['./hiram-details.component.scss'],
    animations: [
        trigger('fadeInDown', [transition('* => *', useAnimation(fadeInDown, { params: { timing: 0.1 } }))]),
        trigger('sessionAnimation', [transition(':enter', useAnimation(fadeInUp, { params: { timing: 0.1 } }))]),
    ],
})
export class HiramDetailsComponent implements OnInit {
    // tslint:disable: radix

    constructor(
        private route: ActivatedRoute,
        private hiramService: HiramService,
        private hiramLoadedObserver: HiramLoadedObserverService
    ) {}

    hiram: HiramDetails;
    hiramId: number;
    readonly$: Observable<boolean> = of(true);
    canAddAttachments$: Observable<boolean> = of(false);
    pageContext: { currentSession: NavBarSession } = { currentSession: { name: 'Overview' } };

    @ViewChild('processPhysicalHazard', { static: false }) processPhysicalHazard: ProcessPhysicalHazardComponent;

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.hiram = null;
            this.hiramId = parseInt(params.get('id'));

            if (!this.creating()) {
                this.readonly$ = this.hiramService.readonly(this.hiramId);
                this.canAddAttachments$ = this.hiramService.canAddAttachments(this.hiramId);

                this.hiramService
                    .byId(this.hiramId)
                    .pipe(tap(data => this.hiramLoadedObserver.newHiramLoaded.emit(data)))
                    .subscribe(data => (this.hiram = data));
            } else {
                this.readonly$ = of(false);
            }
        });
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
    }

    updating(): boolean {
        return !this.creating();
    }

    onMaterialHMISListChanges() {
        if (this.processPhysicalHazard) {
            this.processPhysicalHazard.refresh();
        }
    }

    creating(): boolean {
        // tslint:disable-next-line: triple-equals
        return this.hiramId == -1;
    }
}
