import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiramCommonsModule } from '../hiram-commons/hiram-commons.module';
import { MyApprovalsComponent } from './my-approvals/my-approvals.component';
import { ApprovalItemComponent } from './my-approvals/approval-item/approval-item.component';
import { RouterModule } from '@angular/router';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IconModule } from 'src/app/icon.module';
import { FormsModule } from '@angular/forms';
import { ApprovalStatementComponent } from './my-approvals/approval-statement/approval-statement.component';
import { ApprovalButtonsComponent } from './my-approvals/approval-buttons/approval-buttons.component';
import { HistoryTimelineComponent } from './my-approvals/history-timeline/history-timeline.component';

@NgModule({
    declarations: [
        MyApprovalsComponent,
        ApprovalItemComponent,
        ApprovalStatementComponent,
        ApprovalButtonsComponent,
        HistoryTimelineComponent,
    ],
    imports: [
        CommonModule,
        HiramCommonsModule,
        IconModule,
        TooltipModule.forRoot(),
        FormsModule,
        RouterModule,
        AlertModule.forRoot(),
    ],
    exports: [MyApprovalsComponent, ApprovalItemComponent],
})
export class ApprovalsModule {}
