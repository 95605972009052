import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UploadService } from 'src/app/core/services/upload/upload.service';
import { HiramAttachment } from 'src/app/core/model/hiram-attachment';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { tap } from 'rxjs/operators';
import { HiramDetails } from 'src/app/core/model/hiram-details';

@Component({
    selector: 'hiram-attachment-item',
    templateUrl: './attachment-item.component.html',
    styleUrls: ['./attachment-item.component.scss'],
})
export class AttachmentItemComponent implements OnInit {
    constructor(private uploadService: UploadService, private hiramService: HiramService) {}

    @Input() disabled = false;
    @Input() readonly = false;
    @Input() item: HiramAttachment;
    @Input() hiram: HiramDetails;
    @Output() delete = new EventEmitter();

    downloading = false;
    editingDescription = false;
    originalDescription = null;
    updateDescriptionInProgress = false;

    ngOnInit() {
        this.originalDescription = this.item.description;
    }

    download() {
        this.downloading = true;
        this.uploadService
            .downloadHiramAttachment(this.item.id, this.item.fileName)
            .subscribe(_ => (this.downloading = false));
    }

    startDescriptionChange() {
        this.editingDescription = true;
    }

    changeItemDescription(event) {
        this.item.description = event.target.value;
    }

    saveDescription() {
        this.updateDescriptionInProgress = true;
        this.hiramService
            .updateAttachmentDescription(this.item.id, this.item.description)
            .pipe(tap(() => (this.updateDescriptionInProgress = false)))
            .subscribe(() => (this.editingDescription = false));
    }

    undo() {
        this.item.description = this.originalDescription;
        this.editingDescription = false;
    }

    canBeDeleted() {
        return (
            this.hiram && this.hiram.statusDescription === 'Draft' && !this.readonly && this.item.omProcedure !== true
        );
    }
}
