import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Approval } from 'src/app/core/model/approval';
import { tap } from 'rxjs/operators';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddApproverModalComponent } from '../add-approver-modal/add-approver-modal.component';
import { ApprovalRole } from 'src/app/core/model/approval-role';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

@Component({
    selector: 'hiram-approvals-report',
    templateUrl: './approvals-report.component.html',
    styleUrls: ['./approvals-report.component.scss'],
})
export class ApprovalsReportComponent implements OnInit {
    @Input() hiramId: number;
    @Output() itemChange = new EventEmitter();

    hiramStatus: string;
    items: Approval[];
    pendingApprovalsForCurrentUser: Approval[];
    loading = false;
    modalRef: BsModalRef;
    roles: ApprovalRole[];
    writable = false;
    isAdmin = false;

    constructor(
        private service: HiramService,
        private approvalsService: ApprovalService,
        private modalService: BsModalService,
        private auth: AuthenticationService,
        private confirmationService: ConfirmationService
    ) {}

    ngOnInit() {
        this.refreshData();
    }

    readyToApprove(item: Approval): boolean {
        if (item.accepted) {
            return false;
        }

        if (['Draft', 'Retired'].includes(this.hiramStatus)) {
            return false;
        }

        if (item.approvalRole.ackOnly) {
            return this.hiramHasBeenApproved();
        }

        if (item.approvalRole.name === 'Approver') {
            return this.hiramStatus === 'Awaiting Approval';
        }

        if (item.approvalRole.isEndorser) {
            if (this.hiramStatus !== 'Awaiting Endorsements') {
                return false;
            }

            return (
                this.otherItemsInSameRoleHaveNotBeenAccepted(item) &&
                this.previousStagesHaveBeenEndorsed(item.approvalRole)
            );
        }
    }

    private hiramHasBeenApproved(): boolean {
        const approval = this.items.find(each => !each.approvalRole.isEndorser && !each.approvalRole.ackOnly);
        return approval && approval.accepted;
    }

    private otherItemsInSameRoleHaveNotBeenAccepted(item: Approval): boolean {
        const otherItems = this.items.filter(
            each => each.id !== item.id && item.approvalRole.id === each.approvalRole.id
        );
        return otherItems.find(each => each.accepted !== null) === undefined;
    }

    private previousStagesHaveBeenEndorsed(role: ApprovalRole): boolean {
        const previousStages = this.previousStages(role);

        for (const stage of previousStages) {
            const rolesFromStage = this.rolesFromStage(stage);
            for (const roleInStage of rolesFromStage) {
                if (!this.roleHasBeenEndorsed(roleInStage)) {
                    return false;
                }
            }
        }

        return true;
    }

    private previousStages(role: ApprovalRole): number[] {
        const result = [];

        for (let stage = role.stage - 1; stage > 0; stage--) {
            result.push(stage);
        }

        return result;
    }

    private roleHasBeenEndorsed(role: ApprovalRole): boolean {
        const itemsInRole = this.items.filter(each => each.approvalRole.id === role.id);

        if (itemsInRole.find(each => each.accepted === false)) {
            return false;
        }

        return itemsInRole.find(each => each.accepted === true) !== undefined;
    }

    private rolesFromStage(stage: number): ApprovalRole[] {
        const itemsForStage = this.items.filter(each => each.approvalRole.stage === stage);
        const result = [];
        for (const item of itemsForStage) {
            if (!result.find(each => each.id === item.approvalRole.id)) {
                result.push(item.approvalRole);
            }
        }

        return result;
    }

    noResearchersInHiram(): boolean {
        return this.approvalsForType('Researcher').length === 0;
    }

    titleForRole(role: string) {
        if (role === 'Researcher') {
            return 'Researchers';
        }

        return role;
    }

    itemChanged() {
        this.refreshData();
        this.itemChange.emit();
    }

    allRoles() {
        if (!this.items) {
            return [];
        }

        const result = [];
        this.items.forEach(each => {
            if (result.indexOf(each.approvalRole.name) === -1) {
                result.push(each.approvalRole.name);
            }
        });

        if (!result.find(each => each === 'Researcher')) {
            result.push('Researcher');
        }

        return result;
    }

    addResearcher() {
        const initialState = {
            hiramId: this.hiramId,
            hideApproverRole: true,
            singleRole: this.roles.find(each => each.name === 'Researcher'),
        };

        this.modalRef = this.modalService.show(AddApproverModalComponent, { initialState });
        this.modalRef.content.closing.subscribe((item: Approval) => this.onModalClosing(item));
    }

    delete(item: Approval) {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.service.deleteApproval(item).subscribe(_ => {
                    this.items.splice(this.items.indexOf(item), 1);
                });
            },
        });
    }

    pendingApprovalForItem(item: Approval) {
        if (!this.pendingApprovalsForCurrentUser) {
            return null;
        }

        return this.pendingApprovalsForCurrentUser.find(each => each.id === item.id);
    }

    approvalsForType(type: string) {
        const result = this.items.filter(each => each.approvalRole.name === type);
        return result ? result : [];
    }

    onModalClosing(item: Approval) {
        if (this.modalRef) {
            this.modalRef.hide();
        }

        this.refreshData();
    }

    refreshData() {
        this.items = [];
        this.pendingApprovalsForCurrentUser = [];

        this.loading = true;

        this.service
            .getApprovals(this.hiramId)
            .pipe(tap(() => (this.loading = false)))
            .subscribe(items => (this.items = items));

        this.approvalsService
            .getApprovalsForCurrentUser()
            .subscribe(data => (this.pendingApprovalsForCurrentUser = data));

        this.approvalsService.getApprovalRoles().subscribe(data => (this.roles = data));

        this.auth.isAdmin().subscribe(isAdmin => {
            this.writable = this.writable || isAdmin;
            this.isAdmin = isAdmin;
        });

        this.auth.getPersonDataForCurrentUser().subscribe(user => {
            this.service.byId(this.hiramId).subscribe(hiramData => {
                this.writable = this.writable || hiramData.createdById === user.id || hiramData.ownerId === user.id;
            });

            this.service.getContributors(this.hiramId).subscribe(contributors => {
                this.writable = this.writable || contributors.find(each => each.id === user.id) !== undefined;
            });
        });

        const hiramDetails$ = this.service.byId(this.hiramId);
        if (hiramDetails$) {
            hiramDetails$.subscribe(data => (this.hiramStatus = data.statusDescription));
        }
    }
}
