import { Component, OnInit } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { Observable } from 'rxjs';
import { HiramsByChemicalItem } from 'src/app/core/model/hirams-by-chemical-item';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SortController } from 'src/app/core/services/sort-controller';

// tslint:disable: no-misleading-array-reverse

@Component({
    selector: 'hiram-hirams-by-chemical',
    templateUrl: './hirams-by-chemical.component.html',
    styleUrls: ['./hirams-by-chemical.component.scss'],
})
export class HiramsByChemicalComponent implements OnInit {
    items$: Observable<HiramsByChemicalItem[]>;
    showResults = false;
    searchForm: UntypedFormGroup;
    sortController = new SortController();
    searchTerm: string;

    constructor(private service: HiramService) {}

    ngOnInit() {
        this.sortController.field = 'hiramNumber';
        this.sortController.direction = 'asc';
        this.sortController.SortParametersChange.subscribe(_ => this.loadData());

        this.searchForm = new UntypedFormGroup({
            searchTerm: new UntypedFormControl(''),
        });
    }

    search() {
        this.searchTerm = this.searchForm.value.searchTerm;
        this.loadData();
    }

    loadData() {
        this.showResults = true;
        this.items$ = this.service.hiramsByMaterial(this.searchTerm, {
            sortByField: this.sortController.field,
            sortDirection: this.sortController.direction,
        });
    }
}

function byHiramAndChemicalTitle() {
    return (x: HiramsByChemicalItem, y: HiramsByChemicalItem) => {
        const hiramNumberCompare = (x.hiramNumber + '').localeCompare(y.hiramNumber + '');
        if (hiramNumberCompare !== 0) {
            return hiramNumberCompare;
        }

        return (x.chemicalName + '').localeCompare(y.chemicalName + '');
    };
}
