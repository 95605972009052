// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.row-header {
  background-color: #6c757d;
  color: white;
  vertical-align: middle;
  padding-top: 0.3rem !important;
  padding-bottom: 0.3rem !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
}

select {
  padding: 0;
}

input {
  padding: 0;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
