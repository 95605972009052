import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApprovalRoleMembersListComponent } from './approval-role-members-list/approval-role-members-list.component';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AddMemberModalComponent } from './add-member-modal/add-member-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
    declarations: [ApprovalRoleMembersListComponent, AddMemberModalComponent],
    imports: [CommonModule, HiramCommonsModule, FontAwesomeModule, ReactiveFormsModule, TypeaheadModule.forRoot()],
    exports: [ApprovalRoleMembersListComponent]
})
export class ApprovalRolesMembersModule {}
