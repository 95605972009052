import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicDetailsComponent } from './basic-details/basic-details.component';
import { PrintComponent } from './print.component';
import { ProcessComponent } from './process/process.component';
import { ChemicalsComponent, ToNumberPipe } from './chemicals/chemicals.component';
import { OtherHazardsComponent } from './other-hazards/other-hazards.component';
import { SbrasComponent } from './sbras/sbras.component';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { ApproversComponent } from './approvers/approvers.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OtherInfoComponent } from './other-info/other-info.component';

@NgModule({
    declarations: [
        PrintComponent,
        BasicDetailsComponent,
        ProcessComponent,
        ChemicalsComponent,
        OtherHazardsComponent,
        SbrasComponent,
        ApproversComponent,
        OtherInfoComponent,
        ToNumberPipe,
    ],
    imports: [CommonModule, HiramCommonsModule, FontAwesomeModule],
    exports: [PrintComponent],
})
export class HiramPrintModule {}
