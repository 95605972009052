import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'hiram-admin-session',
    templateUrl: './admin-session.component.html',
    styleUrls: ['./admin-session.component.scss'],
})
export class AdminSessionComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
