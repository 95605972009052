import { Component, OnInit, Input } from '@angular/core';
import { EndorsementPreviewItem } from 'src/app/core/model/endorsement-preview-item';
import { Person } from 'src/app/core/model/person';

@Component({
    selector: 'hiram-preview-item',
    templateUrl: './preview-item.component.html',
    styleUrls: ['./preview-item.component.sass'],
})
export class PreviewItemComponent implements OnInit {
    constructor() {}

    @Input() item: EndorsementPreviewItem;

    ngOnInit() {}

    personFromItem(item: EndorsementPreviewItem): Person {
        return {
            displayName: item.signerDisplayName,
            domainUserName: item.signerDomainUserName,
            domainName: item.signerDomainName,
        };
    }

    roleBadge(item: EndorsementPreviewItem) {
        return item.role + (item.name ? ` (${item.name})` : '');
    }
}
