import { Component, OnInit } from '@angular/core';
import { PendingResponseReportItem } from 'src/app/core/model/pending-response-report-item';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';
import { Person } from 'src/app/core/model/person';
import { trigger, transition, useAnimation } from '@angular/animations';
import { slideOutRight, bounce } from 'ng-animate';

@Component({
    selector: 'hiram-pending-reponse',
    templateUrl: './pending-reponse.component.html',
    styleUrls: ['./pending-reponse.component.scss'],
    animations: [
        trigger('removableItem', [transition(':leave', useAnimation(slideOutRight, { params: { timing: 0.3 } }))]),
        trigger('removeFilterButton', [transition(':enter', useAnimation(bounce))]),
    ],
})
export class PendingReponseComponent implements OnInit {
    constructor(private service: ApprovalService) {}

    userToFilter: string;
    items: PendingResponseReportItem[];
    hirams: Hiram[];

    ngOnInit() {
        this.service.getPendingResponseReport().subscribe(data => {
            this.items = data;
            this.hirams = [];
            this.items.forEach(item => this.addToHiramsListIfNew(item));
            this.hirams.forEach(hiram => this.addRelatedItems(hiram));
        });
    }

    signer(item: PendingResponseReportItem): Person {
        return {
            displayName: item.personName,
            domainName: item.personLanDomain,
            domainUserName: item.personLanUser,
        };
    }

    visibleHirams() {
        if (!this.hirams) {
            return [];
        }

        return this.hirams.filter(hiram => this.shouldDisplayHiram(hiram));
    }

    visibleItems(hiramItems: PendingResponseReportItem[]) {
        if (!this.userToFilter) {
            return hiramItems;
        }

        return hiramItems.filter(each => each.personName === this.userToFilter);
    }

    shouldDisplayHiram(hiram: Hiram): boolean {
        if (!this.userToFilter || this.userToFilter === hiram.owner) {
            return true;
        }

        return hiram.items.find(item => item.personName === this.userToFilter) !== undefined;
    }

    restrictToUser(user: string) {
        this.userToFilter = user;
    }

    private addToHiramsListIfNew(item: PendingResponseReportItem) {
        if (!this.hirams.find(x => x.hiramId === item.hiramId)) {
            this.hirams.push({
                hiramId: item.hiramId,
                hiramNumber: item.hiramNumber,
                hiramTitle: item.hiramTitle,
                owner: item.owner,
                status: item.status,
                items: [],
            });
        }
    }

    private addRelatedItems(hiram) {
        this.items
            .filter(x => x.hiramId === hiram.hiramId)
            .sort((a, b) => (a.roleStage > b.roleStage ? 1 : -1))
            .forEach(item => hiram.items.push(item));
    }
}

class Hiram {
    hiramId: number;
    hiramNumber: string;
    status: string;
    hiramTitle: string;
    owner: string;
    items: PendingResponseReportItem[];
}
