import { Component, OnInit } from '@angular/core';
import { DocumentCategory } from 'src/app/core/model/document-category';
import { DocumentsService } from 'src/app/core/services/documents/documents.service';
import { Document } from 'src/app/core/model/document';
import { Person } from 'src/app/core/model/person';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

@Component({
    selector: 'hiram-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent implements OnInit {
    categories: DocumentCategory[] = [];
    documents: Document[] = [];
    currentUser: Person;

    constructor(private service: DocumentsService, private auth: AuthenticationService) {}

    ngOnInit() {
        this.service.allCategories().subscribe(data => (this.categories = data));
        this.service.all().subscribe(data => (this.documents = data));
        this.auth.getPersonDataForCurrentUser().subscribe(data => (this.currentUser = data));
    }

    newItem(document: Document) {
        document.ownerId = this.currentUser.id;
        document.ownerDisplayName = this.currentUser.displayName;
        this.documents.push(document);
    }

    deletedItem(document: Document) {
        this.documents.splice(this.documents.indexOf(document), 1);
    }

    documentsForCategory(category: DocumentCategory) {
        return this.documents.filter(each => each.documentCategoryId === category.id);
    }
}
