import { Component, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'hiram-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
    message: string;
    displayDefaultMessage: boolean;
    @Output() OnConfirm = new EventEmitter();
    @Output() OnReject = new EventEmitter();

    constructor(public bsModalRef: BsModalRef) {}

    confirm(): void {
        this.OnConfirm.emit('confirm');
        this.bsModalRef.hide();
    }

    decline(): void {
        this.OnReject.emit('decline');
        this.bsModalRef.hide();
    }
}
