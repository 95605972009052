import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Person } from 'src/app/core/model/person';

// tslint:disable: no-commented-code
// tslint:disable: use-host-property-decorator
// tslint:disable: object-literal-key-quotes

@Component({
    selector: 'hiram-person-selection',
    templateUrl: './person-selection.component.html',
    styleUrls: ['./person-selection.component.scss'],
    host: { class: 'row' },
})
export class PersonSelectionComponent implements OnInit {
    constructor() {}

    person: Person;

    @Input() label: string;
    @Output() personChange = new EventEmitter();

    ngOnInit() {}

    selectedUserChange(value: Person) {
        this.person = value;
        this.personChange.emit(value);
    }
}
