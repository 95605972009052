import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskCategoryTileComponent } from './risk-category-tile.component';

@NgModule({
    declarations: [RiskCategoryTileComponent],
    imports: [CommonModule],
    exports: [RiskCategoryTileComponent],
})
export class RiskCategoryTileModule {}
