import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'hiram-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
    constructor(private toastr: ToastrService, private bsModalRef: BsModalRef, private service: FeedbackService) {}

    text: string;
    working = false;

    ngOnInit() {}

    textIsEmpty() {
        return this.text == null || this.text.trim() === '';
    }

    send() {
        this.working = true;
        this.service
            .send(this.text)
            .pipe(
                tap(() => (this.working = false)),
                tap(() => this.toastr.success('The message has been sent!'))
            )
            .subscribe(() => {
                this.bsModalRef.hide();
            });
    }

    cancel() {
        this.bsModalRef.hide();
    }
}
