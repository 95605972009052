import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LoadingSignComponent } from './loading-sign/loading-sign.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmComponent } from './confirm/confirm.component';
import { InProgressIconComponent } from './in-progress-icon/in-progress-icon.component';
import { UserPictureComponent } from './user-picture/user-picture.component';
import { RestrictToOwnerDirective } from './restrict-to-owner/restrict-to-owner.directive';
import { PersonCardComponent } from './person-card/person-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PersonPickerComponent } from './person-picker/person-picker.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { OkButtonDirective } from './buttons/ok-button.directive';
import { SaveButtonDirective } from './buttons/save-button.directive';
import { CancelButtonDirective } from './buttons/cancel-button.directive';
import { DeleteButtonDirective } from './buttons/delete-button.directive';
import { BasicCardComponent } from './basic-card/basic-card.component';
import { IconModule } from 'src/app/icon.module';
import { RiskLevelBadgeComponent } from './risk-level-badge/risk-level-badge.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SortButtonComponent } from './sort-button/sort-button.component';
import { HiramHistoryButtonComponent } from './hiram-history-button/hiram-history-button.component';

@NgModule({
    declarations: [
        LoadingSignComponent,
        UserProfileComponent,
        ConfirmComponent,
        InProgressIconComponent,
        UserPictureComponent,
        RestrictToOwnerDirective,
        PersonCardComponent,
        PersonPickerComponent,
        OkButtonDirective,
        SaveButtonDirective,
        CancelButtonDirective,
        DeleteButtonDirective,
        BasicCardComponent,
        RiskLevelBadgeComponent,
        SpinnerComponent,
        SortButtonComponent,
        HiramHistoryButtonComponent,
    ],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, IconModule, FontAwesomeModule, TypeaheadModule.forRoot(), NgOptimizedImage],
    exports: [
        HiramHistoryButtonComponent,
        LoadingSignComponent,
        UserProfileComponent,
        UserPictureComponent,
        InProgressIconComponent,
        RestrictToOwnerDirective,
        PersonCardComponent,
        PersonPickerComponent,
        OkButtonDirective,
        DeleteButtonDirective,
        CancelButtonDirective,
        SaveButtonDirective,
        BasicCardComponent,
        RiskLevelBadgeComponent,
        SpinnerComponent,
        SortButtonComponent,
    ]
})
export class HiramCommonsModule {}
