import { Component, OnInit } from '@angular/core';
import { PeopleService } from 'src/app/core/services/people-service/people.service';
import { tap } from 'rxjs/operators';
import { Administrator } from 'src/app/core/model/administrator';
import { Person } from 'src/app/core/model/person';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AddAdminComponent } from '../add-admin/add-admin.component';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceInRight, bounceOutRight } from 'ng-animate';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

@Component({
    selector: 'hiram-admin-list',
    templateUrl: './admin-list.component.html',
    styleUrls: ['./admin-list.component.sass'],
    animations: [
        trigger('bounce', [
            transition(':enter', useAnimation(bounceInRight)),
            transition(':leave', useAnimation(bounceOutRight)),
        ]),
    ],
})
export class AdminListComponent implements OnInit {
    constructor(
        private peopleService: PeopleService,
        private modalService: BsModalService,
        private confirmationService: ConfirmationService,
        private auth: AuthenticationService
    ) {}

    public items: Administrator[];
    private currentUser: Person;
    private itemsInProgress = [];

    ngOnInit() {
        this.peopleService.getAllAdministrators().subscribe(data => (this.items = data));
        this.auth.getPersonDataForCurrentUser().subscribe(data => (this.currentUser = data));
    }

    getPerson(item: Administrator): Person {
        return {
            id: item.personId,
            displayName: item.personDisplayName,
            domainName: item.personDomainName,
            domainUserName: item.personDomainUserName,
        };
    }

    add() {
        const modalRef = this.modalService.show(AddAdminComponent, { initialState: {} });
        modalRef.content.closing.subscribe((newItem: Administrator) => {
            this.items.push(newItem);
        });
    }

    delete(item: Administrator) {
        this.itemsInProgress.push(item);
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.peopleService
                    .deleteAdministrator(item.id)
                    .pipe(tap(() => this.itemsInProgress.splice(this.itemsInProgress.indexOf(item), 1)))
                    .subscribe(() => this.items.splice(this.items.indexOf(item), 1));
            },
        });
    }

    allowedToDelete(item: Administrator): boolean {
        if (!this.currentUser) {
            return false;
        }

        return this.currentUser.id !== item.personId;
    }

    itemInProgress(item: Administrator) {
        return this.itemsInProgress.indexOf(item) !== -1;
    }
}
