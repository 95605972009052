import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Url } from 'src/app/core/model/url';
import { Observable } from 'rxjs';
import { UrlCategory } from 'src/app/core/model/url-category';
import { UrlService } from 'src/app/core/services/urls/url.service';
import { tap } from 'rxjs/operators';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'hiram-url-item',
    templateUrl: './url-item.component.html',
    styleUrls: ['./url-item.component.sass'],
})
export class UrlItemComponent implements OnInit {
    constructor(
        private service: UrlService,
        private confirmationService: ConfirmationService,
        private toastr: ToastrService
    ) {}

    @Input() item: Url;
    @Output() delete = new EventEmitter();

    allCategories$: Observable<UrlCategory[]>;
    editMode = false;
    workInProgress = false;
    clonedData: Url;

    ngOnInit() {
        this.allCategories$ = this.service.categories();
    }

    edit() {
        this.editMode = true;
        this.clonedData = this.clone();
    }

    save() {
        const operation$ = this.creating() ? this.service.create(this.item) : this.service.update(this.item);

        this.runUpdateOperation(operation$);
    }

    deleteAndBubleUp() {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.service
                    .delete(this.item)
                    .pipe(tap(() => this.toastr.success('Item successfully deleted!')))
                    .subscribe(() => this.delete.emit());
            },
        });
    }

    canUndo() {
        return !this.creating() && this.editMode;
    }

    undo() {
        if (this.creating()) {
            this.delete.emit();
            return;
        }

        this.editMode = false;
        this.item.address = this.clonedData.address;
        this.item.description = this.clonedData.description;
        this.item.categoryId = this.clonedData.categoryId;
        this.item.categoryDescription = this.clonedData.categoryDescription;
        this.item.tag = this.clonedData.tag;
    }

    private runUpdateOperation(operation$: Observable<Url>) {
        console.log(this.item);
        this.workInProgress = true;
        operation$.pipe(tap(() => (this.workInProgress = false))).subscribe(data => {
            console.log('data', data);
            this.item.id = data.id;
            this.item.categoryDescription = data.categoryDescription;
            this.editMode = false;
        });
    }

    editInProgress(): boolean {
        return this.creating() || this.editMode;
    }

    creating() {
        return this.item.id === -1;
    }

    private clone(): Url {
        return {
            id: this.item.id,
            tag: this.item.tag,
            address: this.item.address,
            description: this.item.description,
            categoryId: this.item.categoryId,
            categoryDescription: this.item.categoryDescription,
        };
    }
}
