import { Injectable } from '@angular/core';
import { Person } from '../../model/person';
import { HiramAPIService } from '../hiramapi/hiramapi.service';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Administrator } from '../../model/administrator';

@Injectable({
    providedIn: 'root',
})
export class PeopleService {
    constructor(private api: HiramAPIService) {}

    peopleSearchCache = {};
    peopleByGuidCache = {};

    getAllAdministrators(): Observable<Administrator[]> {
        return this.api.httpGetMulti('administrator');
    }

    addAdministrator(personId: number): Observable<Administrator> {
        return this.api.post<Administrator>('administrator', personId);
    }

    deleteAdministrator(adminId: number): Observable<any> {
        return this.api.delete<Administrator>(`administrator(${adminId})`, {});
    }

    enable(email: string): Observable<any> {
        return this.api.post<any>(`EnableUserFromGraph`, email);
    }

    byGuid(guid: string): Observable<Person> {
        if (this.peopleByGuidCache[guid]) {
            return of(this.peopleByGuidCache[guid]);
        }

        return this.api.httpGetMulti<Person>('people', { filter: `guid eq ${guid}` }).pipe(
            map(data => data[0]),
            tap(data => (this.peopleByGuidCache[guid] = data))
        );
    }

    byTerm(searchTerm: string): Observable<Person[]> {
        const filter = `contains(displayName, '${searchTerm}')`;
        console.log('filter', filter);

        if (this.peopleSearchCache[searchTerm]) {
            console.log('%citems returned from cache', 'color: green');
            return of(this.peopleSearchCache[searchTerm]);
        }

        return this.api
            .httpGetMulti<Person>('people', { filter, top: 10 })
            .pipe(tap(data => (this.peopleSearchCache[searchTerm] = data)));
    }
}
