import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'risk-table-cell-content',
    templateUrl: './process-physical-hazard-cell.component.html',
    styleUrls: ['./process-physical-hazard-cell.component.scss'],
})
export class ProcessPhysicalHazardCellComponent implements OnInit {
    constructor(private sanitizer: DomSanitizer) {}

    @Input() selectionItemIds: any;
    @Input() riskFactor: number;
    @Input() description: string;
    @Input() selectedRiskFactors: any;
    @Input() riskLevelDescriptions: any;
    @Input() readonly = false;

    @Output() OnClick: EventEmitter<{ riskFactor: number; description: string; id: number }> = new EventEmitter();

    ngOnInit() {}

    isSelected(): boolean {
        return this.selectedRiskFactors && this.selectedRiskFactors[this.description] === this.riskFactor;
    }

    select() {
        this.OnClick.emit({
            riskFactor: this.riskFactor,
            description: this.description,
            id: this.itemId(),
        });
    }

    itemId(): number {
        if (
            this.selectionItemIds &&
            this.selectionItemIds[this.description] &&
            this.selectionItemIds[this.description][this.riskFactor - 1]
        ) {
            return this.selectionItemIds[this.description][this.riskFactor - 1];
        } else {
            return 0;
        }
    }

    classCategory(): string {
        if (!this.isSelected()) {
            return 'secondary';
        }

        switch (this.riskFactor) {
            case 1:
                return 'danger';
            case 2:
                return 'warning';
            case 3:
            case 4:
                return 'success';
            case 5:
                return 'success';
            default:
                return 'secondary';
        }
    }

    text() {
        if (this.riskLevelDescriptions && this.riskLevelDescriptions[this.description]) {
            let result = this.riskLevelDescriptions[this.description][this.riskFactor - 1];

            if (this.isSelected()) {
                result = result.split('<red>').join('');
                result = result.split('<blue>').join('');
                result = result.split('</>').join('');
            } else {
                result = result.split('<red>').join("<span style='color: red'>");
                result = result.split('<blue>').join("<span style='color: blue'>");
                result = result.split('</>').join('</span>');
            }

            return this.sanitizer.bypassSecurityTrustHtml(result);
        } else {
            return '';
        }
    }
}
