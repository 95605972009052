import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { StandardDocumentsService } from 'src/app/core/services/standard-documents/standard-documents.service';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { ToastrService } from 'ngx-toastr';
import { UploadService } from 'src/app/core/services/upload/upload.service';
import { StandardDocument } from 'src/app/core/model/standard-document';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'hiram-standard-documents-list-item',
    templateUrl: './standard-documents-list-item.component.html',
    styleUrls: ['./standard-documents-list-item.component.scss'],
})
export class StandardDocumentsListItemComponent implements OnInit {
    constructor(
        private service: StandardDocumentsService,
        private confirmationService: ConfirmationService,
        private toastr: ToastrService,
        private uploadService: UploadService
    ) {}

    @Input() item: StandardDocument;
    @ViewChild('attachmentsInput', { static: false }) fileInput;

    inProgress = false;

    ngOnInit() {}

    download(item: StandardDocument) {
        this.uploadService
            .downloadStandardDocument(item.id, item.fileName)
            .subscribe(() => console.log('download complete'));
    }

    uploadNewVersion(item: StandardDocument) {
        this.confirmationService.ask({
            message: `This operation will replace the contents of ${item.description}. Are you sure you want to proceed?`,
            andRunIfConfirmed: () => this.openFileExplorer(),
        });
    }

    openFileExplorer() {
        this.fileInput.nativeElement.click();
    }

    filesSelectedFromFileInput(fileInput) {
        const files: FileList = fileInput.files;

        if (files.length > 1) {
            this.toastr.error('Only one file should be dropped at a time');
            return;
        }

        this.upload(files[0]);
    }

    upload(file) {
        this.inProgress = true;
        this.service
            .update(this.item.id, file.name, file)
            .pipe(tap(() => (this.inProgress = false)))
            .subscribe(
                newItem => {
                    this.item.fileName = file.name;
                    this.toastr.success('The document has been successfully updated!');
                },
                error => this.errorOnUpload(error)
            );
    }

    private errorOnUpload(error) {
        this.inProgress = false;
        this.toastr.error(error.message);
    }
}
