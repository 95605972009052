import { Component, OnInit } from '@angular/core';
import { Person } from 'src/app/core/model/person';
import { trigger, transition, useAnimation } from '@angular/animations';
import {
    bounceIn,
    bounceInRight,
} from 'ng-animate';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ToastrService } from 'ngx-toastr';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';

@Component({
    selector: 'hiram-replace-person',
    templateUrl: './replace-person.component.html',
    styleUrls: ['./replace-person.component.scss'],
    animations: [
        trigger('stageTransition', [transition(':enter', useAnimation(bounceInRight))]),
        trigger('bounceIn', [transition(':enter', useAnimation(bounceIn))]),
    ],
})
export class ReplacePersonComponent implements OnInit {
    constructor(private service: HiramService, private toastr: ToastrService) {}

    stage = 1;
    personToBeReplaced: Person;
    newPerson: Person;
    replaceComplete = false;
    selectedHirams: HiramListItem[];

    ngOnInit() {}

    onPersonSelected(person: Person) {
        this.personToBeReplaced = person;
        this.stage++;
    }

    cancelPersonToBeReplacedSelection() {
        this.personToBeReplaced = null;
        this.stage--;
    }

    onNewPersonSelected(person: Person) {
        this.newPerson = person;
        this.stage++;
    }

    selectedHiramsChange(selectedHirams: HiramListItem[]) {
        this.selectedHirams = selectedHirams;
        this.stage++;
    }

    cancelHiramSelection() {
        this.selectedHirams = null;
        this.stage--;
    }

    readyToDisplayHiramList() {
        return this.personToBeReplaced && this.newPerson;
    }

    readyToReplace() {
        return this.personToBeReplaced && this.newPerson && this.selectedHirams && this.selectedHirams.length > 0;
    }

    cancelOperationExecution() {
        this.stage--;
    }

    personReplacementCompleted() {
        this.stage = 1;
    }
}
