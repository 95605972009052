import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SBRATickler } from 'src/app/core/model/sbra-tickler';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'hiram-sbra-tickler-list-item',
    templateUrl: './sbra-tickler-list-item.component.html',
    styleUrls: ['./sbra-tickler-list-item.component.scss'],
})
export class SbraTicklerListItemComponent implements OnInit {
    constructor(
        private service: HiramService,
        private toastr: ToastrService,
        private confirmationService: ConfirmationService
    ) {}

    @Input() item: SBRATickler;
    @Output() itemDelete = new EventEmitter();

    backup: SBRATickler;
    workInProgress = false;
    editMode = false;

    ngOnInit() {}

    edit() {
        this.backup = clone(this.item);
        this.editMode = true;
    }

    save() {
        if (!this.isValid()) {
            this.toastr.error('Please fill anything in at least one field to save!');
            return;
        }

        this.workInProgress = true;

        this.performSave()
            .pipe(
                tap(data => (this.item.id = data.id)),
                tap(() => (this.workInProgress = false)),
                tap(() => (this.editMode = false))
            )
            .subscribe(() => this.toastr.success('Item successfully updated!'));
    }

    editInProgress() {
        return this.editMode || this.isCreating();
    }

    isValid() {
        return (
            this.item.hazard.trim() !== '' ||
            this.item.potentialRiskScenario.trim() !== '' ||
            this.item.notes.trim() !== ''
        );
    }

    delete() {
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.workInProgress = true;
                this.service
                    .deleteSBRATickler(this.item)
                    .pipe(
                        tap(() => (this.workInProgress = false)),
                        tap(() => this.itemDelete.emit())
                    )
                    .subscribe(() => this.toastr.success('Item deleted!'));
            },
        });
    }

    undo() {
        if (this.isCreating()) {
            this.itemDelete.emit();
        } else {
            this.askIfInformationWillBeLostAndThen(() => this.performUndoWithoutAskingAndCancelEdition());
        }
    }

    private askIfInformationWillBeLostAndThen(func: () => void) {
        if (this.isCreating() || this.backupDifferentThanItem()) {
            this.confirmationService.ask({
                message: 'The current changes will be lost. Do you want to continue?',
                andRunIfConfirmed: func,
            });
        } else {
            func();
        }
    }

    private isCreating(): boolean {
        return this.item.id === -1;
    }

    private backupDifferentThanItem() {
        return (
            this.item.hazard !== this.backup.hazard ||
            this.item.potentialRiskScenario !== this.backup.potentialRiskScenario ||
            this.item.notes !== this.backup.notes
        );
    }

    private performUndoWithoutAskingAndCancelEdition() {
        this.item.hazard = this.backup.hazard;
        this.item.potentialRiskScenario = this.backup.potentialRiskScenario;
        this.item.notes = this.backup.notes;
        this.backup = null;
        this.editMode = false;
    }

    private performSave(): Observable<SBRATickler> {
        return this.isCreating()
            ? this.service.createSBRATickler(this.item)
            : this.service.updateSBRATickler(this.item);
    }
}

function clone(item: SBRATickler): SBRATickler {
    return {
        id: item.id,
        hazard: item.hazard,
        potentialRiskScenario: item.potentialRiskScenario,
        notes: item.notes,
    };
}
