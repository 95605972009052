import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'src/app/components/hiram-commons/confirmation-service/confirmation-service.service';

@Component({
    selector: 'hiram-control-list',
    templateUrl: './control-list.component.html',
    styleUrls: ['./control-list.component.scss'],
})
export class ControlListComponent implements OnInit {
    constructor(private confirmationService: ConfirmationService) {}

    @Input() items: { id: number; text: string }[];
    @Input() itemsInProgress: { id: number; text: string }[] = [];
    @Input() description: string;
    @Input() readonly = false;
    @Output() itemChange = new EventEmitter();
    @Input() suggestions: string[];

    ngOnInit() {}

    delete(item: { id: number; text: string }) {
        this.itemsInProgress.push(item);
        this.confirmationService.ask({
            andRunIfConfirmed: () => {
                this.items.splice(this.items.indexOf(item), 1);
                this.itemChange.emit();
            },
        });

        this.itemsInProgress.splice(this.itemsInProgress.indexOf(item), 1);
    }

    isInProgress(item: { id: number; text: string }) {
        return this.itemsInProgress.indexOf(item) !== -1;
    }
}
