import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OimsProcedureReportComponent } from './oims-procedure-report/oims-procedure-report.component';
import { HiramCommonsModule } from '../../hiram-commons/hiram-commons.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
    declarations: [OimsProcedureReportComponent],
    imports: [CommonModule, HiramCommonsModule, AppRoutingModule, FontAwesomeModule, TooltipModule.forRoot()],
    exports: [OimsProcedureReportComponent],
})
export class OimsProcedureReportModule {}
