import { Component, OnInit } from '@angular/core';
import { UrlService } from 'src/app/core/services/urls/url.service';
import { Url } from 'src/app/core/model/url';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceInRight, bounceOutRight } from 'ng-animate';

@Component({
    selector: 'hiram-url-list',
    templateUrl: './url-list.component.html',
    styleUrls: ['./url-list.component.sass'],
    animations: [
        trigger('bounce', [
            transition(':enter', useAnimation(bounceInRight)),
            transition(':leave', useAnimation(bounceOutRight)),
        ]),
    ],
})
export class UrlListComponent implements OnInit {
    constructor(private service: UrlService) {}

    items: Url[];
    itemsInProgress = [];
    categoryIdForNewOnes = null;

    ngOnInit() {
        this.service.all().subscribe(data => (this.items = data));
        this.service.categories().subscribe(data => (this.categoryIdForNewOnes = data[0].id));
    }

    readyToCreate() {
        return this.categoryIdForNewOnes;
    }

    create() {
        this.items.unshift({
            id: -1,
            tag: '',
            address: '',
            description: '',
            categoryId: this.categoryIdForNewOnes,
        });
    }

    removeFromList(item: Url) {
        console.log('delete', item);
        this.items.splice(this.items.indexOf(item), 1);
    }
}
