import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'hiram-changelog',
    templateUrl: './changelog.component.html',
    styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
