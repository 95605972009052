import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'hiram-basic-card',
    templateUrl: './basic-card.component.html',
    styleUrls: ['./basic-card.component.scss'],
})
export class BasicCardComponent implements OnInit {
    constructor() {}

    @Input() title: string;
    @Input() minHeight: string;
    @Input() border = true;

    ngOnInit() {}
}
