import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramDetails } from 'src/app/core/model/hiram-details';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { Person } from 'src/app/core/model/person';

export interface NavBarSession {
    name: string;
    icon?: string;
    hideIfReadonly?: boolean;
    showTitle?: boolean;
    highlighted?: boolean;
    badge?: number;
}
@Component({
    selector: 'hiram-details-navbar',
    templateUrl: './hiram-details-navbar.component.html',
    styleUrls: ['./hiram-details-navbar.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    width: '19rem',
                })
            ),
            state(
                'closed',
                style({
                    width: '3rem',
                })
            ),
            transition('open => closed', [animate('0.3s')]),
            transition('closed => open', [animate('0.3s')]),
        ]),
    ],
})
export class HiramDetailsNavbarComponent implements OnInit {
    constructor(private service: HiramService, private auth: AuthenticationService) {}

    @Input() pageContext: { currentSession: NavBarSession };
    @Input() hiramId: number;
    @Input() readonly = true;

    sessions: NavBarSession[] = [];
    expanded = false;

    ngOnInit() {
        this.sessions.push({ name: 'Overview', icon: 'play', hideIfReadonly: false, showTitle: true });
        this.sessions.push({ name: 'Discussion', icon: 'comment-dots', hideIfReadonly: false, showTitle: true });
        this.sessions.push({ name: 'Process', icon: 'fire', hideIfReadonly: false, showTitle: false });
        this.sessions.push({ name: 'Chemicals', icon: 'flask', hideIfReadonly: false, showTitle: true });
        this.sessions.push({ name: 'Other Hazards', icon: 'biohazard', hideIfReadonly: false, showTitle: true });
        this.sessions.push({ name: 'SBRA', icon: 'project-diagram', hideIfReadonly: false, showTitle: true });
        this.sessions.push({ name: 'People', icon: 'user-friends', hideIfReadonly: false, showTitle: false });
        this.sessions.push({ name: 'Attachments', icon: 'paperclip', hideIfReadonly: false, showTitle: true });
        this.sessions.push({ name: 'Other Info', icon: 'info', hideIfReadonly: false, showTitle: true });
        this.sessions.push({ name: 'Change Log', icon: 'history', hideIfReadonly: false, showTitle: true });

        this.addOptionalSessions();
        this.refreshUnreadHighlight();
    }

    openSession(item: NavBarSession) {
        if (this.pageContext) {
            this.pageContext.currentSession = item;
            setTimeout(() => {
                this.refreshUnreadHighlight();
            }, 2000);
            window.scrollTo(0, 0);
        }

        this.expanded = false;
    }

    refreshUnreadHighlight() {
        this.service
            .unreadMessageCount()
            .pipe(map(data => data.filter(each => each.hiramId === this.hiramId)))
            .subscribe(data => {
                if (data && data.length === 1) {
                    const numberOfUnreadMessages = data[0].unreadMessages;
                    const discussionSession = this.sessions.find(each => each.name === 'Discussion');
                    discussionSession.highlighted = numberOfUnreadMessages > 0;
                    if (discussionSession.highlighted) {
                        discussionSession.badge = numberOfUnreadMessages;
                    }
                }
            });
    }

    isActive(session: { isOpen: boolean }): boolean {
        return session && session.isOpen;
    }

    sessionsToBeDisplayed() {
        if (this.readonly) {
            return this.sessions.filter(each => !each.hideIfReadonly);
        } else {
            return this.sessions;
        }
    }

    private addOptionalSessions() {
        if (this.hiramId === -1) {
            return;
        }

        this.auth.getPersonDataForCurrentUser().subscribe(user => this.addSessionForUser(user));
    }

    private addSessionForUser(user: Person) {
        this.getHiramDetails().subscribe((hiramDetails: HiramDetails) =>
            this.addSessionBasedOnHiramStatus(hiramDetails, user)
        );
    }

    private addSessionBasedOnHiramStatus(hiramDetails: HiramDetails, user: Person) {
        if (hiramDetails.statusDescription !== 'Draft') {
            return;
        }

        this.auth.isAdmin().subscribe(isAdmin => this.addSessionBasedOnUserProfile(user, isAdmin, hiramDetails));
    }

    private addSessionBasedOnUserProfile(user: Person, isAdmin: boolean, hiramDetails: HiramDetails) {
        if (isAdmin || this.userAllowedToSubmit(user, hiramDetails)) {
            this.sessions.push({ name: 'Submit', icon: 'share', hideIfReadonly: true, showTitle: true });
        }
    }

    private userAllowedToSubmit(user: Person, hiramDetails: HiramDetails) {
        return user.id === hiramDetails.ownerId && hiramDetails.statusDescription === 'Draft';
    }

    private getHiramDetails(): Observable<HiramDetails> {
        return this.service.byId(this.hiramId);
    }
}
