// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-link.active {
  border-color: darkgray;
  border-bottom-color: white;
}

.nav-tabs {
  border-color: darkgray;
}

.risk-cell-1 {
  color: #c60d57;
}

.risk-cell-2 {
  color: #ffe500;
}

.risk-cell-3 {
  color: #286cff;
}

.risk-cell-4 {
  color: #069b17;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
