import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[restrict-to-owner]',
})
export class RestrictToOwnerDirective implements OnInit {
    constructor(private authentication: AuthenticationService, private elementRef: ElementRef) {}

    @Input() hiram: { ownerId: number };

    ngOnInit() {
        if (!this.hiram) {
            console.error('hiram not set..... restrict-to-owner will not work!');
            return;
        }

        this.elementRef.nativeElement.style.display = 'none';
        this.authentication.getPersonDataForCurrentUser().subscribe(user => {
            if (this.hiram && user.id === this.hiram.ownerId) {
                this.elementRef.nativeElement.style.display = '';
            }
        });
    }
}
