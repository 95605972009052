export class HiramListFilter {
    title?: string;
    hiramNumber?: string;
    owner?: string;
    status?: string;
    level?: string;
    role?: string;
    onlyExpired?: boolean;
    expiresSoon?: boolean;
    pendingApproval?: boolean;

    reset() {
        this.title = '';
        this.hiramNumber = '';
        this.owner = '';
        this.status = 'All';
        this.level = 'All';
        this.role = '';
        this.onlyExpired = false;
        this.expiresSoon = false;
        this.pendingApproval = false;
    }
}
