import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
// tslint:disable-next-line: max-line-length
import { ProcessPhysicalHazardsService } from 'src/app/core/services/process-physical-hazards-service/process-physical-hazards-service.service';
import { ProcessPhysicalHazardItem } from 'src/app/core/model/process-physical-hazard-item';

@Component({
    selector: 'hiram-item-editable-description',
    templateUrl: './item-editable-description.component.html',
    styleUrls: ['./item-editable-description.component.scss'],
})
export class ItemEditableDescriptionComponent implements OnInit {
    constructor(
        private service: ProcessPhysicalHazardsService,
        private formBuilder: UntypedFormBuilder,
        private toastr: ToastrService
    ) {}

    @Input() item: ProcessPhysicalHazardItem;
    @Output() updated: EventEmitter<ProcessPhysicalHazardItem> = new EventEmitter();

    itemIsBeingEdited = false;
    detailsForm: UntypedFormGroup;
    submittingForm = false;

    ngOnInit() {
        this.detailsForm = this.buildForm();
        this.detailsForm.patchValue(this.item);
    }

    submit() {
        this.submittingForm = true;
        const value = this.detailsForm.value;
        this.service.updateDescription(value).subscribe(item => this.afterSaving(item));
    }

    afterSaving(item: ProcessPhysicalHazardItem) {
        this.toastr.success('Item successfully updated!');
        this.item.description = item.description;
        this.submittingForm = false;
        this.itemIsBeingEdited = false;
        this.updated.emit(item);
    }

    isSubmitEnabled(): boolean {
        return this.detailsForm.valid && !this.detailsForm.pristine && !this.submittingForm;
    }

    private buildForm(): UntypedFormGroup {
        return this.formBuilder.group({
            id: [0],
            description: [null, Validators.required],
        });
    }
}
