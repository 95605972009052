import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PeopleService } from 'src/app/core/services/people-service/people.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'hiram-enable-person',
    templateUrl: './enable-person.component.html',
    styleUrls: ['./enable-person.component.sass'],
})
export class EnablePersonComponent implements OnInit {
    constructor(private toastr: ToastrService, private service: PeopleService, private formBuilder: UntypedFormBuilder) {}

    mainForm = this.formBuilder.group({
        email: [null, [Validators.email, Validators.required]],
    });

    ngOnInit() {}

    submitEnabled() {
        return this.mainForm.valid;
    }

    submit() {
        if (!this.submitEnabled()) {
            return;
        }

        this.service.enable(this.mainForm.value.email).subscribe(() => {
            this.toastr.success('User successfully enabled!');
        });
    }
}
