import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramDetails } from 'src/app/core/model/hiram-details';
import { Observable } from 'rxjs';
import { HiramOtherInfoQuestionResponse } from 'src/app/core/model/hiram-other-info-question-response';

@Component({
    selector: 'hiram-other-info',
    templateUrl: './other-info.component.html',
    styleUrls: ['../print.component.scss'],
})
export class OtherInfoComponent implements OnInit {
    @Input() hiramId: number;

    hiram$: Observable<HiramDetails>;
    responses$: Observable<HiramOtherInfoQuestionResponse[]>;

    constructor(private service: HiramService) {}

    ngOnInit() {
        this.hiram$ = this.service.byId(this.hiramId);
        this.responses$ = this.service.getOtherInfoQuestionResponses(this.hiramId);
    }
}
