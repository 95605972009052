import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { HiramListItem } from 'src/app/core/model/hiram-list-item';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { ApprovalService } from 'src/app/core/services/approval/approval.service';
import { Approval } from 'src/app/core/model/approval';
import { Person } from 'src/app/core/model/person';
import { tap } from 'rxjs/operators';
import { trigger, transition, useAnimation } from '@angular/animations';
import { slideOutUp } from 'ng-animate';

@Component({
    selector: 'hiram-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: [
        trigger('optionalPannels', [transition(':leave', useAnimation(slideOutUp, { params: { timing: 0.3 } }))]),
    ],
})
export class DashboardComponent implements OnInit {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private approvalService: ApprovalService,
        private hiramService: HiramService
    ) {}

    isAdmin = false;
    currentUserData: Person;
    unreadMessages = {};
    myHirams: HiramListItem[];
    showOnlyRelatedHirams = false;
    myApprovals: Approval[];
    loadingMyHirams = false;

    ngOnInit() {
        this.authService.isAdmin().subscribe(data => (this.isAdmin = data));
        this.authService.getPersonDataForCurrentUser().subscribe(data => (this.currentUserData = data));
        this.approvalService.getApprovalsForCurrentUser().subscribe(data => (this.myApprovals = data));
        this.hiramService.unreadMessageCount().subscribe((data: { hiramId: number; unreadMessages: number }[]) => {
            if (data) {
                data.forEach(each => (this.unreadMessages[each.hiramId] = each.unreadMessages));
            }
        });

        this.loadingMyHirams = true;
        this.hiramService
            .myHirams()
            .pipe(tap(() => (this.loadingMyHirams = false)))
            .subscribe(data => (this.myHirams = data));
    }

    toggleRelatedHiramsSize() {
        this.showOnlyRelatedHirams = !this.showOnlyRelatedHirams;
    }

    gotoNewHiramPage() {
        this.router.navigateByUrl(this.hiramUrl(-1));
    }

    gotoApprovals() {
        return this.router.navigateByUrl('/myapprovals');
    }

    gotoManageHiramsPage() {
        this.router.navigateByUrl('hirams');
    }

    numberOfExpiresSoon() {
        return this.numberOfMyHiramsFiltered(expiresSoon());
    }

    numberOfExpired() {
        return this.numberOfMyHiramsFiltered(expired());
    }

    numberOfEndorsements() {
        return this.numberOfMyApprovalsFiltered(isEndorsement());
    }

    numberOfApprovals() {
        return this.numberOfMyApprovalsFiltered(isApproval());
    }

    numberOfAcknowledgements() {
        return this.numberOfMyApprovalsFiltered(isAcknowledgement());
    }

    associatedHirams(): HiramListItem[] {
        const relatedHirams = this.myHirams
            .filter(each => {
                if (each.currentUserRole === 'No role') {
                    return false;
                }

                if (this.currentUserData && each.ownerId === this.currentUserData.id) {
                    return (
                        each.statusDescription !== 'Draft' &&
                        each.statusDescription !== 'Retired' &&
                        each.statusDescription.indexOf('Awaiting') === -1
                    );
                }

                return true;
            })
            .sort(byHiramNumber);
        const itemsToRemove = relatedHirams.filter(
            each =>
                each.statusDescription === 'Retired' &&
                relatedHirams.find(
                    other => other.hiramNumber === each.hiramNumber && other.statusDescription !== 'Retired'
                ) != null
        );

        itemsToRemove.forEach(each => relatedHirams.splice(relatedHirams.indexOf(each), 1));

        return relatedHirams;
    }

    numberOfPendingApprovals() {
        return this.numberOfMyHiramsFiltered(pendingApproval());
    }

    hiramsOwnedByUserInDraft() {
        return this.hiramsOwnedByCurrentUser().filter(
            each => each.statusDescription && each.statusDescription === 'Draft'
        );
    }

    hiramsOwnedByUserInProgress() {
        return this.hiramsOwnedByCurrentUser().filter(
            each => each.statusDescription && each.statusDescription.indexOf('Awaiting') !== -1
        );
    }

    hiramsOwnedByCurrentUser() {
        if (!this.currentUserData || !this.myHirams) {
            return [];
        }

        return this.myHirams.filter(each => each.ownerId === this.currentUserData.id);
    }

    gotoExpiredHiramsPage() {
        this.router.navigate(['/hirams', 'onlyExpired']);
    }

    gotoExpiresSoonPage() {
        this.router.navigate(['/hirams', 'expiresSoon']);
    }

    gotoPendingApprovalPage() {
        this.router.navigate(['/hirams', 'pendingApproval']);
    }

    private numberOfMyHiramsFiltered(filter) {
        if (!this.myHirams) {
            return null;
        }

        return this.hiramsOwnedByCurrentUser().filter(filter).length;
    }

    private numberOfMyApprovalsFiltered(filter) {
        if (!this.myApprovals) {
            return null;
        }

        const pendingApprovals = this.myApprovals.filter(each => each.accepted == null);
        return pendingApprovals.filter(filter).length;
    }

    private hiramUrl(id: number): string {
        return `hirams/${id}`;
    }
}

function isEndorsement() {
    return (approval: Approval) => approval.approvalRoleIsEndorser;
}

function isApproval() {
    return (approval: Approval) => !(isEndorsement()(approval) || isAcknowledgement()(approval));
}

function isAcknowledgement() {
    return (approval: Approval) => approval.approvalRoleAckOnly;
}

function pendingApproval() {
    return (hiram: HiramListItem) => {
        return hiram.statusDescription && hiram.statusDescription.indexOf('Awaiting') !== -1;
    };
}

function expiresSoon() {
    return (hiram: HiramListItem) => {
        return hiram.numberOfDaysToExpiration <= 30 && hiram.numberOfDaysToExpiration > 0;
    };
}

function expired() {
    return (hiram: HiramListItem) => {
        return hiram.numberOfDaysToExpiration < 0;
    };
}

function byHiramNumber(a: HiramListItem, b: HiramListItem) {
    if (!a || !b || !a.hiramNumber || !b.hiramNumber) {
        return -1;
    }

    return a.hiramNumber.localeCompare(b.hiramNumber);
}
