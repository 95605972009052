import { Component, OnInit, Input } from '@angular/core';
import { HiramService } from 'src/app/core/services/hiram-service/hiram.service';
import { EndorsementPreviewItem } from 'src/app/core/model/endorsement-preview-item';
import { trigger, style, transition, stagger, animate, query } from '@angular/animations';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'hiram-endorsers-preview',
    templateUrl: './endorsers-preview.component.html',
    styleUrls: ['./endorsers-preview.component.scss'],
    animations: [
        trigger('listAnimation', [
            transition(':enter', [
                query(
                    '.listItem',
                    [
                        style({ opacity: 0, transform: 'translateX(-100px)' }),
                        stagger(-100, [
                            animate('500ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'none' })),
                        ]),
                    ],
                    { optional: true }
                ),
            ]),
        ]),
    ],
})
export class EndorsersPreviewComponent implements OnInit {
    constructor(private service: HiramService) {}

    @Input()
    hiramId: number;

    items: EndorsementPreviewItem[];
    loading = false;

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        console.log('refresh');
        this.loading = true;
        this.items = [];
        this.service
            .getEndorsementPreview(this.hiramId)
            .pipe(tap(() => (this.loading = false)))
            .subscribe(data => (this.items = data));
    }

    approver() {
        return this.items.find(each => each.role === 'Approver');
    }

    endorsers() {
        return this.items.filter(each => each.role !== 'Approver').sort(byStage());
    }
}

function byStage() {
    return (a: EndorsementPreviewItem, b: EndorsementPreviewItem) => {
        if (a.roleStage === b.roleStage) {
            return a.role.localeCompare(b.role);
        }

        return a.roleStage < b.roleStage ? 1 : -1;
    };
}
